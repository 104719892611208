import { useContext } from 'react';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import { useTranslation } from 'react-i18next';
import { Trim } from 'shared/utils';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import { Checkbox } from 'components/ui/checkbox';

export default function Accreditations({
  currentFilters,
  updateFilters,
  accreditations
}: {
  currentFilters: any;
  updateFilters: (a: string, b: any) => void;
  accreditations: any[];
}) {
  const { setCurrentPageFiltering } = useContext(PartnersContext);
  const selectedAccreditations = currentFilters?.accreditations || [];
  const { t } = useTranslation();

  return (
    <fieldset className="flex flex-col gap-2">
      <div className="p-0 m-0 text-base font-extralight text-[#2360a0]">
        {t('corporate_accreditations')}
      </div>
      <div className="flex flex-col gap-1">
        {accreditations.map(({ name = '' } = {}) => (
          <Checkbox
            key={name}
            label={
              <>
                {name}
                {Trim(name).toLowerCase() === 'sase framework' ? (
                  <HybridTooltip
                    text={t('sase_tooltip')}
                    alignAsText
                    portalContainerId="landing_page_filters_popup"
                  />
                ) : null}
              </>
            }
            id={name}
            onCheckedChange={() => {
              setCurrentPageFiltering(0);
              updateFilters('accreditations', name);
            }}
            checked={selectedAccreditations?.includes(name)}
          />
        ))}
      </div>
    </fieldset>
  );
}
