import { useContext, useEffect, useState } from 'react';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});
const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});
const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function Languages({
  languages,
  currentFilters,
  updateFilters
}: {
  languages: any[];
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { t } = useTranslation();

  const languages_data = languages
    .map((l) => {
      return { ...l, label: l?.name, value: l?.id };
    })
    ?.sort((a, b) => a?.label?.localeCompare(b?.label));

  const { clickOnFilter } = useContext(PartnersContext);
  const [langs, setLangs] = useState<any[]>([]);

  useEffect(() => {
    if (currentFilters?.languages?.length > 0) {
      const langs =
        currentFilters.languages.map((l) =>
          languages_data?.find((e) => e.name === l)
        ) || [];
      setLangs([...langs]);
    }
  }, []);
  useEffect(() => {
    if (!currentFilters?.languages) setLangs([]);
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="languages" className="text-base font-light">
        {t('languages')}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="languages"
          placeholder={t('languages')}
          data={languages_data}
          value={langs}
          onChange={(l) => {
            setLangs([...l]);
            updateFilters(
              'languages',
              l?.map((e) => e.name)
            );
            clickOnFilter('Languages');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
