import PartnersList from 'components/organisms/PartnersList';
import { useUseCasesContext } from 'hooks/useContexts';
import { useTranslation } from 'react-i18next';
import Pagination from '../Pagination';
import { Skeleton } from 'components/ui/skeleton';
import FinderNoResult from './FinderNoResults';
import { UseCasesFinderControllerResult } from 'controllers/finderTools/UseCasesFinder';
import FinderUseCasesList from 'components/organisms/FinderUseCasesList';
import { useEffect, useState } from 'react';

const defaultUseCasesFinder = {
  totalUseCases: 0,
  totalRelatedPartners: 0,
  currentUseCases: [],
  relatedPartnersFromPage: [],
  USECASES_PER_PAGE: 0,
  RELATED_PARTNERS_PER_PAGE: 0,
  isLoading: false,
  isLoadingRelated: false
};
export default function UseCasesFinderResults({
  standalone,
  useCasesFinder = defaultUseCasesFinder
}: {
  standalone: boolean;
  useCasesFinder?: UseCasesFinderControllerResult;
}) {
  const {
    totalUseCases,
    totalRelatedPartners,
    currentUseCases,
    relatedPartnersFromPage,
    USECASES_PER_PAGE,
    RELATED_PARTNERS_PER_PAGE,
    isLoading = false,
    isLoadingRelated = false
  } = useCasesFinder;

  const { t } = useTranslation();

  const {
    currentPageFiltering,
    setCurrentPageFiltering,
    relatedPageFiltering,
    setRelatedPageFiltering
  } = useUseCasesContext();

  const [useCases, setUseCases] = useState([] as any[]);

  useEffect(() => {
    setUseCases(currentUseCases);
  }, [currentUseCases]);

  return (
    <>
      {isLoading ||
      isLoadingRelated ||
      (!isLoading && !isLoadingRelated && useCases?.length > 0) ? (
        <div className="flex flex-col gap-32 mb-20">
          <section className="flex flex-col gap-10">
            <h3
              className="text-[#171725] p-0 m-0 font-normal inline-flex gap-2 items-center"
              id="useCasesFinder-mainResults"
            >
              {t('results')}{' '}
              {isLoading ? (
                <Skeleton className="w-36 h-8" />
              ) : (
                <span className="font-extralight text-lg">
                  ({t('showing')} {totalUseCases})
                </span>
              )}
            </h3>
            <FinderUseCasesList
              useCases={useCases}
              setUseCases={setUseCases}
              isLoading={isLoading}
              loadingCount={12}
            />
            {isLoading ? null : (
              <div className="flex justify-between text-[#b5b7c0]">
                <Pagination
                  perPage={USECASES_PER_PAGE}
                  total={totalUseCases}
                  currentPage={currentPageFiltering}
                  onChange={(currentPageFiltering) => {
                    setCurrentPageFiltering(currentPageFiltering);
                  }}
                  showDataInfo
                  removePadding
                  scrollId="useCasesFinder-mainResults"
                />
              </div>
            )}
          </section>
          {((!standalone &&
            !isLoadingRelated &&
            relatedPartnersFromPage.length > 0) ||
            isLoadingRelated) && (
            <section className="flex flex-col gap-10">
              <h3
                className="text-[#171725] p-0 m-0 font-normal inline-flex gap-2 items-center"
                id="useCasesFinder-relatedResults"
              >
                {t('rel_partners')}{' '}
                {isLoadingRelated ? (
                  <Skeleton className="w-36 h-8" />
                ) : (
                  <span className="font-extralight text-lg">
                    ({t('showing')} {totalRelatedPartners})
                  </span>
                )}
              </h3>
              <PartnersList
                partners={relatedPartnersFromPage}
                isLoading={isLoadingRelated}
                loadingCount={3}
                related="useCases"
              />
              {isLoadingRelated ? null : (
                <div className="flex justify-between text-[#b5b7c0]">
                  <Pagination
                    perPage={RELATED_PARTNERS_PER_PAGE}
                    total={totalRelatedPartners}
                    currentPage={relatedPageFiltering}
                    onChange={(relatedPageFiltering) => {
                      setRelatedPageFiltering(relatedPageFiltering);
                    }}
                    showDataInfo
                    removePadding
                    scrollId="useCasesFinder-relatedResults"
                  />
                </div>
              )}
            </section>
          )}
        </div>
      ) : null}
      {!isLoading && !isLoadingRelated && useCases?.length === 0 ? (
        <FinderNoResult />
      ) : null}
    </>
  );
}
