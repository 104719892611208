import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/ui/checkbox';

export default function LicensePortability({
  currentFilters,
  updateFilters
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { t } = useTranslation();

  return (
    <fieldset className="flex flex-col gap-2 mb-4">
      <div className="m-0 p-0 font-extralight text-base text-[#2360a0]">
        {t('license_portability')}
      </div>
      <div className="flex flex-col gap-1 w-full mt-2">
        <Checkbox
          id="license_portability"
          name="license_portability"
          label={t('license_portability')}
          onCheckedChange={(checked) => {
            updateFilters('isLicensePortability', checked);
          }}
          checked={currentFilters['isLicensePortability'] || false}
        />
      </div>
    </fieldset>
  );
}
