import { getDayOfTheWeek, getMonths } from 'shared/constants';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useContext } from 'react';
import { Slider } from 'components/molecules/Slider';
import useEmblaCarousel from 'embla-carousel-react';
import EventsContext from 'context/EventsListingsProvider';
import {
  convertTimezoneBasedOnDate,
  getEventTimeDateInfo,
  getHoursAndMinutesFromISODate,
  getNewDate,
  getTimezonesData
} from 'shared/utils';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from 'services/axios';
import PublicEventModal from 'componentsV2/molecules/Modals/PublicEventModal';
import Image from 'next/image';
import { cn } from 'lib/utils';
import { Skeleton } from 'components/ui/skeleton';
import useNavigator from 'hooks/useNavigator';
import MiniCalendarIcon from 'public/images/calendar-mini.png';

const DEFAULT_IMAGE = '/images/event-broadcom-small.png';
const DEFAULT_PARTNER_IMAGE = '/images/event_banner_def.svg';
const DEFAULT_LOGO = '/images/broadcom-icon.png';
const DEFAULT_WEBCAST = '/images/event-webcast-small.png';
const DEFAULT_CONFERENCE = '/images/event-conference-small.png';
const DEFAULT_USER_GROUP = '/images/event-user-group-small.png';
const DEFAULT_TRAINING = '/images/event-training-small.png';
const DEFAULT_CSP = '/images/csp-event-large.png';

export default function UpcomingEvents({ eventType }: { eventType?: string }) {
  const { t } = useTranslation();
  const daysOfTheWeek = getDayOfTheWeek(t);
  const navigator = useNavigator();

  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const { allTimezones, usersTimezoneLocation } = useContext(EventsContext);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const isMobile = screenSize && screenSize.width <= 768;

  const today: any = getNewDate({
    isUTC0: true,
    date: null,
    returnDateObject: true,
    formatUSDate: false,
    timezoneHoursOffset: undefined,
    endOfDay: false,
    getCurrentDateAndTime: false
  });

  const months = getMonths(t);
  const hourFormat = Intl.DateTimeFormat(navigator?.language, {
    hour: 'numeric'
  }).resolvedOptions().hourCycle;

  const timezonesData = getTimezonesData(allTimezones, usersTimezoneLocation);

  const { data: upcomingEvents = [], isLoading } = useQuery({
    queryKey: ['upcomingEvents'],
    queryFn: async () => {
      const res: any = await getAPIClient().get(
        `/events/upcomingEvents${eventType ? `?eventType=${eventType}` : ''}`
      );
      return res.data;
    },
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize({
        width: window.innerWidth || 0,
        height: window.innerHeight || 0
      });
    };
    updateDimension();
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);

  const slidesToShow = isMobile
    ? 1
    : screenSize.width >= 650 && screenSize.width <= 1291
    ? 2
    : 3;

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    slidesToScroll: slidesToShow
  });

  return isLoading ? (
    <div className="grid grid-cols-1 gap-8">
      <div className="text-[22px] text-[#1f384c]">{t('upcoming_events')}</div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {[1, 2, 3].map((_, index) => (
          <Skeleton
            key={`loading_upcoming_event_${index}`}
            className="w-full h-[132px]"
          />
        ))}
      </div>
    </div>
  ) : upcomingEvents.length > 0 ? (
    <>
      <div className="grid grid-cols-1 gap-8">
        <Slider
          numOfElements={upcomingEvents.length}
          title={t('upcoming_events')}
          slidesToShow={slidesToShow}
          showArrows={upcomingEvents.length > slidesToShow}
          emblaRef={emblaRef}
          emblaApi={emblaApi}
          slideShow
          customStylingTitle={{ marginTop: '1rem' }}
        >
          {upcomingEvents.map((e: any, index: number) => {
            const { startDate } = getEventTimeDateInfo({
              event: e
            });
            const type = e?.EventTypes.name;

            const currentDate: any = getNewDate({
              date: e.startDate,
              returnDateObject: true
            });

            const eventBanner = e?.image
              ? e?.image
              : e?.locatorCompanyId
              ? DEFAULT_CSP
              : e?.partnerId
              ? DEFAULT_PARTNER_IMAGE
              : type === 'WEBCAST_ONLINE_PRESENTATION'
              ? DEFAULT_WEBCAST
              : type.includes('CONFERENCE')
              ? DEFAULT_CONFERENCE
              : type === 'USER_GROUP'
              ? DEFAULT_USER_GROUP
              : type === 'PRODUCT_TRAINING'
              ? DEFAULT_TRAINING
              : DEFAULT_IMAGE;

            return (
              <div
                key={`upcoming_event_card_${index}`}
                className="w-[30%] bg-white rounded-[10px] flex items-center mr-[13px] box-border p-[10px] cursor-pointer"
                style={{
                  flex:
                    isMobile || screenSize.width <= 819
                      ? '0 0 100%'
                      : screenSize.width >= 650 && screenSize.width <= 1291
                      ? '0 0 50%'
                      : '0 0 33.33%',
                  boxShadow: '0px 3.47368px 13.8947px rgba(51, 51, 51, 0.08)'
                }}
                onClick={() => {
                  setSelectedEvent(e);
                }}
              >
                <div className="h-full grid grid-cols-[116px_auto] items-center gap-4">
                  <div className="relative h-[116px] w-full">
                    <Image
                      src={eventBanner}
                      fill
                      className="object-cover rounded-lg brightness-50"
                      alt="Event Banner"
                      sizes="116px"
                      quality={100}
                    />
                    <Image
                      className={cn(
                        'absolute object-contain left-2 bottom-3 max-w-[80px] bg-white rounded-md',
                        e?.Partner?.logo
                          ? 'max-w-[50px]'
                          : e?.LocatorCompany?.logo
                          ? 'max-w-[50px]'
                          : ''
                      )}
                      height={40}
                      width={40}
                      src={
                        e?.Partner?.logo ||
                        e?.LocatorCompany?.logo ||
                        DEFAULT_LOGO
                      }
                      alt="Event Logo"
                      quality={100}
                    />
                    <div className="absolute left-0 top-0 w-full h-full rounded-lg" />
                  </div>
                  <div className="box-border h-full flex flex-col justify-start gap-1 py-2">
                    <span className="text-base text-[#292D32] font-normal line-clamp-2 text-ellipsis">
                      {e.title}
                    </span>
                    <span className="text-[#2360A0] text-xs font-normal">
                      {e?.EventTypes?.label}
                    </span>
                    <div className="flex items-center gap-1 mt-auto">
                      <Image
                        src={MiniCalendarIcon}
                        alt="calendar"
                        width={20}
                        height={20}
                        quality={100}
                      />
                      <span className="text-black text-xs font-light flex items-center">
                        {screenSize.width > 412
                          ? `${daysOfTheWeek[currentDate.getDay()].name.slice(
                              0,
                              3
                            )}. `
                          : ''}
                        {
                          months?.find(
                            (month: any) =>
                              Number(month.number) ===
                              currentDate.getMonth() + 1
                          )?.short
                        }{' '}
                        {currentDate.getDate()},{' '}
                        {e.allDayEvent ? (
                          <HybridTooltip
                            text={
                              hourFormat === 'h12'
                                ? '8AM - 6PM'
                                : '08:00 - 18:00'
                            }
                            side="top"
                            asChild
                          >
                            <span className="px-1 underline cursor-pointer">
                              {t('all_day')}
                            </span>
                          </HybridTooltip>
                        ) : (
                          getHoursAndMinutesFromISODate(startDate)
                        )}{' '}
                        {convertTimezoneBasedOnDate(
                          e?.timezone,
                          e.startDate,
                          timezonesData,
                          true
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
      {selectedEvent ? (
        <PublicEventModal
          open={selectedEvent ? true : false}
          onOpenChange={() => setSelectedEvent(null)}
          event={selectedEvent}
        />
      ) : null}
    </>
  ) : null;
}
