import KnightsDetail from 'components/molecules/KnightsDetail';
import { Skeleton } from 'components/ui/skeleton';
import SendEmailForm from 'componentsV2/molecules/Modals/SendEmailForm';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function KnightsFeatured({
  knightFeatured,
  isLoading = true
}: {
  knightFeatured: any;
  isLoading?: boolean;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="w-full grid grid-cols-1 gap-8">
        <h3 className="pt-[10px] text-[20px] text-[#1F384C] font-[350]">
          <span className="bg-[#CC092F] text-white p-1 font-light capitalize">
            {t('featured')}
          </span>{' '}
          {t('knight')}
        </h3>
        {isLoading ? (
          <Skeleton className="min-h-[480px] w-full" />
        ) : (
          knightFeatured && (
            <KnightsDetail
              knight={knightFeatured}
              setEmailModalOpen={setOpen}
              isFeatured
            />
          )
        )}
      </div>
      {open && (
        <SendEmailForm
          open={open}
          setOpen={setOpen}
          partnerEmail={knightFeatured?.email}
          partnerName={knightFeatured?.name}
        />
      )}
    </>
  );
}
