import StarIconFull from 'public/images/star-rating-full.svg';
import StarIconEmpty from 'public/images/star-rating-empty.svg';
import CloseIcon from 'public/images/close-select-icon.svg';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Rating } from '@mui/material';

export default function RatingFilter({
  currentFilters,
  updateFilters
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { t } = useTranslation();

  return (
    <fieldset className={`${styles.fieldset} ${styles.multiselector}`}>
      <h5 className={`${styles.disabled}`}>{t('rating')}</h5>
      <p style={{ color: '#1F384C' }}>{t('rating_filter')}</p>
      <div className={styles.stars_comp}>
        <div className={styles.stars}>
          <Rating
            id="ratingStars"
            name="half-rating"
            defaultValue={0}
            precision={0.1}
            icon={<StarIconFull />}
            emptyIcon={<StarIconEmpty />}
            value={currentFilters?.starRating || 0}
            onChange={(e: any, value: any) => {
              updateFilters('starRating', !!value ? e.currentTarget.value : 0);
            }}
          />
        </div>
        <div className={styles.values_wrapper}>
          <span className={styles.stars_descr}>
            {!!currentFilters?.starRating && currentFilters?.starRating}{' '}
            {currentFilters?.starRating < 5 && <span>{t('stars_up')}</span>}
            {currentFilters?.starRating == 5 && <span>{t('stars')}</span>}
          </span>
          {!!currentFilters?.starRating && (
            <span
              className={styles.close}
              onClick={() => {
                updateFilters('starRating', 0);
              }}
            >
              <CloseIcon />
            </span>
          )}
        </div>
      </div>
    </fieldset>
  );
}
