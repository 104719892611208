import { useContext, useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';
import CSPContext from 'context/CSPListingsProvider';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});

const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});

const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function VerticalMarkets({
  currentFilters,
  updateFilters,
  verticalMarket
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
  verticalMarket?: any;
}) {
  const { t } = useTranslation();
  const { clickOnFilter } = useContext(CSPContext);

  const [selectedVerticalMarket, setSelectedVerticalMarket] = useState<any[]>(
    []
  );

  const verticalMarket_data = verticalMarket
    .map((l) => {
      return { ...l, label: l?.name, value: l?.id };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!!currentFilters?.verticalMarket?.length) {
      const selectedVerticalMarket = currentFilters.verticalMarket.map((l) =>
        verticalMarket_data?.find((e) => e.id === Number(l))
      );
      setSelectedVerticalMarket([...selectedVerticalMarket]);
    }
  }, []);

  useEffect(() => {
    if (!currentFilters?.verticalMarket?.length) {
      setSelectedVerticalMarket([]);
    }
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="vertical_markets" className="text-base font-light">
        {t('vertical_markets')}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="vertical_markets"
          placeholder={t('vertical_markets')}
          data={verticalMarket_data}
          value={selectedVerticalMarket}
          onChange={(s: any) => {
            setSelectedVerticalMarket([...s]);
            updateFilters(
              'verticalMarket',
              s?.map((e: any) => e?.id)
            );
            clickOnFilter('verticalMarket');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
