import styles from './style.module.scss';
import { ProviderCard } from 'components/molecules/Cards/ProviderCard';
import { Skeleton } from 'components/ui/skeleton';

type ComponentProps = {
  providers: any[];
  setLoading?: any;
  isLoading?: boolean;
  loadingCount?: number;
};

export default function ProviderList({
  providers = [],
  isLoading = false,
  loadingCount = 12
}: ComponentProps) {
  return (
    <ul className={styles.card_grid}>
      {isLoading
        ? Array.apply(null, Array(loadingCount)).map((e, i) => (
            <Skeleton
              key={`loading_partner_card${i}`}
              className="h-[400px] rounded-md"
            />
          ))
        : providers?.map((provider: any) => (
            <ProviderCard key={provider.id} provider={provider} showTooltip />
          ))}
    </ul>
  );
}
