import Image from 'next/image';
import GhostImage from 'public/images/ghost-results.png';
import { useTranslation } from 'react-i18next';

export default function FinderNoResult() {
  const { t } = useTranslation();

  return (
    <section
      className="flex flex-col justify-center items-center text-center gap-8 rounded py-20 h-[60vh]"
      style={{
        background:
          'linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%)'
      }}
    >
      <div className="relative w-[234px] h-[195px] md:w-[304px] md:h-[265px]">
        <Image
          alt="no_results_image"
          src={GhostImage}
          className="object-contain"
          fill
          priority
          placeholder="blur"
        />
      </div>
      <div className="flex flex-col justify-center items-center gap-4 text-[#4a4a4a] font-light leading-6 max-w-[300px]">
        <h4 className="p-0 m-0 text-base font-normal text-[#005c8a]">
          {t('no_results')}
        </h4>
        <span>{t('try_adjust')}</span>
      </div>
    </section>
  );
}
