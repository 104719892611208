export const validateYouTubeUrl = (item) => {
  let { link, type } = item;

  if (
    type?.toLowerCase() === 'video' &&
    link?.toLowerCase()?.includes('www.youtube.com')
  ) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = link.match(regExp);
    if (match && match[2].length == 11) {
      return { ...item };
    } else {
      return { ...item, link: '', invalid: true };
    }
  }
  return { ...item };
};

export const validateVimeoUrl = (item) => {
  let { link, type } = item;

  if (
    type?.toLowerCase() === 'video' &&
    link?.toLowerCase()?.includes('vimeo')
  ) {
    if (
      /^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$/.test(
        link
      )
    ) {
      return { ...item };
    } else {
      return { ...item, link: '', invalid: true };
    }
  }
  return { ...item };
};

export const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const getVimeoIdFromUrl = (url) => {
  const match = /vimeo.*\/(\d+)/i.exec(url);
  if (match) return match[1];
};

export const getYouTubeVideoIdFromUrl = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : undefined;
};

export const isYoutubeUrl = (url) => {
  const youtubeRegex =
    /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  return url.match(youtubeRegex);
};
export const isVimeoUrl = (url) => url.toLowerCase().includes('vimeo.com');
