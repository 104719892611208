import PublicGallery from 'componentsV2/molecules/Galleries/PublicGallery';
import { cn } from 'lib/utils';
import Image from 'next/image';

export default function Resume({
  gallery,
  image,
  reverse,
  title = '',
  description = ''
}: {
  gallery?: any[];
  image?: {
    src: string;
    alt: string;
  };
  reverse?: boolean;
  title: string;
  description: string;
}) {
  return (
    <section
      className={cn(
        'flex flex-col md:flex-row gap-12 sm:gap-12 xl:gap-32',
        reverse ? 'md:flex-row-reverse' : ''
      )}
    >
      <div
        className={cn(
          'relative w-full md:w-[50%] xl:w-[35%] sm:h-[360px] h-[300px]',
          gallery ? 'pb-12' : ''
        )}
      >
        {image ? (
          <Image
            src={image.src}
            alt={image.alt}
            fill
            className="object-cover rounded-[5px]"
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            quality={100}
          />
        ) : null}
        {gallery ? <PublicGallery gallery={gallery} objectFit="cover" /> : null}
      </div>
      <div className="flex-1 flex flex-col gap-10">
        <h3 className="p-0 m-0 flex items-center text-[#1f384c] text-[1.75rem] font-light">
          {title}
        </h3>
        <span className="font-light whitespace-pre-line text-[#53565a] text-base leading-7">
          {description}
        </span>
      </div>
    </section>
  );
}
