import { DOTS, usePagination } from '../../../hooks/usePagination';
import IconArrowRight from '../../../public/images/icon-arrow-right-pagination.svg';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import useRouterStuff from 'hooks/useRouterStuff';
import {
  OLD_SITE_ADMINISTRATION_PAGE_URL,
  SITE_ADMINISTRATION_PAGE_URL
} from 'utils/constants';

type ComponentProps = {
  perPage: number;
  total: number;
  siblingCount?: number;
  currentPage: number;
  onChange: (a: number) => void;
  dataName?: string;
  showDataInfo?: boolean;
  removePadding?: boolean;
  removeMargin?: boolean;
  scrollId?: string;
};

export default function Pagination({
  perPage = 18,
  total = 0,
  siblingCount = 1,
  currentPage,
  onChange,
  dataName = 'data',
  showDataInfo = false,
  removePadding = false,
  removeMargin = false,
  scrollId
}: ComponentProps) {
  const { t } = useTranslation();
  const { pathname } = useRouterStuff();
  if (
    total <= perPage &&
    ![
      '/dashboard',
      `/${SITE_ADMINISTRATION_PAGE_URL}`,
      `/${OLD_SITE_ADMINISTRATION_PAGE_URL}`
    ].some((e) => pathname?.startsWith(e))
  ) {
    return <></>;
  }
  const paginationRange: any = usePagination({
    currentPage,
    total,
    siblingCount,
    perPage
  });

  function scrollToElement() {
    if (!scrollId) return;
    const element = document.getElementById(scrollId);
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const handleChangePage = (pageNumber) => {
    if (pageNumber === currentPage) return;

    onChange && onChange(pageNumber);
    scrollToElement();
  };

  const lastPage = paginationRange[paginationRange.length - 2];
  const startItemIndex = currentPage * perPage + 1;
  const endItemIndex = startItemIndex + perPage - 1;

  return (
    <nav
      className={`${
        showDataInfo
          ? styles.pagination_with_data
          : styles.pagination_without_data
      }`}
      style={{
        padding: `${!removePadding ? '30px 0px 30px 0px' : 0}`,
        marginBottom: `${!removeMargin ? '50px' : 0}`
      }}
    >
      {showDataInfo && (
        <span>
          {t('showing')} {dataName} {startItemIndex} {t('to')}{' '}
          {endItemIndex > total ? total : endItemIndex} {t('of')} {total}{' '}
          {t('entries')}
        </span>
      )}
      <div className={styles.pagination}>
        <button
          className={`${styles.button_pagination_nav} ${styles.left} ${
            currentPage === 0 ? styles.disabled : ''
          }`}
          disabled={currentPage === 0}
          onClick={() => handleChangePage(currentPage - 1)}
        >
          <div>
            <IconArrowRight></IconArrowRight>
          </div>
        </button>
        <ol>
          {paginationRange.map((pageNumber) => {
            const pageNumberLabel = pageNumber;

            if (pageNumber === DOTS) {
              return (
                <li key={pageNumberLabel} className={styles.dots}>
                  &#8230;
                </li>
              );
            }

            return (
              <li
                key={pageNumberLabel}
                className={pageNumber - 1 === currentPage ? styles.active : ''}
                onClick={() => handleChangePage(pageNumber - 1)}
              >
                <a>{pageNumberLabel}</a>
              </li>
            );
          })}
        </ol>
        <button
          className={`${styles.button_pagination_nav} ${
            currentPage === lastPage || !lastPage ? styles.disabled : ''
          }`}
          disabled={!lastPage || currentPage === lastPage}
          onClick={() => handleChangePage(currentPage + 1)}
        >
          <div>
            <IconArrowRight></IconArrowRight>
          </div>
        </button>
      </div>
    </nav>
  );
}
