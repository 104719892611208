'use client';
import { Calendar as CalendarIcon, X } from 'lucide-react';
import { cn } from 'lib/utils';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger
} from './popover';
import { Button } from './button';
import { Calendar } from './calendar';
import { convertDateToFormat, formatDateUTC } from 'shared/utils';
import ChangesLog from 'components/atoms/ChangesLog';
import { DateRange } from 'react-day-picker';

export function DateRangePicker({
  id,
  value = { from: undefined, to: undefined },
  onChange,
  disabled = false,
  placeholder = '--/--/----  -  --/--/----',
  triggerClassName = '',
  min,
  max,
  size = 'medium',
  allowClear = false,
  insidePortal = false,
  highlight = false,
  prevValue,
  wasChanged = false,
  approved = false,
  changed = false,
  dismiss = false
}: {
  id: string;
  disabled?: boolean;
  value: DateRange;
  onChange: Dispatch<SetStateAction<DateRange>>;
  placeholder: string;
  triggerClassName?: string;
  min: Date | undefined;
  max: Date | undefined;
  size?: 'small' | 'medium' | 'input';
  insidePortal?: boolean;
  allowClear?: boolean;
  highlight?: boolean;
  prevValue?: string;
  wasChanged?: boolean;
  approved?: boolean;
  changed?: boolean;
  dismiss?: boolean;
}) {
  const [previousDate, setPreviousDate] = useState<DateRange>({
    from: undefined,
    to: undefined
  });
  const [open, setOpen] = useState(false);
  const [openChanges, setOpenChanges] = useState(false);
  const sizes = {
    small: { input: 'text-sm px-3 py-0 h-10', icon: 'h-4 w-4' },
    medium: { input: 'text-base px-3 py-0 h-12', icon: 'h-5 w-5' },
    input: { input: 'text-base px-3 py-0 h-[54px] mt-[5px]', icon: 'h-5 w-5' }
  };

  const status = {
    approved:
      'rounded border-solid border-[#6fcf97] pr-12 focus:shadow-none hover:shadow-none focus:border-2 focus:border-solid focus:border-[#6fcf97]',
    dismiss:
      'rounded border-solid border-[#eb5757] pr-12 focus:shadow-none hover:shadow-none focus:border-2 focus:border-solid focus:border-[#eb5757]',
    changed:
      'rounded border-solid border-[#f2c94c] pr-12 focus:shadow-none hover:shadow-none focus:border-2 focus:border-solid focus:border-[#f2c94c]',
    requireReview:
      'bg-[#f0b51a33] hover:bg-[#f0b51a33] border-2 border-solid border-[#f0b51a]'
  };

  const applyClass = wasChanged
    ? approved
      ? status.approved
      : dismiss
      ? status.dismiss
      : changed
      ? status.changed
      : status.requireReview
    : approved
    ? status.approved
    : dismiss
    ? status.dismiss
    : '';

  const selectedValue: any = {
    from: value.from ? convertDateToFormat(value.from, false) : undefined,
    to: value.to ? convertDateToFormat(value.to, false) : undefined
  };

  const hasPreviousValue = wasChanged && prevValue;
  const hasClearAction = allowClear && value.from;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant="outline"
          className={cn(
            'relative justify-start text-left font-light text-black h-auto tracking-normal',
            sizes[size].input,
            'hover:shadow-[inset_0_0_0_1px_#b5bfc9] hover:bg-white disabled:bg-[#eeeeee] disabled:text-[#97999b]',
            value.from ? '' : 'text-muted-foreground',
            highlight ? 'border-none bg-[#fdefc3] hover:bg-[#fdefc3]' : '',
            hasPreviousValue || hasClearAction ? 'pr-9' : '',
            applyClass,
            triggerClassName
          )}
          disabled={disabled}
        >
          {value.from ? null : <CalendarIcon className={sizes[size].icon} />}
          {value.from ? (
            <span>
              {formatDateUTC(value.from)}{' '}
              {value.to ? `- ${formatDateUTC(value.to)}` : ''}
            </span>
          ) : (
            <span>{placeholder}</span>
          )}
          {hasPreviousValue || hasClearAction ? (
            <div className="absolute top-1/2 translate-y-[-50%] right-4 flex gap-2">
              {hasPreviousValue ? (
                <ChangesLog
                  showLog={openChanges}
                  setShowLog={setOpenChanges}
                  prevValue={prevValue}
                />
              ) : null}
              {hasClearAction ? (
                <PopoverClose asChild>
                  <X
                    className="p-0 m-0 hover:cursor-pointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange({ from: undefined, to: undefined });
                    }}
                  />
                </PopoverClose>
              ) : null}
            </div>
          ) : null}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-auto p-0 border border-solid border-gray-200"
        noPortal={!insidePortal}
      >
        <Calendar
          id={id}
          mode="range"
          fromDate={min}
          toDate={max}
          defaultMonth={selectedValue ? selectedValue.from : undefined}
          selected={selectedValue}
          onSelect={(range, selectedDay) => {
            const rangeValue = range
              ? range
              : { from: undefined, to: undefined };
            //if previous selected date has from and to, reset the range and set the selected day has the from
            //else if selected day is the to date, set it and close the calendar popover
            //else, when the selected day is the from date
            if (previousDate.from && previousDate.to) {
              const resettedRange = { from: selectedDay, to: undefined };
              onChange(resettedRange);
              setPreviousDate(resettedRange);
            } else if (!!rangeValue.to) {
              onChange(rangeValue);
              setPreviousDate(rangeValue);
              setOpen(false);
            } else {
              onChange(rangeValue);
              setPreviousDate(rangeValue);
            }
          }}
          initialFocus
          fixedWeeks
        />
      </PopoverContent>
    </Popover>
  );
}
