import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import useDevice from '../../../../../hooks/useDevice';
import styles from './style.module.scss';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});
const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});
const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function Verticals({
  currentFilters,
  updateFilters,
  verticals
}) {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  const verticals_data = verticals
    ?.map((l) => {
      return { ...l, label: l?.name, value: l?.id };
    });

  const { clickOnFilter } = useContext(PartnersContext);
  const [verts, setVerts] = useState<any[]>([]);

  useEffect(() => {
    if (currentFilters?.verticals?.length > 0) {
      const data = currentFilters.verticals.map((l) =>
        verticals_data?.find((e) => e.id === l)
      );
      setVerts([...data]);
    }
  }, []);

  useEffect(() => {
    if (!currentFilters?.verticals) setVerts([]);
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="verticals" className="text-base font-light">
        {t('verticals')}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="verticals"
          placeholder={t('verticals')}
          data={verticals_data}
          value={verts}
          onChange={(s) => {
            setVerts([...s]);
            updateFilters(
              'verticals',
              s?.map((e) => e?.id)
            );
            clickOnFilter('verticals');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
