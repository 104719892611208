import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button } from 'components/ui/button';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import EnterpriseIcon from 'public/images/enterprice_icon.svg';
import CiberSecurityIcon from 'public/images/shield_icon.svg';
import KnightProfileModal from 'components/molecules/Modal/KnightProfileModal';
import { getAPIClient } from 'services/axios';
import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import { Skeleton } from 'components/ui/skeleton';

export default function KnightsProfileHighlights() {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery({
    queryKey: ['mainPageKnights', 'highlighted'],
    queryFn: async () => getAPIClient().get(`/knights/highlighted`),
    refetchOnWindowFocus: false
  });
  const knightsHighlighted: any[] = data?.data || [];

  const [profileModal, setProfileModal] = useState(false);
  const [selectedKnight, setSelectedKnight] = useState(null);

  return (
    <>
      {isLoading || (knightsHighlighted?.length > 0 && !isLoading) ? (
        <div className="flex flex-col gap-6 w-full">
          <h3 className="p-0 m-0">{t('knights_profile_highlighted')}</h3>
          <div
            className={'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8'}
          >
            {isLoading ? (
              <>
                <Skeleton className="min-h-[200px] w-full" />
                <Skeleton className="min-h-[200px] w-full" />
                <Skeleton className="min-h-[200px] w-full" />
              </>
            ) : (
              knightsHighlighted.map((knight) => (
                <div
                  key={`highlighted_card_of_knight_${knight.name}`}
                  className={`relative bg-white min-h-[200px] w-full shadow-md rounded-2xl p-6 box-border flex flex-col justify-between gap-4`}
                >
                  <div className="flex flex-row justify-start gap-4">
                    <div className="relative max-w-[112px] max-h-[127px]">
                      <Image
                        src={knight.image}
                        alt={`${knight.name} Image`}
                        width={0}
                        height={0}
                        sizes="100vw"
                        className="w-full h-auto max-h-[127px] rounded-lg object-contain"
                      />
                    </div>
                    <div className="flex-1 flex flex-col gap-5">
                      <div className="font-normal text-lg text-[#171725]">
                        {knight.name}
                      </div>
                      {knight.bio.length > 85 ? (
                        <HybridTooltip
                          asChild
                          text={knight.bio}
                          contentClassName="font-light"
                        >
                          <div className="text-sm leading-[1.6875rem] font-light text-[#53565A]">
                            {`${knight.bio.slice(0, 82)}...`}
                          </div>
                        </HybridTooltip>
                      ) : (
                        <div className="text-sm leading-[1.6875rem] font-light text-[#53565A]">
                          {knight.bio}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-full grid grid-cols-2 items-center gap-8">
                    <Button
                      className="rounded-lg py-6"
                      onClick={() => {
                        setSelectedKnight(knight);
                      }}
                    >
                      {t('learn_more')}
                    </Button>
                    {knight.types?.length > 0 && (
                      <div className="flex gap-2 justify-end">
                        {knight.types.some((t) => t.value === 'ENTERPRISE') ? (
                          <HybridTooltip text={t('enterprise_tooltip')} asChild>
                            <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#F1BA1B]">
                              <EnterpriseIcon />
                            </div>
                          </HybridTooltip>
                        ) : null}
                        {knight.types.some(
                          (t) => t.value === 'CIBERSECURITY'
                        ) ? (
                          <HybridTooltip
                            text={t('cyber_security_tooltip')}
                            asChild
                          >
                            <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#0098C7]">
                              <CiberSecurityIcon />
                            </div>
                          </HybridTooltip>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
          {selectedKnight && (
            <KnightProfileModal
              isOpen
              setOpen={() => setSelectedKnight(null)}
              knight={selectedKnight}
            />
          )}
        </div>
      ) : null}
    </>
  );
}
