/* eslint-disable @next/next/no-img-element */
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import LinkedInIcon from '/public/images/linkedin.svg';
import EmailIcon from 'public/images/email-icon-tile.svg';
import CiberSecurityIcon from 'public/images/shield_icon.svg';
import EnterpriseIcon from 'public/images/enterprice_icon.svg';

interface ModalProps {
  knight: any;
  setEmailModalOpen?: (open: any) => void;
  isFeatured?: boolean;
  hideName?: boolean;
  disableLinksInteraction?: boolean;
  isPreview?: boolean;
}

export default function KnightsDetail({
  knight = {},
  setEmailModalOpen = () => {},
  isFeatured = false,
  hideName = false,
  disableLinksInteraction = false,
  isPreview = false
}: ModalProps) {
  const { t } = useTranslation();

  const locs = knight.locations?.map((item: any) => item['name'])?.join(', ');
  const prds = knight.products?.map((item: any) => item['level3'])?.join(', ');
  const otherProducts = knight?.otherProducts
    ?.map((item: any) => item['level3'])
    ?.join(', ');

  return (
    <div
      className={`box-border flex flex-col gap-8 bg-white ${
        isFeatured ? `p-6 md:p-10 rounded-lg shadow-md mb-4 min-h-[480px]` : ``
      }`}
    >
      <div className={styles.content}>
        <div className={styles.img}>
          <img src={knight.image} />
        </div>
        <div className={styles.name_bio}>
          {hideName ? null : <div className={styles.name}>{knight.name}</div>}
          <div className={styles.tooltip}>
            {knight.bio?.length > 200 ? (
              <HybridTooltip
                asChild
                text={knight.bio}
                contentClassName="font-light"
              >
                <div className={styles.bio}>{knight.bio.slice(0, 200)}...</div>
              </HybridTooltip>
            ) : (
              <div className={`${styles.bio} ${styles.no_cursor}`}>
                {knight.bio}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.other_info}>
        {(knight?.Partner || knight?.customPartnerName) && (
          <div>
            <span className={styles.subtitle}>{t('knights_of')}: </span>
            <span className={styles.info}>
              {knight?.Partner?.name || knight?.customPartnerName || ''}
            </span>
          </div>
        )}
        {knight.year && (
          <div>
            <span className={styles.subtitle}>{t('year_knighted_in')}: </span>
            <span className={styles.info}>{knight.year}</span>
          </div>
        )}
        {prds && (
          <div>
            <span className={styles.subtitle}>
              {t('products_knighted_in')}:{' '}
            </span>
            <span className={styles.info}>{prds}</span>
          </div>
        )}
        {otherProducts && (
          <div>
            <span className={styles.subtitle}>{t('other_products')}: </span>
            <span className={styles.info}>{otherProducts}</span>
          </div>
        )}
        {locs && (
          <div>
            <span className={styles.subtitle}>{t('geo_graph_loc')}: </span>
            <span className={styles.info}>{locs}</span>
          </div>
        )}
      </div>
      <div className={styles.wrapper}>
        <div>
          {knight.email && (
            <div className={styles.email_info}>
              <EmailIcon />
              <span className={styles.info}>
                {disableLinksInteraction ? (
                  knight.email.length > 40 ? (
                    `${knight.email.slice(0, 40)}(...)`
                  ) : (
                    knight.email
                  )
                ) : (
                  <a
                    className={styles.link}
                    onClick={() => {
                      if (isPreview) return;
                      setEmailModalOpen(true);
                    }}
                    style={{ cursor: isPreview ? 'default' : 'pointer' }}
                  >
                    {knight.email.length > 40
                      ? `${knight.email.slice(0, 40)}(...)`
                      : knight.email}
                  </a>
                )}
              </span>
            </div>
          )}
          {knight.consent && knight.linkedIn && knight.linkedIn && (
            <div className={styles.email_info}>
              <LinkedInIcon />
              <span className={styles.info}>
                {disableLinksInteraction ? (
                  knight.linkedIn
                ) : (
                  <a
                    href={
                      /^http/i.test(knight.linkedIn)
                        ? knight.linkedIn
                        : `https://${knight.linkedIn}`
                    }
                    rel="noreferrer"
                    target="_blank"
                    className={styles.link}
                  >
                    {knight.linkedIn}
                  </a>
                )}
              </span>
            </div>
          )}
        </div>
        <div className="flex items-center">
          {knight.types?.length > 0 && (
            <div className="flex gap-2">
              {knight.types.some((t) => t.value === 'ENTERPRISE') ? (
                <HybridTooltip text={t('enterprise_tooltip')} asChild>
                  <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#F1BA1B]">
                    <EnterpriseIcon />
                  </div>
                </HybridTooltip>
              ) : null}
              {knight.types.some((t) => t.value === 'CIBERSECURITY') ? (
                <HybridTooltip text={t('cyber_security_tooltip')} asChild>
                  <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#0098C7]">
                    <CiberSecurityIcon />
                  </div>
                </HybridTooltip>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
