import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as ga from '../../../../lib/ga';
import { getDayOfTheWeek, getMonths } from 'shared/constants';
import { Button } from 'components/ui/button';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import {
  getEventTimeDateInfo,
  getHoursAndMinutesFromISODate,
  getNewDate
} from 'shared/utils';
import Image from 'next/image';

const DEFAULT_IMAGE = '/images/event-broadcom-large.png';
const DEFAULT_PARTNER_IMAGE = '/images/event_banner_def_medium.png';
const DEFAULT_LOGO = '/images/broadcom-icon.png';
const DEFAULT_WEBCAST = '/images/event-webcast-large.png';
const DEFAULT_CONFERENCE = '/images/event-conference-large.png';
const DEFAULT_USER_GROUP = '/images/event-user-group-large.png';
const DEFAULT_TRAINING = '/images/event-training-large.png';
const DEFAULT_CSP = '/images/csp-event-large.png';

export default function EventCard({ event, setSelectedEvent = () => {} }) {
  const { t } = useTranslation();
  const daysOfTheWeek = getDayOfTheWeek(t);
  const months = getMonths(t);

  const { startDate: startDateISO } = getEventTimeDateInfo({ event });
  const startDate = getNewDate({ date: startDateISO, returnDateObject: true });
  const hourFormat = Intl.DateTimeFormat(navigator.language, {
    hour: 'numeric'
  }).resolvedOptions().hourCycle;
  const type = event?.EventTypes.name;

  const eventImage = event?.image
    ? event?.image
    : event?.locatorCompanyId
    ? DEFAULT_CSP
    : event?.partnerId
    ? DEFAULT_PARTNER_IMAGE
    : type === 'WEBCAST_ONLINE_PRESENTATION'
    ? DEFAULT_WEBCAST
    : type.includes('CONFERENCE')
    ? DEFAULT_CONFERENCE
    : type === 'USER_GROUP'
    ? DEFAULT_USER_GROUP
    : type === 'PRODUCT_TRAINING'
    ? DEFAULT_TRAINING
    : DEFAULT_IMAGE;

  useEffect(() => {
    ga.event({
      action: 'search',
      params: {
        search_term: `Tile presented (${event.title})`
      }
    });
  }, []);

  return (
    <div
      className="relative box-border w-full h-full p-3 flex flex-col items-start justify-between gap-7 rounded-[10px] bg-white"
      style={{
        boxShadow: '0px 3.474px 13.895px 0px rgba(51, 51, 51, 0.08)'
      }}
    >
      <div className="w-full flex flex-col items-start gap-4">
        <div className="relative w-full h-auto">
          <div className="relative w-full h-[113px]">
            <Image
              src={eventImage}
              alt="background-img"
              fill
              quality={100}
              sizes="50vw"
              className={`overflow-hidden rounded-lg ${
                event?.image ? 'object-cover' : 'object-fill'
              }`}
            />
          </div>
          <Image
            className="absolute left-2 bottom-2 rounded-sm bg-white object-contain"
            width={40}
            height={40}
            quality={100}
            src={
              event?.Partner?.logo ||
              event?.LocatorCompany?.logo ||
              DEFAULT_LOGO
            }
            alt="event-logo"
          />
        </div>
        <div className="w-full flex flex-col gap-3">
          {!!event?.region?.label && (
            <span className="text-xs font-normal text-black">
              ({event.region?.label || ''})
            </span>
          )}
          <div className="w-full flex flex-col gap-1">
            <span className="text-[#2360A0] text-base font-normal">
              {event.title}
            </span>
            <span className="text-xs font-normal text-black">
              {event.EventTypes?.label}
            </span>
          </div>
          <span className="text-[#53565A] text-sm font-light">
            {event.tagline}
          </span>
          <div className="flex items-center gap-1 my-2">
            <Image
              src={'/images/calendar_events.svg'}
              alt="calendar"
              width={20}
              height={20}
            />
            <span className="text-black text-xs font-light flex items-center">
              {daysOfTheWeek[startDate.getDay()].name.slice(0, 3)}.{' '}
              {
                months.find(
                  (month) => Number(month.number) === startDate.getMonth() + 1
                )?.name
              }{' '}
              {startDate.getDate()},{' '}
              {!event.allDayEvent ? (
                <HybridTooltip
                  text={hourFormat === 'h12' ? '8AM - 6PM' : '08:00 - 18:00'}
                  side="top"
                  asChild
                >
                  <span className="px-1 underline cursor-pointer">
                    {t('all_day')}
                  </span>
                </HybridTooltip>
              ) : (
                getHoursAndMinutesFromISODate(startDateISO)
              )}{' '}
              {event.timezone.split(' ')[0]}
            </span>
          </div>
        </div>
      </div>
      <Button
        className="h-auto py-3 px-9 font-normal text-sm rounded-lg"
        onClick={() => {
          setSelectedEvent(event);
        }}
      >
        {t('learn_more')}
      </Button>
    </div>
  );
}
