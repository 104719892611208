import { ReactNode, useEffect, useState } from 'react';
import ArrowLeft from '../../../public/images/events_arrow_circle_left.svg';
import ArrowRight from '../../../public/images/events_arrow_circle_right.svg';
import styles from './style.module.scss';
import DotNotSelected from '../../../public/images/dot_slider_not_selected.svg';
import DotSelected from '../../../public/images/dot_slider_selected.svg';

type ComponentProps = {
  children: any;
  numOfElements: any;
  title: string | ReactNode;
  showArrows?: boolean;
  slideShow?: boolean;
  setDotsToShow?: (a: any) => void;
  dotsToShow?: any;
  emblaRef?: any;
  emblaApi?: any;
  slidesToShow?: number;
  customStylingHeader?: any;
  verticalStyles?: any;
  containerProps?: any;
  customStylingTitle?: any;
};

export const Slider = ({
  children,
  numOfElements,
  title,
  showArrows = false,
  slideShow = false,
  setDotsToShow,
  dotsToShow,
  emblaRef,
  emblaApi,
  slidesToShow = 0,
  customStylingHeader = {},
  verticalStyles,
  containerProps = {},
  customStylingTitle = {}
}: ComponentProps) => {
  const [isEndReached, setIsEndReached] = useState(false);
  const [isBeginingReached, setIsBeginingReached] = useState(true);

  useEffect(() => {
    if (emblaApi) {
      const onScroll = () => {
        if (!!setDotsToShow)
          setDotsToShow(
            dotsToShow?.map((e, i) =>
              i === emblaApi.selectedScrollSnap() ? (
                <DotSelected />
              ) : (
                <div
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    emblaApi.scrollTo(i);
                  }}
                >
                  <DotNotSelected />
                </div>
              )
            )
          );
        const isAtEnd = emblaApi.scrollProgress() >= 0.99;
        setIsEndReached(isAtEnd);
        const isAtBegining =
          emblaApi.scrollProgress() <= 0.01 ||
          emblaApi.scrollProgress().toString().includes('e-7');
        setIsBeginingReached(isAtBegining);
      };

      emblaApi.on('scroll', onScroll);
      return () => {
        emblaApi.off('scroll', onScroll);
      };
    }
  }, [emblaApi]);

  useEffect(() => {
    if (slideShow) {
      const intervalId = setInterval(() => {
        if (!isEndReached) {
          emblaApi.scrollNext();
        } else {
          emblaApi.scrollTo(0);
          setIsEndReached(false);
        }
      }, 8000);
      return () => clearInterval(intervalId);
    }
  });

  return (
    <>
      <div className={styles.header} style={customStylingHeader}>
        <h3 style={customStylingTitle}>{title}</h3>
        {showArrows && (
          <div className={styles.arrows} style={customStylingHeader}>
            <ArrowLeft
              onClick={() => emblaApi.scrollPrev()}
              className={
                verticalStyles
                  ? isBeginingReached
                    ? styles.disabled_vertical
                    : styles.enabled_vertical
                  : isBeginingReached
                  ? styles.disabled
                  : styles.enabled
              }
            />
            <ArrowRight
              onClick={() => emblaApi.scrollNext()}
              className={
                isEndReached || numOfElements <= slidesToShow
                  ? verticalStyles
                    ? styles.disabled_vertical
                    : styles.disabled
                  : verticalStyles
                  ? styles.enabled_vertical
                  : styles.enabled
              }
            />
          </div>
        )}
      </div>
      <div style={{ overflow: 'hidden' }} ref={emblaRef}>
        <div
          style={verticalStyles ? verticalStyles : { display: 'flex' }}
          {...containerProps}
        >
          {children}
        </div>
      </div>
    </>
  );
};
