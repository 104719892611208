import EventsViewMore from 'components/molecules/EventsViewMore';
import styles from './style.module.scss';

export default function FilterEvents({
  element,
  right = false,
  title,
  currentFilters,
  updateFilters
}: {
  element: any;
  right: boolean;
  title: string;
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  element = element.toRender;
  const elementsAreas = element
    ?.filter((el) => el.level1 !== 'Mainframe Software')
    ?.concat(element?.filter((el) => el.level1 === 'Mainframe Software'))
    ?.sort((a, b) => (a.level2?.length < b.level2?.length ? 1 : -1));

  return (
    <fieldset className={`${styles.fieldset} grid_container`}>
      {elementsAreas?.map((item) => (
        <div
          key={item.level1}
          className={
            right
              ? `${styles.fiters_column_right}`
              : `${styles.fiters_column_left}`
          }
        >
          <div className="mt-4 mb-2 pb-0 text-base font-light">{title}</div>
          <EventsViewMore
            item={item}
            currentFilters={currentFilters}
            updateFilters={updateFilters}
          />
        </div>
      ))}
    </fieldset>
  );
}
