import { cn } from 'lib/utils';
import { HTMLAttributes } from 'react';

export default function Page({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('grid_container', className)} {...props}>
      {children}
    </div>
  );
}
