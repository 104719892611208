import { createUrl } from 'lib/utils';

export const parseFilters = (filters) => {
  let newFilters = {};

  Object.entries(filters).map(([key, value]) => {
    newFilters[key] = Array.isArray(value) ? [...value] : value;
  });

  return newFilters;
};

export const serializeToUrl = (filters) => {
  const filtersSerialized = Object.keys(filters)
    .map((key) => `${key}=${encodeURIComponent(filters[key])}`)
    .join('&');

  return filtersSerialized;
};

export const deSerializeFromUrl = (urlObject) => {
  let filtersObj = {};

  const { tab, ...filtersURLObject } = urlObject;

  Object.keys(filtersURLObject).forEach((key) => {
    const value = decodeURIComponent(filtersURLObject[key]);
    const result =
      key === 'complexSearch' ||
      key === 'complexSearchOffering' ||
      key === 'complexSearchKnight' ||
      key === 'complexSearchEvent' ||
      key === 'complexSearchUseCase' ||
      key === 'complexSearchCSP'
        ? value
        : key === 'starRating'
        ? value.split(',').join('')
        : value.includes(',')
        ? value.split(',')
        : [value];

    filtersObj[key] = result;
  });

  return { filtersObj };
};

export const addToRouter = ({ filters, validFilters, url, router }) => {
  const params =
    Object.keys(filters)?.filter((el) => validFilters?.includes(el)).length > 0
      ? `${serializeToUrl(parseFilters(filters))}`
      : '';

  const searchParams = new URLSearchParams(params);
  router.push(createUrl(url, searchParams), { scroll: false });
};
