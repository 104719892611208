/* eslint-disable @next/next/no-img-element */
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Clock8 } from 'lucide-react';
import { Slider } from 'components/molecules/Slider';
import { Skeleton } from 'components/ui/skeleton';

export default function KnightsBlogPosts({
  blogPosts = [],
  isLoading = false
}) {
  const { t } = useTranslation();

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const [lastHoveredPost, setLastHoveredPost] = useState(false);
  const slidesToShow = 3;

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    slidesToScroll: 3,
    axis: 'y',
    containScroll: 'trimSnaps'
  });

  return (
    <div className={styles.blog_posts}>
      <Slider
        numOfElements={blogPosts.length}
        title={
          <div className="pt-[10px] text-[20px] text-[#1F384C] font-[350]">
            <span className="bg-[#CC092F] text-white p-1 font-light">
              {t('knights')}
            </span>{' '}
            <span>{t('blogPosts')}</span>
          </div>
        }
        slidesToShow={slidesToShow}
        showArrows={!isLoading && slidesToShow > 0}
        emblaRef={emblaRef}
        emblaApi={emblaApi}
        verticalStyles={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '27px',
          height: '495px',
          maxHeight: '495px',
          minHeight: '495px',
          touchAction: 'pan-x',
          gap: '17px'
        }}
      >
        {isLoading ? (
          <>
            <Skeleton className="min-h-[153px] w-full" />
            <Skeleton className="min-h-[153px] w-full" />
            <Skeleton className="min-h-[153px] w-full" />
          </>
        ) : (
          blogPosts.map((post: any, index: number) => {
            return (
              <div
                className={`${styles.blog_post} ${
                  lastHoveredPost
                    ? lastHoveredPost === post.id
                      ? styles.hover_state
                      : ''
                    : index === 0
                    ? styles.hover_state
                    : ''
                } shadow-md`}
                onPointerEnter={() => setLastHoveredPost(post.id)}
              >
                <a
                  key={`blog_post_home_page_${post.id}`}
                  href={post?.link}
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                >
                  <div className={styles.content}>
                    <div className={styles.title}>
                      {post.title}{' '}
                      <span>
                        - {t('by')} {post.author}
                      </span>
                    </div>
                    <div className={styles.bottom_content}>
                      <div>{new Date(post.date)?.toDateString()}</div>
                      <div className={styles.minutes}>
                        <div className={styles.line} />
                        <Clock8 className="w-4 h-4" strokeWidth={1.5} />
                        <span>{post.readTime}</span>
                        <span>{t('Minute')}</span>
                        <span>{t('read')}</span>
                      </div>
                    </div>
                  </div>
                  {post.image && (
                    <div className={styles.image_wrapper}>
                      <div className={styles.wrapper}>
                        <img
                          src={post.image}
                          className={styles.image}
                          alt="background-img"
                        />
                        <div className={styles.overlay} />
                      </div>
                    </div>
                  )}
                </a>
              </div>
            );
          })
        )}
      </Slider>
    </div>
  );
}
