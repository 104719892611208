import ExpertiseViewMore from 'components/molecules/ExpertiseViewMore';
import { Accordion } from 'components/ui/accordion';

export default function AreasExpertise({
  products,
  marketplace,
  knights,
  events,
  currentFilters,
  updateFilters
}: {
  products: any;
  marketplace: boolean;
  knights: boolean;
  events: boolean;
  currentFilters: any;
  updateFilters: (a: string, b: any) => void;
}) {
  return (
    <Accordion type="single" collapsible>
      <fieldset
        className={`grid_container two_columns m-0 mb-2 p-0 border-0 text-[#2360a0] w-full`}
      >
        {products?.toRender?.map((area) => (
          <div
            key={area.level1}
            className="pr-0 border-0 pb-[1vw] md:pr-[1vw] md:border-0 md:border-r md:pb-0 md:border-solid md:border-[#e3e3e5]"
          >
            <div className="mt-4 mb-2 pb-0 text-base font-light">
              {area.level1}
            </div>
            <ExpertiseViewMore
              products={area}
              marketplace={marketplace}
              knights={knights}
              events={events}
              updateFilters={updateFilters}
              currentFilters={currentFilters}
            />
          </div>
        ))}
      </fieldset>
    </Accordion>
  );
}
