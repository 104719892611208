import { useTranslation } from 'react-i18next';
import Pagination from '../Pagination';
import { useCSPContext } from 'hooks/useContexts';
import FinderNoResult from './FinderNoResults';
import { Skeleton } from 'components/ui/skeleton';
import { CSPFinderControllerResult } from 'controllers/finderTools/CSPFinder';
import ProviderList from 'components/organisms/ProviderList';

type ComponentProps = {
  standalone: boolean;
  providerFinder?: CSPFinderControllerResult;
};

const defaultProviderFinder = {
  currentProviders: [],
  totalProviders: 0,
  PROVIDERS_PER_PAGE: 0,
  isLoading: false
};

export default function CSPFinderResults({
  providerFinder = defaultProviderFinder
}: ComponentProps) {
  const { t } = useTranslation();

  const {
    currentProviders,
    totalProviders,
    PROVIDERS_PER_PAGE = 12,
    isLoading = false
  } = providerFinder;

  const { currentPageFiltering, setCurrentPageFiltering } = useCSPContext();

  return (
    <>
      {isLoading || (!isLoading && currentProviders?.length > 0) ? (
        <div className="flex flex-col gap-32 mb-20">
          <section className="flex flex-col gap-10">
            <h3
              className="text-[#171725] p-0 m-0 font-normal inline-flex items-center gap-2"
              id="cspFinder-mainResults"
            >
              {t('results')}{' '}
              {isLoading ? (
                <Skeleton className="w-36 h-8" />
              ) : (
                <span className="font-extralight text-lg">
                  ({t('showing')} {totalProviders})
                </span>
              )}
            </h3>
            <ProviderList
              providers={currentProviders}
              isLoading={isLoading}
              loadingCount={12}
            />
            {isLoading ? null : (
              <div className="flex justify-between text-[#b5b7c0]">
                <Pagination
                  perPage={PROVIDERS_PER_PAGE}
                  total={totalProviders}
                  currentPage={currentPageFiltering}
                  onChange={(p) => setCurrentPageFiltering(p)}
                  showDataInfo
                  removePadding
                  scrollId="cspFinder-mainResults"
                />
              </div>
            )}
          </section>
        </div>
      ) : null}
      {!isLoading && currentProviders.length === 0 ? <FinderNoResult /> : null}
    </>
  );
}
