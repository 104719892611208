import { cn } from 'lib/utils';
import { Loader2 } from 'lucide-react';

export default function Spinner({
  className,
  strokeWidth,
  ...props
}: {
  className?: string;
  strokeWidth?: any;
}) {
  return (
    <Loader2
      className={cn('h-4 w-4 animate-spin', className)}
      strokeWidth={strokeWidth}
    />
  );
}
