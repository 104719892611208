import IconRatingStar from '../../../../public/images/icon-rating-star.svg';
import StarIconFull from '../../../../public/images/star-rating-full.svg';
import StarIconEmpty from '../../../../public/images/star-rating-empty.svg';
import StarIconReviewedFull from '../../../../public/images/star-rating-reviewed-full.svg';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Rating } from '@mui/material';

export default function RatingCard({
  partner,
  originalPartner,
  viewOnly = false,
  partnerTile = false
}) {
  return (
    <aside>
      <div
        className={
          partnerTile ? styles.stars_comp_view_tile : styles.stars_comp_view
        }
      >
        <span
          className={partnerTile ? styles.stars_descr : styles.responses_view}
        >
          {partner?.ratingStars}
        </span>
        <div
          className={partnerTile ? styles.stars_view_tile : styles.stars_view}
        >
          <Rating
            id="ratingStars"
            name="half-rating"
            defaultValue={5}
            precision={0.1}
            icon={<StarIconFull />}
            emptyIcon={<StarIconEmpty />}
            value={partner?.ratingStars}
            readOnly={viewOnly}
          />
        </div>
        <span
          className={partnerTile ? styles.stars_descr : styles.responses_view}
        >
          ({partner.ratingResponses})
        </span>
      </div>
    </aside>
  );
}
