import { ExpertiseCard } from 'components/molecules/Cards/ExpertiseCard';
import { ProviderCard } from 'components/molecules/Cards/ProviderCard';
import Pagination from 'componentsV2/molecules/Pagination';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from 'services/axios';
import { Skeleton } from 'components/ui/skeleton';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import UpcomingEvents from '../UpcomingEvents';
import { LocatorCompany } from '@prisma/client';

const PROVIDERS_PER_PAGE = 3;

export default function CSPFinderResume() {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const url = `/provider/pinacleProviders`;

  const apiClient = getAPIClient();

  const { data: providerIDs, isLoading: isLoadingProviderIDs } = useQuery({
    queryKey: ['providersIDs'],
    queryFn: async (): Promise<any> => {
      let ids = await apiClient.get<LocatorCompany[]>(`${url}?idOnly=true`);

      return ids?.data || [];
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always'
  });

  const { data: pinnacleProviders, isLoading: isLoadingProviders } = useQuery({
    queryKey: ['providersFinder', currentPage, providerIDs],
    enabled: !isLoadingProviderIDs,
    queryFn: async (): Promise<any> => {
      let res = await apiClient.get<LocatorCompany[]>(
        `${url}?ids=${JSON.stringify(
          providerIDs.slice(
            currentPage * PROVIDERS_PER_PAGE,
            currentPage * PROVIDERS_PER_PAGE + PROVIDERS_PER_PAGE
          )
        )}`
      );

      return res?.data;
    },
    refetchOnWindowFocus: false
  });

  return (
    <div className="flex flex-col justify-center items-center gap-8">
      <div className="flex flex-col gap-4 items-center text-center">
        <span className="text-[#1F384C] font-normal text-2xl md:text-[1.75rem]">
          {t('csp_finder_resume_title')}
        </span>
        <span className="text-[#53565A] font-light text-base md:text-base break-keep leading-7">
          {t('csp_finder_resume_desc')}
        </span>
      </div>
      <div className="w-full">
        <UpcomingEvents eventType="csp" />
      </div>
      <div className="grid sm:grid-cols-1 gap-4 items-center justify-center text-center md:grid-cols-2">
        <ExpertiseCard
          title={t('csp_finder_resume_expertise_title_1')}
          about={t('csp_finder_resume_expertise_desc_1')}
          type={'hosted'}
        />
        <ExpertiseCard
          title={t('csp_finder_resume_expertise_title_2')}
          about={t('csp_finder_resume_expertise_desc_2')}
          type={'managed'}
        />
        <ExpertiseCard
          title={t('csp_finder_resume_expertise_title_3')}
          about={t('csp_finder_resume_expertise_desc_3')}
          type={'public'}
        />
        <ExpertiseCard
          title={t('csp_finder_resume_expertise_title_4')}
          about={t('csp_finder_resume_expertise_desc_4')}
          type={'sovereign'}
        />
      </div>
      <div className="flex flex-col gap-4 items-center text-center mt-10">
        <span className="text-[#1F384C] font-normal text-2xl md:text-[1.75rem] flex gap-2">
          {t('csp_finder_resume_title_2')}
          <HybridTooltip
            text={t(`tooltip_ptype_Pinnacle`)}
            size="sm"
            type={'Pinnacle'}
          />
        </span>
      </div>
      <div className="w-full h-full grid grid-cols-1 gap-[1.5rem] md:grid-cols-3">
        {isLoadingProviderIDs || isLoadingProviders ? (
          <>
            <Skeleton className="relative box-border flex flex-col justify-between gap-5 items-start w-full h-[300px] p-6 rounded-[10px] bg-white" />
            <Skeleton className="relative box-border flex flex-col justify-between gap-5 items-start w-full h-[300px] p-6 rounded-[10px] bg-white" />
            <Skeleton className="relative box-border flex flex-col justify-between gap-5 items-start w-full h-[300px] p-6 rounded-[10px] bg-white" />
          </>
        ) : (
          pinnacleProviders?.map((provider: any) => (
            <div key={provider.id}>
              <ProviderCard provider={provider} />
            </div>
          ))
        )}
      </div>
      <Pagination
        perPage={PROVIDERS_PER_PAGE}
        showDataInfo
        total={providerIDs?.length}
        currentPage={currentPage}
        removePadding
        removeMargin
        onChange={(currentPage) => {
          setCurrentPage(currentPage);
        }}
      />
    </div>
  );
}
