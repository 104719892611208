import { useTranslation } from 'react-i18next';
import LampIcon from 'public/images/lamp_icon.svg';
import BoxIcon from 'public/images/box_icon.svg';

export default function MarketplaceCarousel() {
  const { t } = useTranslation();

  const styles = {
    wrapper: `flex flex-col gap-5 text-center items-center`,
    icon: 'w-[48px] h-[48px]',
    title: 'text-[#1F384C] text-xl font-normal',
    text: 'text-[#53565A] font-light text-base break-keep max-w-[369px] leading-7'
  };

  return (
    <div className="flex flex-col gap-10 text-center items-center">
      <div className="flex flex-col gap-4 items-center">
        <div className="text-[#1F384C] text-[1.75rem] font-normal">
          {t('insights_marketplace_info_title')}
        </div>
        <div className="text-[#53565A] text-base font-light">
          {t('insights_marketplace_info_desc')}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-8 items-center">
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <LampIcon />
          </div>
          <span className={styles.title}>{t('get_things_done_title')}</span>
          <span className={styles.text}>{t('get_things_done_desc')}</span>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.icon}>
            <BoxIcon />
          </div>
          <span className={styles.title}>{t('outside_the_box_title')}</span>
          <span className={styles.text}>{t('outside_the_box_desc')}</span>
        </div>
      </div>
    </div>
  );
}
