import ExpertPlus from 'public/images/expert-plus.svg';
import AwardWinner from 'public/images/award-winner.svg';
import KnightsBadge from 'public/images/badge-knights.svg';
import SaseFrameworkBadge from 'public/images/sase_framework.svg';
import EducationDelivery from 'public/images/education-delivery.png';
import { useEffect, useState } from 'react';
import * as ga from '../../../../lib/ga';
import styles from './style.module.scss';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import RatingCard from '../RatingCard';
import BadgeTooltip from '../../Tooltips/BadgeTooltip';
import { Button } from 'components/ui/button';
import Image from 'next/image';
import Loader from 'components/atoms/Loader';

export default function PartnerCard({ partner = {}, related }) {
  const {
    logo,
    name,
    accreditations,
    expertPlus,
    knights,
    awards,
    about,
    slug
  } = partner;
  useEffect(() => {
    ga.event({
      action: 'search',
      params: {
        search_term: `Tile presented (${name})`
      }
    });
  }, []);

  const tileClicked = () => {
    ga.event({
      action: 'search',
      params: {
        search_term: `Tile clicked (${name})`
      }
    });
    setLoading(true);
  };

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const hasEducationDelivery =
    partner?.capabilities?.filter(
      (el) => el?.serviceType === 'EDUCATION_DELIVERY'
    ) || [];

  return (
    <>
      {loading ? <Loader /> : null}
      <li
        className="relative box-border flex flex-col justify-between gap-7 items-start w-full h-full p-6 rounded-[10px] bg-white"
        style={{ boxShadow: '0px 3.474px 13.895px 0px rgba(51, 51, 51, 0.08)' }}
      >
        <div className="w-full flex flex-col items-start gap-4">
          <div className="w-full flex justify-between items-center">
            <Image
              src={logo}
              alt={`${name} logo image`}
              className="max-w-[100px] max-h-[50px] object-contain"
              width={100}
              height={50}
            />
            <div className={styles.badges}>
              {!!accreditations?.find((el) => el.name === 'SASE Framework') && (
                <BadgeTooltip text={t('sase_tooltip')}>
                  <div className={styles.badge}>
                    <SaseFrameworkBadge />
                  </div>
                </BadgeTooltip>
              )}
              {expertPlus && (
                <BadgeTooltip text={t('expert_plus_tooltip')}>
                  <div className={styles.badge}>
                    <ExpertPlus />
                  </div>
                </BadgeTooltip>
              )}
              {knights && (
                <BadgeTooltip text={t('knights_tooltip')}>
                  <div className={styles.badge}>
                    <KnightsBadge />
                  </div>
                </BadgeTooltip>
              )}
              {awards && (
                <BadgeTooltip text={t('awards_tooltip')}>
                  <div className={styles.badge}>
                    <AwardWinner />
                  </div>
                </BadgeTooltip>
              )}
              {hasEducationDelivery?.length > 0 && (
                <BadgeTooltip text={t('ed_delivery_tooltip')}>
                  <div className={styles.badge}>
                    <Image
                      src={EducationDelivery}
                      alt="EducationDelivery"
                      width={38}
                      height={38}
                      className="object-contain"
                    />
                  </div>
                </BadgeTooltip>
              )}
            </div>
          </div>
          <h4 className="m-0 p-0 text-[#171725] font-normal text-lg break-[break-word] overflow-x-hidden max-w-full">
            {name}
          </h4>
          <span className="text-[#53565A] text-sm font-light leading-6 line-clamp-3 max-w-full">
            {about || ''}
          </span>
          {partner.ratingResponses > 0 && (
            <RatingCard viewOnly partnerTile partner={partner} />
          )}
        </div>
        <Link
          href={`/partner/${slug}${related ? `?related=${related}` : ''}`}
          onClick={() => tileClicked()}
        >
          <Button className="py-3 h-auto px-9 text-sm font-normal rounded-md">
            {t('view_profile')}
          </Button>
        </Link>
      </li>
    </>
  );
}
