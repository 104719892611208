import styles from './style.module.scss';
import Magnifier from 'public/images/magnifier-icon.svg';
import MarketImg from 'public/images/marketplace-img.svg';
import { useTranslation } from 'react-i18next';

export default function StandaloneBanner({
  marketplace
}: {
  marketplace?: any;
}) {
  const { t } = useTranslation();

  return (
    <section
      className={`${styles.standalone_banner} ${
        marketplace ? styles.bck_img : ''
      }`}
    >
      <div className={styles.text_banner}>
        {marketplace ? <p>{t('homepage_mp')}</p> : <p>{t('homepage_2')}</p>}
      </div>
      <div className={styles.img_partner_finder}>
        {marketplace ? <MarketImg /> : <Magnifier />}
        <div className={styles.titles_container}>
          <h5>{t('insights')}</h5>
          <h3>
            {marketplace ? t('marketplace_caps') : t('partner_finder_caps')}
          </h3>
        </div>
      </div>
    </section>
  );
}
