import PublicOfferingCard from 'components/molecules/Cards/PublicOfferingCard';
import styles from './style.module.scss';
import { Skeleton } from 'components/ui/skeleton';
import { cn } from 'lib/utils';

type ComponentProps = {
  offerings: any[];
  bgContrast?: boolean;
  isLoading?: boolean;
  loadingCount?: number;
};

export default function PublicOfferingsGrid({
  offerings,
  bgContrast = false,
  isLoading = false,
  loadingCount = 6
}: ComponentProps) {
  return (
    <ul className={styles.offerings_list}>
      {isLoading
        ? Array.apply(null, Array(loadingCount)).map((e, i) => (
            <Skeleton
              key={`loading_offering_card${i}`}
              className={cn('h-[400px] rounded-md')}
            />
          ))
        : offerings?.map((offering) => (
            <PublicOfferingCard
              key={offering.id}
              offering={offering}
              bgContrast={bgContrast}
            />
          ))}
    </ul>
  );
}
