import KnightsFinderResume from '../../Resumes/KnightsFinderResume';
import FeaturedSection from './FeaturedSection';
import KnightsCarousel from './KnightsCarousel';
import KnightsProfileHighlights from './KnightsProfileHighlights';

export default function KnighstFinderPresentation() {
  return (
    <div className="flex flex-col gap-12">
      <KnightsCarousel />
      <KnightsFinderResume />
      <KnightsProfileHighlights />
      <FeaturedSection />
    </div>
  );
}
