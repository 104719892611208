'use client';
import { useEffect } from 'react';
import * as ga from '../../../lib/ga';
import { useSession } from 'next-auth/react';
import FinderToolsToolbar from 'componentsV2/molecules/Toolbars/FinderToolsToolbar';
import { getLocalStorageItem } from 'lib/localStorage';
import { LandingPageResources } from 'componentsV2/pages/LandingPage';
import Page from 'components/ui/page';
import FinderToolsPresentation from 'componentsV2/molecules/LandingPageContent/FinderToolsPresentation';
import PartnerFinderResults from 'componentsV2/molecules/FinderToolResults/PartnerFinderResults';
import MarketplaceFinderResults from 'componentsV2/molecules/FinderToolResults/MarketplaceFinderResults';
import KnightsFinderResults from 'componentsV2/molecules/FinderToolResults/KnightsFinderResults';
import EventsFinderResults from 'componentsV2/molecules/FinderToolResults/EventsFinderResults';
import { PartnerFinderControllerResult } from 'controllers/finderTools/PartnerFinder';
import { MarketplaceFinderControllerResult } from 'controllers/finderTools/MarketplaceFinder';
import { KnightsFinderControllerResult } from 'controllers/finderTools/KnightsFinder';
import { EventsFinderControllerResult } from 'controllers/finderTools/EventsFinder';
import { UseCasesFinderControllerResult } from 'controllers/finderTools/UseCasesFinder';
import { CSPFinderControllerResult } from 'controllers/finderTools/CSPFinder';
import {
  useCSPContext,
  useUseCasesContext,
  useEventsContext,
  useKnightsContext,
  useOfferingContext,
  usePartnerContext
} from 'hooks/useContexts';
import UseCasesFinderResults from 'componentsV2/molecules/FinderToolResults/UseCasesFinderResults';
import CSPFinderResults from 'componentsV2/molecules/FinderToolResults/CSPFinderResults';

type ComponentProps = {
  tab: string;
  standalone?: boolean;
  filtersResources: LandingPageResources;
  partnerFinder?: PartnerFinderControllerResult;
  marketplaceFinder?: MarketplaceFinderControllerResult;
  knightsFinder?: KnightsFinderControllerResult;
  eventsFinder?: EventsFinderControllerResult;
  useCasesFinder?: UseCasesFinderControllerResult;
  cspFinder?: CSPFinderControllerResult;
};

export default function FinderToolPage({
  tab = 'partner',
  filtersResources,
  standalone = false,
  partnerFinder,
  marketplaceFinder,
  knightsFinder,
  eventsFinder,
  useCasesFinder,
  cspFinder
}: ComponentProps) {
  const { data: session } = useSession();

  const { totalFilters } =
    tab === 'events'
      ? useEventsContext()
      : tab === 'marketplace'
      ? useOfferingContext()
      : tab === 'knights'
      ? useKnightsContext()
      : tab === 'useCases'
      ? useUseCasesContext()
      : tab === 'cloud_svcs'
      ? useCSPContext()
      : usePartnerContext();

  const { setWhereToComeBack, isFirstWebsiteVisit, setFirstWebsiteVisit } =
    usePartnerContext();

  useEffect(() => {
    setWhereToComeBack({
      standalone: standalone,
      partnerFinder: tab === 'partnerFinder' || (standalone && !!partnerFinder),
      marketplaceFinder:
        tab === 'marketplace' || (standalone && !!marketplaceFinder),
      eventsFinder: tab === 'events',
      knightsFinder: tab === 'knights',
      useCasesFinder: tab === 'useCases',
      cspFinder: tab === 'cloud_svcs'
    });
  }, [standalone, tab]);

  useEffect(() => {
    setFirstWebsiteVisit(getLocalStorageItem('isFirstWebsiteVisit') === null);
    ga.event({
      action: 'search',
      params: {
        search_term: `Visitor type (${session ? 'broadcom' : 'visitor'})`
      }
    });
  }, []);

  return (
    <>
      <div className={`w-full h-auto box-border my-0 flex flex-col gap-8`}>
        <FinderToolsToolbar
          tab={tab}
          filtersResources={filtersResources}
          isFirstWebsiteVisit={isFirstWebsiteVisit}
          setFirstWebsiteVisit={setFirstWebsiteVisit}
          standalone={standalone}
        />
        <Page className="w-full">
          {!totalFilters ? (
            <FinderToolsPresentation standalone={standalone} tab={tab} />
          ) : (
            <>
              {tab === 'partner' ? (
                <PartnerFinderResults
                  standalone={standalone}
                  partnerFinder={partnerFinder}
                />
              ) : tab === 'marketplace' ? (
                <MarketplaceFinderResults
                  standalone={standalone}
                  marketplaceFinder={marketplaceFinder}
                />
              ) : tab === 'knights' ? (
                <KnightsFinderResults
                  standalone={standalone}
                  knightsFinder={knightsFinder}
                />
              ) : tab === 'events' ? (
                <EventsFinderResults
                  standalone={standalone}
                  eventsFinder={eventsFinder}
                />
              ) : tab === 'useCases' ? (
                <UseCasesFinderResults
                  standalone={standalone}
                  useCasesFinder={useCasesFinder}
                />
              ) : tab === 'cloud_svcs' ? (
                <CSPFinderResults
                  standalone={standalone}
                  providerFinder={cspFinder}
                />
              ) : null}
            </>
          )}
        </Page>
      </div>
    </>
  );
}
