import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import Resume from './Resume';

export default function KnightsFinderResume({
  reverse = false
}: {
  reverse?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Resume
      title={t('knight_finder_title')}
      description={t('knight_finder_desc')}
      image={{
        src: '/images/knights-finder-landing.png',
        alt: 'Knights Finder Image'
      }}
      reverse={reverse}
    />
  );
}
