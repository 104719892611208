import { Button } from 'components/ui/button';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import Link from 'next/link';

export default function BannerRecentNews() {
  const { t } = useTranslation();
  return (
    <div className="relative flex items-center justify-between px-12 my-12 overflow-hidden max-w-full">
      <div className="absolute inset-0 w-full h-full">
        <Image
          src="/images/banner-vcsp-news.svg"
          alt="VCSP Banner"
          fill
          className="object-cover rounded-lg"
          quality={100}
        />
      </div>
      <div className="relative z-10 w-full flex flex-col sm:flex-row items-center justify-between">
          <h2 className="text-white w-full sm:w-4/12 leading-8 font-light text-center sm:text-left">
              {t('recent_news_from_vcsp')}
          </h2>
          <Link href="/csp_news" target='_blank'>
              <Button variant="secondary" className="text-[#2360A0] text-sm font-[350] mb-8 sm:mb-0 mt-4 sm:mt-0 sm:mr-8 text-center sm:text-left">
                  {t('read_news_from_vcsp')}
              </Button>
          </Link>
      </div>
    </div>
  );
}
