import { useContext, useEffect, useState } from 'react';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});
const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});
const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function Services({
  currentFilters,
  updateFilters,
  services
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
  services: any[];
}) {
  const { t } = useTranslation();

  const services_data = services
    .map((l) => {
      return { ...l, label: l?.name, value: l?.id };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const { clickOnFilter } = useContext(PartnersContext);
  const [servs, setServs] = useState<any[]>([]);

  useEffect(() => {
    if (currentFilters?.services?.length > 0) {
      const servs = currentFilters.services.map((l) =>
        services_data?.find((e) => e.name === l)
      );
      setServs([...servs]);
    }
  }, []);
  useEffect(() => {
    if (!currentFilters?.services) setServs([]);
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="services" className="text-base font-light">
        {t('services')}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="services"
          placeholder={t('services')}
          data={services_data}
          value={servs}
          onChange={(s) => {
            setServs([...s]);
            updateFilters(
              'services',
              s?.map((e) => e?.name)
            );
            clickOnFilter('Services');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
