import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import IconChevronLeftBlue from 'public/images/icon-chevron-left-blue.svg';
import Button from 'components/atoms/Button';
import AreasExpertise from '../AreasExpertise';
import FilterEvents from '../Events';
import Filter from '../Marketplace';
import KnightsOnStaff from '../KnightsOnStaff';
import PartnerTypes from '../PartnerTypes';
import PublicSector from '../PublicSector';
import Accreditations from '../Accreditations';
import Certifications from '../Certifications';
import RatingFilter from '../RatingFilter';
import Countries from '../Countries';
import Languages from '../Languages';
import Services from '../Services';
import UseCasesType from '../UseCasesType';
import Verticals from '../Verticals';
import BroadcomEvents from '../BroadcomEvents';
import Regions from '../Regions';
import { LandingPageResources } from 'componentsV2/pages/LandingPage';
import { Dispatch, SetStateAction } from 'react';
import { Skeleton } from 'components/ui/skeleton';
import KnightsType from '../KnightsType';
import ProviderServices from '../ProviderServices';
import VerticalMarkets from '../VerticalMarkets';
import FreeTrial from '../FreeTrial';
import ServicesProvided from '../ServicesProvided';
import TypeOfUseCases from '../TypeOfUseCases';
import UseCaseReaderType from '../UseCaseReaderType';
import FilterEventsDateRange from '../Events/EventsDateRange';
import LicensePortability from '../LicensePortability';
import PartnersContext from 'context/PartnerListingsProvider';
import CSPContext from 'context/CSPListingsProvider';
import { Country } from 'country-state-city';

type ComponentProps = {
  filtersResources: LandingPageResources;
  id: string;
  contentRef?: any;
  setPopupOpen: Dispatch<SetStateAction<boolean>>;
  handleClearAll: () => void;
  tab: string;
  hasChanges: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  updateFilters: (a: any, b: any) => void;
  currentFilters: any;
  applyFilters: () => void;
  currentTotalFilters: number;
};

export default function PopupContent({
  id = '',
  contentRef = null,
  setPopupOpen,
  handleClearAll,
  hasChanges,
  setDrawerOpen,
  filtersResources,
  updateFilters,
  currentFilters,
  applyFilters,
  currentTotalFilters,
  tab
}: ComponentProps) {
  const {
    locations = [],
    accreditations = [],
    countries = [],
    languages = [],
    prices = [],
    products = [],
    services = [],
    verticals = [],
    eventTypes = [],
    formFactors = [],
    operatingSystems = [],
    knightTypes = [],
    divisions = [],
    providerServices = [],
    servicesProvided = [],
    verticalMarket = [],
    regions = [],
    isLoading = false,
    useCaseType = [],
    readerType = []
  } = filtersResources;

  const filtersUtils = {
    currentFilters,
    updateFilters
  };

  const { t } = useTranslation();

  const marketplace = tab === 'marketplace';
  const knights = tab === 'knights';
  const events = tab === 'events';
  const useCasesTab = tab === 'useCases';
  const providers = tab === 'cloud_svcs';

  return (
    <div className={styles.popupContent} ref={contentRef} id={id}>
      <header>
        <div className={`${styles.title}`}>
          <div
            className={styles.backBtn}
            onClick={() => {
              setPopupOpen(false);
              setDrawerOpen(false);
            }}
          >
            <IconChevronLeftBlue />
          </div>
          <h4>
            {t('filters')}{' '}
            <span className={styles.selected}>
              ({currentTotalFilters} {t('selected')})
            </span>
          </h4>
        </div>
        {currentTotalFilters > 0 && (
          <a onClick={handleClearAll}>{t('clear_filters_text')}</a>
        )}
        <div className={styles.close} onClick={() => setDrawerOpen(false)}>
          X
        </div>
      </header>
      <div className={styles.info}>
        {marketplace
          ? t('filter_menus_marketplace')
          : events
          ? t('filter_events')
          : knights
          ? t('filter_menus_knights')
          : providers
          ? t('filter_menus_providers')
          : t('filter_menus')}
      </div>
      <div
        className={`grid_container ${
          knights
            ? 'three_columns'
            : useCasesTab || providers
            ? 'two_columns'
            : 'four_columns'
        }`}
      >
        {isLoading ? (
          <>
            <Skeleton className="h-[200px] md:h-[600px] w-full bg-gray-200 md:bg-gray-300" />
            <Skeleton className="h-[200px] md:h-[600px] w-full bg-gray-200 md:bg-gray-300" />
            {!providers && (
              <Skeleton className="h-[200px] md:h-[600px] w-full bg-gray-200 md:bg-gray-300" />
            )}
            {knights && (
              <Skeleton className="h-[200px] md:h-[600px] w-full bg-gray-200 md:bg-gray-300" />
            )}
          </>
        ) : (
          <>
            {!providers && !useCasesTab && (
              <div className={styles.products_columns}>
                <AreasExpertise
                  products={products}
                  marketplace={marketplace}
                  knights={knights}
                  events={events}
                  {...filtersUtils}
                />
              </div>
            )}
            {events && (
              <div className={styles.column}>
                <FilterEvents
                  element={eventTypes}
                  right={true}
                  title={t('type')}
                  {...filtersUtils}
                />
              </div>
            )}
            {marketplace && (
              <div className={styles.column}>
                <Filter
                  element={prices}
                  right={true}
                  title={t('price')}
                  {...filtersUtils}
                />
                <Filter
                  element={formFactors}
                  right={true}
                  title={t('form')}
                  {...filtersUtils}
                />
              </div>
            )}
            {marketplace && (
              <div className={styles.column}>
                <Filter
                  element={operatingSystems}
                  right={true}
                  title={t('platform_lbl')}
                  {...filtersUtils}
                />

                <Button
                  color="primary"
                  title={t('show_results')}
                  action={() => {
                    applyFilters();
                    setPopupOpen(false);
                    setDrawerOpen(false);
                  }}
                  disabled={!hasChanges}
                />
              </div>
            )}

            {!marketplace && !knights && !events && !providers && !useCasesTab && (
              <div className={styles.column}>
                <PartnerTypes {...filtersUtils} />
                <KnightsOnStaff {...filtersUtils} />
                <PublicSector {...filtersUtils} />
                <Accreditations
                  accreditations={accreditations}
                  {...filtersUtils}
                />
                <Certifications {...filtersUtils} />
                <RatingFilter {...filtersUtils} />
              </div>
            )}
            {!marketplace && !providers && !useCasesTab && (
              <div className={!events ? styles.column : ''}>
                {(events || knights) && (
                  <Regions
                    events={events}
                    regions={locations}
                    {...filtersUtils}
                  />
                )}

                {knights && (
                  <KnightsType types={knightTypes} {...filtersUtils} />
                )}

                {!knights && !events && !useCasesTab && (
                  <>
                    <Countries
                      countries={countries}
                      {...filtersUtils}
                      context={PartnersContext}
                    />
                    <Languages languages={languages} {...filtersUtils} />
                    <Services services={services} {...filtersUtils} />
                    <UseCasesType
                      useCasesType={useCaseType}
                      {...filtersUtils}
                    />
                    <Verticals verticals={verticals} {...filtersUtils} />
                  </>
                )}
                {events && (
                  <div>
                    <BroadcomEvents {...filtersUtils} />
                    <FilterEventsDateRange
                      title={t('date')}
                      {...filtersUtils}
                    />
                  </div>
                )}

                <Button
                  color="primary"
                  title={t('show_results')}
                  action={() => {
                    applyFilters();
                    setPopupOpen(false);
                    setDrawerOpen(false);
                  }}
                  disabled={!hasChanges}
                />
              </div>
            )}
            {useCasesTab && (
              <div className={styles.column}>
                <Services services={services} {...filtersUtils} />
                <Verticals verticals={verticals} {...filtersUtils} />
                <TypeOfUseCases useCaseType={useCaseType} {...filtersUtils} />
              </div>
            )}

            {useCasesTab && (
              <div className={styles.column}>
                <Regions regions={locations} {...filtersUtils} useCases />
                <UseCaseReaderType
                  readerTypeVals={readerType}
                  {...filtersUtils}
                />
                <Button
                  color="primary"
                  className={'mt-8'}
                  title={t('show_results')}
                  action={() => {
                    applyFilters();
                    setPopupOpen(false);
                    setDrawerOpen(false);
                  }}
                  disabled={!hasChanges}
                />
              </div>
            )}
            {providers && (
              <>
                <div className={styles.column}>
                  <div className="flex flex-col gap-5 ">
                    <ServicesProvided
                      servicesProvided={servicesProvided}
                      {...filtersUtils}
                    />
                    <ProviderServices
                      providerServices={providerServices}
                      {...filtersUtils}
                    />
                    <Countries
                      countries={Country.getAllCountries().map((c) => ({
                        ...c,
                        label: c.name,
                        value: c.name
                      }))}
                      {...filtersUtils}
                      context={CSPContext}
                    />
                  </div>
                </div>
                <div className={styles.column}>
                  <div className="flex flex-col gap-5">
                    <VerticalMarkets
                      verticalMarket={verticalMarket}
                      {...filtersUtils}
                    />
                    <FreeTrial {...filtersUtils} />
                    <LicensePortability {...filtersUtils} />
                    <Button
                      color="primary"
                      title={t('show_results')}
                      action={() => {
                        applyFilters();
                        setPopupOpen(false);
                        setDrawerOpen(false);
                      }}
                      disabled={!hasChanges}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
