import { useContext } from 'react';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import { useTranslation } from 'react-i18next';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import { Checkbox } from 'components/ui/checkbox';

export default function KnightsOnStaff({
  currentFilters,
  updateFilters
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { clickOnFilter } = useContext(PartnersContext);
  const { t } = useTranslation();

  return (
    <fieldset className="flex flex-col gap-2 my-4">
      <div className="m-0 p-0 font-extralight text-base text-[#2360a0]">
        {t('knights')}
        <HybridTooltip
          text={t('tooltip_knights')}
          alignAsText
          portalContainerId="landing_page_filters_popup"
        />
      </div>
      <Checkbox
        label={t('show_partners_knights')}
        id="knights"
        alignItems='start'
        onCheckedChange={(e) => {
          const checked = e ? 'on' : undefined;
          updateFilters('isKnightsOnStaff', checked);
          clickOnFilter('Knights');
        }}
        checked={
          currentFilters?.isKnightsOnStaff === 'on' ||
          currentFilters?.isKnightsOnStaff?.includes('on') ||
          false
        }
      />
    </fieldset>
  );
}
