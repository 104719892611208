import { useContext, useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});

const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});

const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function Regions({
  currentFilters,
  updateFilters,
  regions = [],
  events,
  useCases
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
  regions: any[];
  events?: boolean;
  useCases?: boolean;
}) {
  const label = useCases ? 'region_services_engaged' : 'regions';
  const { clickOnFilter } = useContext(PartnersContext);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const { t } = useTranslation();

  const regions_data = regions
    ?.filter((el) => (!events && !useCases ? el.name !== 'GLOBAL' : el.name))
    .map((l) => {
      return { ...l, label: l?.name, value: l?.id ? l?.id : l?.name };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!!currentFilters?.regions?.length) {
      const selectedRegions = currentFilters.regions.map((l) =>
        regions_data?.find((e) => e.name === l)
      );
      setSelectedRegions([...selectedRegions]);
    }
  }, []);

  useEffect(() => {
    if (!currentFilters?.regions?.length) {
      setSelectedRegions([]);
    }
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="regions" className="text-base font-light">
        {useCases ? t(label) : t(label)}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="regions"
          placeholder={t(label)}
          data={regions_data}
          value={selectedRegions}
          onChange={(s) => {
            setSelectedRegions([...s]);
            updateFilters(
              'regions',
              s?.map((e) => e?.name)
            );
            clickOnFilter('regions');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
