import { useContext, useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';
import PartnersContext from 'context/PartnerListingsProvider';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});

const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});

const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function ProviderServices({
  currentFilters,
  updateFilters,
  providerServices = []
}: {
  providerServices: any[];
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { clickOnFilter } = useContext(PartnersContext);
  const [selectedProviderServices, setSelectedProviderServices] = useState<
    any[]
  >([]);
  const { t } = useTranslation();

  const providerServices_data = providerServices
    .map((l) => {
      return { ...l, label: l?.name, value: l?.id };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!!currentFilters?.providerServices?.length) {
      const selectedProviderServices =
        currentFilters?.providerServices?.map((l) =>
          providerServices_data?.find((e) => Number(e.id) === Number(l))
        ) || [];
      setSelectedProviderServices([...selectedProviderServices]);
    }
  }, []);

  useEffect(() => {
    if (!currentFilters?.providerServices?.length) {
      setSelectedProviderServices([]);
    }
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="providerServices" className="text-base font-light">
        {t('provider_services')}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="providerServices"
          placeholder={t('provider_services')}
          data={providerServices_data}
          value={selectedProviderServices}
          onChange={(s) => {
            setSelectedProviderServices([...s]);
            updateFilters(
              'providerServices',
              s?.map((e) => e?.id)
            );
            clickOnFilter('providerServices');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
