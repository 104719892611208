import Link from 'next/link';
import { Button } from 'components/ui/button';
import React, { useState } from 'react';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import { useTranslation } from 'react-i18next';
import { cn } from 'lib/utils';
import { capitalize } from 'shared/utils';
import Image from 'next/image';
import Loader from 'components/atoms/Loader';
import { DataCenter } from 'components/organisms/ProviderContent/ProviderDataCenter/constants';
import { usePathname } from 'next/navigation';
import { usePartnerContext } from '@/hooks/useContexts';

interface Provider {
  id: number;
  locatorType: {
    name: 'Pinnacle' | 'Premier';
  };
  about: string;
  name: string;
  logo: string;
  urlSlug: string;
  dataCenters: DataCenter[];
  services: {
    name: string;
  }[];
  LocatorServicesProvided: any;
  cloudVerifiedService: any;
}

const COLOR_PROVIDER_TYPE = {
  Pinnacle: '#2360A0',
  Premier: '#528316'
};

export function ProviderCard({ provider, showTooltip = false }: any) {
  const { t } = useTranslation();
  const pathname = usePathname();
  const { setWhereToComeBack } = usePartnerContext();
  const [loading, setLoading] = useState(false);

  const {
    locatorType,
    about,
    name,
    logo,
    LocatorServicesProvided,
    dataCenters
  }: Provider = provider;

  const services = LocatorServicesProvided?.map((e) => e?.Services)?.filter(
    (el) => !!el
  );

  const isSovereignCloud = dataCenters?.some(
    (dc) => dc?.sovereignCloudApplication?.contentStatus === 'APPROVED'
  );

  return (
    <>
      {loading ? <Loader /> : null}
      <div
        className="relative box-border flex flex-col justify-between gap-5 items-start w-full h-full p-6 rounded-[10px] bg-white"
        style={{ boxShadow: '0px 3.474px 13.895px 0px rgba(51, 51, 51, 0.08)' }}
        key={provider.id}
      >
        <div className="w-full flex flex-col gap-2">
          <div className="w-full flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <div
                className={cn(
                  `w-fit px-[7px] pb-[1px] pt-[2px] flex items-center justify-start border-[1px] border-solid rounded-[2px] gap-1 h-4`,
                  locatorType?.name === 'Pinnacle'
                    ? 'border-[#2360A0]'
                    : 'border-[#528316]'
                )}
              >
                <span
                  className={cn(
                    `text-xs leading-[18px] text-left font-[300] text-[${
                      COLOR_PROVIDER_TYPE[locatorType?.name]
                    }]`
                  )}
                >
                  {capitalize(locatorType?.name)}
                </span>
                {showTooltip && (
                  <HybridTooltip
                    text={t(`tooltip_ptype_${locatorType?.name}`)}
                    size="sm"
                    type={locatorType?.name}
                  />
                )}
              </div>
              {provider.isLicensePortability && (
                <div
                  className={cn(
                    `w-fit px-[7px] pb-[1px] pt-[2px] flex items-center justify-start border-[1px] border-solid border-[#9149DA] rounded-[2px] gap-1 h-4`
                  )}
                >
                  <span
                    className={cn(
                      `text-xs leading-[18px] text-left font-[300] text-[#9149DA]`
                    )}
                  >
                    {t('LP')}
                  </span>
                  <HybridTooltip
                    text={t('tooltip_lp')}
                    size="sm"
                    type={'lic_portability'}
                  />
                </div>
              )}
            </div>
            <div className="w-fit flex justify-between">
              {services?.map((service: any) => (
                <HybridTooltip
                  key={service.name}
                  text={service.name}
                  size="sm"
                  type={service.name}
                  asChild
                >
                  <Image
                    key={service.name}
                    src={
                      service.name === 'Hosted Private Cloud'
                        ? '/images/hosted-private-icon.svg'
                        : service.name === 'Managed Private Cloud'
                        ? '/images/managed-private-icon.svg'
                        : '/images/public-cloud-icon.svg'
                    }
                    width={40}
                    height={40}
                    quality={100}
                    alt="cloud icon"
                    className="-mx-[4px]"
                  />
                </HybridTooltip>
              ))}
              {isSovereignCloud && (
                <HybridTooltip
                  key={'sovereign_cloud'}
                  text={'Sovereign Cloud'}
                  size="sm"
                  type={'sovereign_cloud'}
                  asChild
                >
                  <Image
                    key={'sovereign_cloud'}
                    src={'/images/sovereign-cloud-icon.svg'}
                    width={28}
                    height={37}
                    className="mt-[1.5px] mx-[2px]"
                    quality={100}
                    alt="cloud icon"
                  />
                </HybridTooltip>
              )}
            </div>
          </div>
          <div className="w-full h-[1px] bg-[#DEDEDE]" />
        </div>
        <Image
          src={logo}
          alt="provider_logo"
          className="max-w-[100px] max-h-[50px] object-contain"
          width={100}
          height={50}
        />
        <div className="w-full h-full flex flex-col items-start gap-4">
          <h4 className="m-0 p-0 text-[#171725] font-normal text-lg break-[break-word] overflow-x-hidden max-w-full">
            {name}
          </h4>
          <span className="text-[#53565A] text-sm font-light leading-6 line-clamp-3 max-w-full">
            {about?.trim().replace(/<[^>]*>|&nbsp;/g, '') || ''}
          </span>
        </div>
        <Link href={`/provider/${provider.urlSlug}`}>
          <Button
            className="py-3 h-auto px-9 text-sm font-normal rounded-md"
            onClick={() => {setLoading(true); if(pathname?.startsWith('/csp_news')) setWhereToComeBack({ cspNews: true })}}
          >
            {t('view_profile')}
          </Button>
        </Link>
      </div>
    </>
  );
}
