/* eslint-disable @next/next/no-img-element */
'use client';
import { useState, useRef, useEffect, useCallback } from 'react';
import Error404 from 'public/images/404-error.svg';
import styles from './style.module.scss';
import { v4 } from 'uuid';
import {
  isValidUrl,
  isVimeoUrl,
  isYoutubeUrl,
  validateVimeoUrl,
  validateYouTubeUrl
} from './auxFunctions';
import ReactPlayer from 'react-player/lazy';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import Image from 'next/image';

const slideDelay = 8000;
const margin = 'auto';
const display = 'block';
const paddingTop = '2rem';

type ComponentProps = {
  gallery: any[];
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
};

export default function PublicGallery({
  gallery = [],
  objectFit = 'contain'
}: ComponentProps) {
  const [galleryVisible, setGalleryVisible] = useState(
    gallery.map((el) => validateYouTubeUrl(el) && validateVimeoUrl(el))
  );

  const [invalidGalleryItems, setInvalidGalleryItems] = useState<number[]>([]);

  const [current, setCurrent] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const timeoutRef: any = useRef(null);
  const playerRef: any = useRef(null);
  const length = galleryVisible?.filter((el) => typeof el !== 'string').length;

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const startTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      if (current === length - 1) setCurrent(0);
      else nextSlide();
    }, slideDelay);
  };

  const seekToVideo = (startTime) => {
    if (!playerRef?.current) return;
    const player = playerRef.current;

    const currentTime = player.getCurrentTime();
    const desiredMinute = startTime;

    if (currentTime < desiredMinute) {
      player.seekTo(desiredMinute, 'seconds');
    }
  };

  const nextSlide = () => {
    if (current === length - 1) return;
    if (galleryVisible[current]?.type !== 'Image') videoStopper(current);
    setCurrent(current + 1);
  };

  const prevSlide = () => {
    if (current === 0) return;
    if (galleryVisible[current]?.type !== 'Image') videoStopper(current);
    setCurrent(current - 1);
  };

  const videoStopper = (id) => {
    let containerElement = document.getElementById(id);
    if (containerElement) {
      let iframe_tag = containerElement.querySelector('iframe');
      if (iframe_tag) {
        let iframeSrc = iframe_tag.src;
        iframe_tag.src = iframeSrc;
      }
    }
  };

  const removeInvalidImage = (key) => {
    let temp = [...galleryVisible];
    const index = temp.findIndex((e) => e.key === key);
    temp[index].invalid = true;
    setGalleryVisible([...temp]);
  };

  const onVideoError = (index) => {
    if (!(index === current)) return;
    if (invalidGalleryItems.includes(index)) return;
    setInvalidGalleryItems([...invalidGalleryItems, index]);
    resetTimeout();
    if (current === length - 1) prevSlide();
    else nextSlide();
  };

  const showMedia = useCallback(
    (media: any, index: number) => {
      const type = media?.type?.trim().toLowerCase();

      const key = v4();

      const isCurrent = current === index;

      if (!isReady) return null;

      if (type === 'image') {
        if (media?.invalid) {
          return (
            <Error404
              style={{
                margin: margin,
                display: display,
                paddingTop: paddingTop
              }}
            />
          );
        }
        return (
          <img
            src={media.link}
            alt={media.alt}
            style={{
              objectFit: objectFit,
              borderRadius: '6.82px 6.82px 0 0'
            }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            onError={(error) => {
              console.log(error);
              removeInvalidImage(media.key);
            }}
          />
        );
      }

      if (type === 'video') {
        const isYoutube = isYoutubeUrl(media.link);
        const isVimeo = isVimeoUrl(media.link);

        const startTime =
          (isYoutube || isVimeo) && media.link.includes('t=')
            ? media?.link?.split('t=')?.pop()?.replace(/\D/g, '') || 0
            : 0;

        if (invalidGalleryItems.includes(index)) {
          return (
            <Error404
              style={{
                margin: margin,
                display: display,
                paddingTop: paddingTop
              }}
            />
          );
        }

        if (isYoutube || isVimeo) {
          return (
            <div className="relative w-full h-full">
              <ReactPlayer
                ref={isCurrent ? playerRef : null}
                key={key}
                url={media.link}
                controls
                muted={false}
                onPlay={() => {
                  if (!isCurrent) return;
                  resetTimeout();
                  if (Number(startTime) > 0) {
                    seekToVideo(startTime);
                  }
                }}
                onReady={() => {
                  if (!isCurrent) return;
                  startTimeout();
                }}
                onError={() => onVideoError(index)}
                width="100%"
                height="100%"
              />
            </div>
          );
        }
        if (!isValidUrl(media.link)) {
          onVideoError(index);
          return;
        }
        return (
          <iframe
            key={key}
            src={media.link}
            title={media.alt}
            allow="fullscreen"
          />
        );
      }

      if (type === 'accreditation') {
        return (
          media.alt === 'SASE Framework' && (
            <img
              src="/images/saseframework_png.png"
              alt={media.alt}
              style={{ objectFit: 'cover' }}
            />
          )
        );
      }
    },
    [current, isReady, invalidGalleryItems]
  );

  useEffect(() => {
    const handler = (ev) => {
      const event = typeof ev.data !== 'object' ? JSON.parse(ev.data) : ev.data;
      if (event?.event !== 'play' && event?.event !== 'pause') return;
      const video = galleryVisible.findIndex((e) =>
        e.link.includes(event.uuid)
      );
      if (video !== -1) {
        resetTimeout();
      }
    };

    setIsReady(true);
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  useEffect(() => {
    resetTimeout();
    if (galleryVisible[current]?.type === 'Video') return;
    //only call start timeout on images (video players have their own onReady events)
    startTimeout();
  }, [current]);

  if (!Array.isArray(galleryVisible) || length <= 0) return null;
  if (!isReady) return null;

  return (
    <figure className={styles.gallery}>
      <ul>
        {galleryVisible
          .filter((el) => typeof el !== 'string')
          ?.map((media, index) => {
            if (typeof media !== 'string') {
              return (
                <li
                  key={`galItem_${index}`}
                  className={
                    index === current
                      ? `${styles.active}`
                      : `${styles.inactive}`
                  }
                  id={`${index}`}
                >
                  {showMedia(media, index)}
                </li>
              );
            }
          })}
      </ul>
      <div
        className={cn(
          galleryVisible[current]?.alt &&
            galleryVisible[current]?.alt?.length > 0 &&
            length > 1
            ? 'box-border grid grid-cols-[1fr_auto] items-center gap-8 rounded-b-[5px] bg-black max-h-[110px] overflow-y-auto p-3'
            : ''
        )}
      >
        <span className="text-xs text-[#eeeeee] font-light line-clamp-1">
          {galleryVisible[current]?.alt || ''}
        </span>
        {length > 1 && (
          <div className="flex items-center gap-4 text-[#eeeeee]">
            <Button
              aria-label="previous slide"
              disabled={current === 0}
              variant="secondary"
              icon="chevron-left"
              className="w-6 h-6 p-0 rounded-full"
              onClick={prevSlide}
            />
            <div className="whitespace-nowrap text-xs">
              {current + 1} / {length}
            </div>
            <Button
              aria-label="next slide"
              disabled={current === length - 1}
              variant="secondary"
              icon="chevron-right"
              className="w-6 h-6 p-0 rounded-full"
              onClick={nextSlide}
            />
          </div>
        )}
      </div>
    </figure>
  );
}
