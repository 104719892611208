import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OfferingsContext from '../../../context/OfferingsListingsProvider';
import { Checkbox } from 'components/ui/checkbox';
import { cn } from 'lib/utils';

export default function MarketPlaceViewMore({
  item,
  currentFilters,
  updateFilters
}) {
  const { setCurrentPageFiltering, clickOnFilter } =
    useContext(OfferingsContext);
  const { t } = useTranslation();

  const [viewMore, setViewMore] = useState(null);
  const selectedPrices = currentFilters?.price || [];
  const selectedFormFactors = currentFilters?.form_factor || [];
  const selectedOperatingSystems = currentFilters?.operating_system || [];
  const selectedElements = selectedPrices
    .concat(selectedFormFactors)
    .concat(selectedOperatingSystems);

  return (
    <fieldset className="mb-4 flex flex-col gap-4">
      {item?.level2?.map((value, idx) => {
        return (
          <div
            key={idx}
            className={cn(
              'border-0 border-b border-solid border-[#e3e3e5]',
              idx >= 3 && viewMore !== item?.level2 ? 'hidden' : 'block'
            )}
          >
            <div className="mb-2">
              <Checkbox
                reverse
                key={value.name}
                id={value.label}
                label={value.label}
                onCheckedChange={() => {
                  setCurrentPageFiltering(0);
                  updateFilters(item.level1, value.name);
                  clickOnFilter(`${item.level1} - ${value.label}`);
                }}
                checked={selectedElements?.includes(value.name)}
              />
            </div>
          </div>
        );
      })}
      {item?.level2?.length > 3 && (
        <div
          className="text-[#2360a0] text-sm cursor-pointer ml-4 leading-6 font-normal"
          onClick={() =>
            setViewMore(viewMore === item?.level2 ? null : item?.level2)
          }
        >
          {viewMore === item?.level2
            ? t('view_less') + ' - '
            : t('view_more') + ' + '}
        </div>
      )}
    </fieldset>
  );
}
