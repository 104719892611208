import { format } from 'date-fns-tz';
import { createDateWithEDT } from 'shared/utils';

export const updatePartnerFilters = (setFilters, key, value) => {
  setFilters((prevFilters) => {
    let objCopy = { ...prevFilters };
    if (
      key === 'isPublic' ||
      key === 'isClearedResources' ||
      key === 'isKnightsOnStaff'
    ) {
      key in objCopy ? delete objCopy[key] : (objCopy[key] = value);
    }
    if (key === 'starRating') {
      if (value == 0) {
        delete objCopy[key];
      } else {
        objCopy[key] = value;
      }
    }
    if (key === 'certifications') {
      if (value.length === 0) {
        delete objCopy[key];
      } else {
        objCopy[key] = value;
      }
    }
    if (key === 'expertise') {
      if (key in objCopy) {
        if (objCopy[key].includes(value)) {
          let index = objCopy[key].indexOf(value);
          objCopy[key].splice(index, 1);
          if (objCopy[key].length === 0) delete objCopy[key];
        } else {
          const arr = objCopy?.[key] || [];
          objCopy[key] = [...arr, value];
        }
      } else {
        objCopy[key] = [value];
      }
    }
    if (
      key === 'partnerTypeAuthorizedEducationDelivery' ||
      key === 'partnerTypeConsultingServices' ||
      key === 'partnerTypeAuthorizedTechnicalSupport'
    ) {
      if (key in objCopy) {
        if (objCopy[key].includes(value)) {
          let index = objCopy[key].indexOf(value);
          objCopy[key].splice(index, 1);
          if (objCopy[key].length === 0) delete objCopy[key];
        } else {
          const arr = objCopy?.[key] || [];
          objCopy[key] = [...arr, value];
        }
      } else {
        objCopy[key] = [value];
      }
    }
    if (
      [
        'regions',
        'countries',
        'languages',
        'services',
        'useCasesType',
        'verticals'
      ].includes(key)
    ) {
      objCopy[key] = value;
      if (objCopy[key].length === 0) {
        delete objCopy[key];
      }
    }
    if (key === 'complexSearch') {
      objCopy[key] = value;
      if (objCopy[key].length === 0) {
        delete objCopy[key];
      }
    }
    if (key === 'accreditations') {
      if (key in objCopy) {
        if (objCopy[key].includes(value)) {
          let index = objCopy[key].indexOf(value);
          objCopy[key].splice(index, 1);
          if (objCopy[key].length === 0) delete objCopy[key];
        } else {
          const arr = objCopy?.[key] || [];
          objCopy[key] = [...arr, value];
        }
      } else {
        objCopy[key] = [value];
      }
    }

    return objCopy;
  });
};

export const updateCSPFilters = (setFilters, key, value) => {
  setFilters((prevFilters) => {
    let objCopy = { ...prevFilters };
    if (['isFreeTrial', 'isLicensePortability'].includes(key)) {
      key in objCopy ? delete objCopy[key] : (objCopy[key] = value);
    }

    if (
      [
        'countries',
        'providerServices',
        'verticalMarket',
        'servicesProvided'
      ].includes(key)
    ) {
      objCopy[key] = value;
      if (objCopy[key].length === 0) {
        delete objCopy[key];
      }
    }
    if (key === 'complexSearchCSP') {
      objCopy[key] = value;
      if (objCopy[key].length === 0) {
        delete objCopy[key];
      }
    }

    return objCopy;
  });
};

export const updateMarketplaceFilters = (setFilters, key, value) => {
  setFilters((prevFilters) => {
    let objCopy = { ...prevFilters };
    if (
      ['price', 'form_factor', 'expertise', 'operating_system'].includes(key)
    ) {
      if (key in objCopy) {
        if (objCopy[key].includes(value)) {
          let index = objCopy[key].indexOf(value);
          objCopy[key].splice(index, 1);
          if (objCopy[key].length === 0) delete objCopy[key];
        } else {
          const arr = objCopy?.[key] || [];
          objCopy[key] = [...arr, value];
        }
      } else {
        objCopy[key] = [value];
      }
    }

    if (key === 'complexSearchOffering') {
      objCopy[key] = value;
      if (objCopy[key].length === 0) {
        delete objCopy[key];
      }
    }

    return objCopy;
  });
};

export const updateEventsFilters = (setFilters, key, value) => {
  setFilters((prevFilters) => {
    let objCopy = { ...prevFilters };

    if (['expertise', 'event_types'].includes(key)) {
      if (key in objCopy) {
        if (objCopy[key].includes(value)) {
          let index = objCopy[key].indexOf(value);
          objCopy[key].splice(index, 1);
          if (objCopy[key].length === 0) delete objCopy[key];
        } else {
          const arr = objCopy?.[key] || [];
          objCopy[key] = [...arr, value];
        }
      } else {
        objCopy[key] = [value];
      }
    } else if (key === 'isBroadcomEvents') {
      key in objCopy ? delete objCopy[key] : (objCopy[key] = value);
    } else if (['complexSearchEvent', 'regions'].includes(key)) {
      objCopy[key] = value;
      if (objCopy[key].length === 0) {
        delete objCopy[key];
      }
    } else if (key === 'events_date_range_filter') {
      const { from, to } = value;
      for (const { date, key } of [
        { date: from, key: 'start_date_filter' },
        { date: to, key: 'end_date_filter' }
      ]) {
        if (isValidDate(date)) {
          objCopy[key] = [format(createDateWithEDT(date), 'yyyy-MM-dd')];
        } else delete objCopy[key];
      }
    } else {
      if (isValidDate(value))
        objCopy[key] = [format(createDateWithEDT(value), 'yyyy-MM-dd')];
      else delete objCopy[key];
    }

    return objCopy;
  });
};

function isValidDate(d: any) {
  return !isNaN(d) && d instanceof Date;
}

export const updateKnightsFilters = (setFilters, key, value) => {
  setFilters((prevFilters) => {
    let objCopy = { ...prevFilters };

    if (key === 'expertise') {
      if (key in objCopy) {
        if (objCopy[key].includes(value)) {
          let index = objCopy[key].indexOf(value);
          objCopy[key].splice(index, 1);
          if (objCopy[key].length === 0) delete objCopy[key];
        } else {
          const arr = objCopy?.[key] || [];
          objCopy[key] = [...arr, value];
        }
      } else {
        objCopy[key] = [value];
      }
    }
    if (key === 'complexSearchKnight' || key === 'regions' || key === 'types') {
      objCopy[key] = value;
      if (objCopy[key].length === 0) {
        delete objCopy[key];
      }
    }

    return objCopy;
  });
};

export const updateUseCasesFilters = (setFilters, key, value) => {
  setFilters((prevFilters) => {
    let objCopy = { ...prevFilters };

    if (key === 'complexSearchUseCase') {
      objCopy[key] = value;
      if (objCopy[key].length === 0) {
        delete objCopy[key];
      }
    } else if (['readerType'].includes(key)) {
      objCopy[key] = [value];
    } else if (
      [
        'businessUnits',
        'services',
        'verticals',
        'regions',
        'useCaseType',
        'readerType'
      ].includes(key)
    ) {
      objCopy[key] = value;
      if (objCopy[key].length === 0) {
        delete objCopy[key];
      }
    }
    return objCopy;
  });
};

export const formatComplexSearch = (e) => {
  return e
    ?.split(';')
    .map((e) => e.trim())
    .filter((e) => e)
    .join(';');
};

const arraysOfStringsAreEqual = (a, b) => {
  return (
    a
      ?.filter((id1) => !b?.some((id2) => id2 === id1))
      ?.concat(b?.filter((id1) => !a?.some((id2) => id2 === id1))).length === 0
  );
};

export const compareFilters = (current = {}, original = {}) => {
  //true: Filters different! | false: Filters equal!
  const cKeys = Object.keys(current);
  const oKeys = Object.keys(original);

  if (!arraysOfStringsAreEqual(cKeys, oKeys)) return true;

  //last compare inside keys
  return cKeys.some((key) => {
    const currentVal = current[key];
    const originalVal = original[key];

    //compare arrays of strings
    if (Array.isArray(currentVal)) {
      if (!arraysOfStringsAreEqual(currentVal, originalVal)) return true;
      else return false;
    } else {
      //Compare strings
      if (currentVal !== originalVal) return true;
      else return false;
    }
  });
};

export const parseFilters = (filters) => {
  let newFilters = {};

  Object.entries(filters).map(([key, value]) => {
    newFilters[key] = Array.isArray(value) ? [...value] : value;
  });

  return newFilters;
};
