import { cn } from 'lib/utils';
import { HTMLAttributes } from 'react';

type ComponentProps = HTMLAttributes<HTMLDivElement> & {};

function Skeleton({ className, ...props }: ComponentProps) {
  return (
    <div
      className={cn('animate-pulse rounded-md bg-muted', className)}
      {...props}
    />
  );
}

export { Skeleton };
