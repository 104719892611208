import IconClose from '../../../public/images/icon-historic-close.svg';
import styles from './style.module.scss';

export default function SelectTooltip({
  setShowTooltip,
  data = [],
  type,
  onChange,
  value
}) {
  const onRemove = (id) => {
    let temp = [...value];
    temp = temp.filter((e) => e.id !== id);
    onChange(temp);
  };

  return (
    <div
      className={`${styles.tooltip}`}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div className={styles.data_container}>
        <div className={styles.header}>
          <div className={styles.title}>All {type || 'values'}:</div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setShowTooltip(false);
            }}
          >
            <IconClose></IconClose>
          </div>
        </div>
        <div className={styles.data}>
          {data.map((e, index) => (
            <div key={index} className={`${styles.label_container}`}>
              <div className={styles.label}>{e?.label}</div>
              <div
                className={styles.removeIcon}
                onClick={() => onRemove(e?.id)}
              >
                x
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
