import { Button } from '@/components/ui/button';
import { getBaseURL } from '@/shared/utils';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { EBOOK_DEV, EBOOK_PROD } from './constants';
import Carousel from '../Presentations/FindYourExpertPresentation/Carousel';

export default function FindYourExpertResume() {
  const { t } = useTranslation();

  const Card = ({
    title,
    descr,
    src,
    url
  }: {
    title: string;
    descr: string;
    src: string;
    url: string;
  }) => {
    return (
      <div className="flex flex-col gap-8 bg-white px-auto py-8 rounded-[10px] md:w-1/2">
        <div className="mx-8 h-44">
          <div className="w-full h-40 md:h-full relative">
            <Image
              src={src}
              alt="Find Your Expert Use Case"
              className="absolute object-cover my-auto"
              fill
            />
          </div>
        </div>
        <div className="flex flex-col gap-4 px-8">
          <span className="text-[18px] leading-5">{title}</span>
          <span className="text-[14px] leading-6 font-[100] pr-10">
            {descr}
          </span>
        </div>

        <Button
          variant="default"
          className="w-fit text-[14px] ml-8"
          onClick={() => window.open(url, '_blank')}
        >
          {t('find_out_more')}
        </Button>
      </div>
    );
  };

  return (
    <div className="flex flex-col mx-auto w-[90%] h-full rounded-[10px] break-word">
      <div className="flex flex-col h-full xl:flex-row mt-8 lg:mt-0 gap-8 justify-between text-[#1F384C]">
        <div className="w-full xl:w-3/4 h-[216px] xl:h-[410px] relative">
          <Image
            src="/images/ebook_thumbnail.png"
            alt="Find Your Expert Image"
            className="absolute object-cover xl:object-scale-down my-auto"
            fill
          />
        </div>
        <div className="flex flex-col w-fit justify-center mx-4 gap-2 lg:gap-5">
          <div className="flex flex-col gap-2">
            <h3 className="p-0 m-0 text-[22px] lg:text-[24px] font-[500]">
              {t('access_guide_title')}
            </h3>
            <h4 className="p-0 m-0 text-[18px] md:text-[20px] font-[300] leading-7 xl:leading-10">
              {t('access_guide_subTitle')}
            </h4>
          </div>
          <div className="flex leading-7 text-[#65696D] text-[14px] md:text-[16px] lg:pr-24">
            <span className="font-[100]">
              {t('access_guide_descr_1')}{' '}
              <span className="font-[400]">{t('access_guide_descr_2')}</span>
            </span>
          </div>
          <Button
            variant="default"
            className="w-fit text-[12px] md:text-[14px] mt-4 lg:mt-0"
            onClick={() =>
              window.open(
                getBaseURL()?.includes('expert.broadcom')
                  ? EBOOK_PROD
                  : EBOOK_DEV,
                '_blank'
              )
            }
          >
            {t('access_guide')}
          </Button>
        </div>
      </div>
      <Carousel />
      <div className="flex flex-col gap-4 mt-14">
        <span className="text-[#1F384C] font-[300] text-[28px] leading-9 md:text-[1.75rem]">
          {t('explore_partner_insights')}
        </span>
        <div className="flex gap-6 w-full justify-evenly flex-col md:flex-row">
          <Card
            title={t('card_find_your_useCase_title')}
            descr={t('card_find_your_useCase_descr')}
            src={'/images/find-my-expert-usecases.jpeg'}
            url={`${getBaseURL()}/useCases`}
          />
          <Card
            title={t('card_find_your_partner_title')}
            descr={t('card_find_your_partner_descr')}
            src={'/images/find-my-expert-partners.jpeg'}
            url={`${getBaseURL()}`}
          />
        </div>
      </div>
    </div>
  );
}
