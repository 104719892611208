import styles from './style.module.scss';
import { Skeleton } from 'components/ui/skeleton';
import UseCaseCard from 'components/molecules/Cards/UseCaseCard';
import { Dispatch, SetStateAction } from 'react';

export default function FinderUseCasesList({
  useCases,
  isLoading = false,
  loadingCount = 12,
  setUseCases
}: {
  useCases: any[];
  isLoading?: boolean;
  loadingCount?: number;
  setUseCases?: Dispatch<SetStateAction<any[]>>;
}) {
  return (
    <ul className={styles.useCases_list}>
      {isLoading
        ? Array.apply(null, Array(loadingCount)).map((e, i) => (
            <Skeleton
              key={`loading_usecase_card${i}`}
              className="h-[400px] rounded-md"
            />
          ))
        : useCases?.map((useCase) => (
            <UseCaseCard
              key={useCase.id}
              useCase={useCase}
              setSelectedUseCase={() => {}}
              setUseCases={setUseCases}
            />
          ))}
    </ul>
  );
}
