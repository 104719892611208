import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from 'services/axios';
import { Skeleton } from 'components/ui/skeleton';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PublicOfferingsGrid from 'componentsV2/organisms/PublicOfferingsGrid';

export default function HighlightedIntegrations() {
  const { t } = useTranslation();

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth || 0,
    height: window.innerHeight || 0
  });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };
    window.addEventListener('resize', updateDimension);
    updateDimension();
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);

  const {
    data,
    isLoading: loadingOfferings,
    isSuccess: isReady
  } = useQuery({
    queryKey: ['highlightedOfferings'],
    queryFn: () => getAPIClient().get(`/offering/hightlighted`)
  });
  const offerings: any[] = data?.data || [];

  const integrations_to_show =
    screenSize.width < 768
      ? 1
      : screenSize.width < 1201
      ? 2
      : screenSize.width > 2000
      ? 4
      : 3;

  return loadingOfferings || (offerings.length > 0 && isReady) ? (
    <div className="flex flex-col gap-8">
      <h3 className="p-0 m-0 text-[#1F384C] text-[1.75rem] font-light">
        {t('highlighted_integrations')}
      </h3>
      {loadingOfferings ? (
        <div
          className="grid gap-8 items-center w-full"
          style={{
            gridTemplateColumns: `repeat(${integrations_to_show}, 1fr)`
          }}
        >
          {Array.apply(null, Array(integrations_to_show)).map((a, index) => (
            <LoadingCard
              key={`loading_hightlighted_integration_card_${index}`}
            />
          ))}
        </div>
      ) : (
        <PublicOfferingsGrid
          offerings={offerings.slice(0, integrations_to_show)}
        />
      )}
    </div>
  ) : null;
}

const LoadingCard = () => {
  return (
    <div className="relative bg-white h-[400px] w-full rounded-xl shadow-md p-4 box-border flex flex-col gap-4">
      <Skeleton className="absolute top-0 right-6 w-24 h-8 rounded-b-md" />
      <div className="flex items-center gap-4">
        <Skeleton className="w-16 h-16 rounded-full" />
        <Skeleton className="w-16 h-16 rounded-full" />
      </div>
      <Skeleton className="w-[50%] h-8 rounded-md" />
      <Skeleton className="w-[90%] h-8 rounded-md" />
      <Skeleton className="w-[80%] h-8 rounded-md" />
      <Skeleton className="w-[70%] h-8 rounded-md" />
      <div className="grid grid-cols-2 items-center gap-4 mt-auto">
        <Skeleton className="w-full h-14 rounded-md" />
        <div className="grid grid-cols-[auto_1fr] items-center gap-2 w-full">
          <Skeleton className="w-12 h-12 rounded-full" />
          <Skeleton className="w-[75%] h-8 rounded-md" />
        </div>
      </div>
    </div>
  );
};
