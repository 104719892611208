import { useTranslation } from 'react-i18next';
import Resume from './Resume';

export default function PartnerFinderResume({
  reverse = false
}: {
  reverse?: boolean;
}) {
  const { t } = useTranslation();

  const galleryPartnerFinder = [
    {
      alt: t('video_1_alt'),
      key: '1',
      link: '/images/partner-finder-landing.png',
      type: 'Image'
    }
    // {
    //   alt: t('video_1_alt'),
    //   key: '1',
    //   link: 'https://play.vidyard.com/N5T4gfCExXT4d6tFYvSkrY.html',
    //   type: 'Video'
    // }
  ];

  return (
    <Resume
      title={t('secure_experts')}
      description={t('paragraph_1')}
      gallery={galleryPartnerFinder}
      reverse={reverse}
    />
  );
}
