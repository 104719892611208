import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import useMount from '../../../hooks/useMount';
import styles from './style.module.scss';
import useScroll from 'hooks/useScroll';
export const Drawer = ({ isOpen = false, children }) => {
  const isMounted = useMount();
  const [blockScroll, allowScroll] = useScroll();

  useEffect(() => {
    isOpen ? blockScroll() : allowScroll();
  }, [isOpen]);

  if (!isMounted) return null;

  return (
    <>
      {createPortal(
        <div className={`${styles.drawer} ${isOpen ? styles.is_open : ''}`}>
          {children}
        </div>,
        document?.body
      )}
    </>
  );
};
