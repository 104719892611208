import { useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import SelectTooltip from '../SelectTooltip';
import styles from './style.module.scss';
import useOnScreen from 'hooks/useOnScreen';
import ChangesLog from '../ChangesLog';

export default function SelectLimitedMultiLog({
  id,
  value,
  instanceId,
  prevValue,
  data,
  onChange,
  placeholder,
  customStyles,
  closeMenuOnSelect,
  wasChanged,
  disabled,
  approved,
  changed,
  dismiss,
  fullHeight,
  maxTags = 4
}) {
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  const [height, setHeight] = useState(0);
  const [showLog, setShowLog] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setHeight(ref?.current?.offsetHeight);
  }, [isVisible, ref?.current?.offsetHeight]);

  const approvedProps = { bdWidth: '2px', bdColor: '#6fcf97' };
  const dismissProps = { bdWidth: '2px', bdColor: '#CC2130' };
  const changedProps = { bdWidth: '2px', bdColor: '#f2c94c' };
  const requiredReviewProps = {
    bdWidth: '2px',
    bdColor: '#f2c94c',
    bg: '#f0b51a33'
  };

  const colors = wasChanged
    ? approved
      ? approvedProps
      : dismiss
      ? dismissProps
      : changed
      ? changedProps
      : requiredReviewProps
    : approved
    ? approvedProps
    : dismiss
    ? dismissProps
    : { bdWidth: '1px', bdColor: '#bbbcbc' };

  const MultiValue = (props) => {
    const { index } = props;

    if (index < maxTags) {
      return <components.MultiValue {...props} />;
    }
    if (index === maxTags) {
      return (
        <div className={`${styles.tooltip_wrapper}`}>
          <div
            onMouseDown={(e) => e.stopPropagation()}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            className={`${styles.custom_tooltip} ${
              wasChanged ? styles.tooltipChanged : ''
            }`}
          >
            ...
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`${styles.container} ${disabled ? styles.disabled : ''} ${
        fullHeight ? styles.full_height : ''
      }`}
      ref={ref}
    >
      <Select
        id={id}
        isDisabled={disabled}
        instanceId={instanceId}
        inputId={instanceId}
        menuPlacement={'auto'}
        isMulti
        blurInputOnSelect={false}
        styles={{
          ...customStyles,
          option: (provided, state) => ({
            ...provided,
            color: state.value === 'select-all' ? '#cc2031' : provided.color
          }),
          control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? 'inset 0 0 0 1px #005c8a' : 0,
            backgroundColor: colors.bg || 'white',
            borderColor: state.isFocused ? '#005c8a' : colors.bdColor,
            borderWidth: state.isFocused ? '1px' : colors.bdWidth,
            '&:hover': { borderColor: '#005c8a' },
            height: fullHeight ? '100%' : 'auto',
            minHeight: '54px',
            fontWeight: 'lighter',
            alignItems: 'flex-start'
          }),
          clearIndicator: (styles) => ({
            ...styles,
            paddingTop: '16px'
          }),
          valueContainer: (provided) => ({
            ...provided,
            maxWidth: '90%',
            minHeight: '54px'
          })
        }}
        placeholder={placeholder}
        closeMenuOnSelect={closeMenuOnSelect}
        options={data}
        onChange={onChange}
        value={value}
        components={{ MultiValue }}
      />

      {value?.length > 1 && showTooltip && (
        <SelectTooltip
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          data={value}
          type={placeholder}
          onChange={onChange}
          value={value}
        />
      )}
      {wasChanged && prevValue && (
        <div>
          <a
            className={`${styles.image} ${
              height > 75 ? styles.bottom : styles.top
            } ${value.length > 0 ? '' : styles.no_val}`}
            onClick={() => setShowLog(!showLog)}
          >
            <div style={{ position: 'relative' }}>
              <ChangesLog
                showLog={showLog}
                setShowLog={setShowLog}
                prevValue={prevValue}
              />
            </div>
          </a>
        </div>
      )}
    </div>
  );
}
