import { Dispatch, SetStateAction, useRef, useState } from 'react';
import FilterIcon from 'public/images/filter-icon.svg';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import useOutsideClickMultiple from 'hooks/useOutsideClickMultiple';
import PopupContent from './PopupContent';
import { Popup } from 'components/molecules/Popup';
import { Drawer } from 'components/molecules/Drawer';
import { LandingPageResources } from 'componentsV2/pages/LandingPage';
import {
  useEventsContext,
  useKnightsContext,
  useOfferingContext,
  usePartnerContext,
  useCSPContext,
  useUseCasesContext
} from 'hooks/useContexts';

type ComponentProps = {
  tab: string;
  filtersResources: LandingPageResources;
  totalFilters: number;
  visibleTotalFilters: number;
  filters: any;
  hasChanges: boolean;
  isPopupOpen: boolean;
  inputRefTooltip: any;
  searchBtnRef: any;
  currentFilters: any;
  setPopupOpen: Dispatch<SetStateAction<boolean>>;
  handleFirstVisit: () => void;
  clearFilters: () => void;
  updateFilters: (a: any, b: any) => void;
  applyFilters: () => void;
};

export default function FiltersPopup({
  tab,
  filtersResources,
  totalFilters,
  visibleTotalFilters,
  filters,
  hasChanges,
  isPopupOpen = false,
  inputRefTooltip,
  searchBtnRef,
  currentFilters,
  setPopupOpen = () => {},
  handleFirstVisit = () => {},
  clearFilters = () => {},
  updateFilters = () => {},
  applyFilters = () => {}
}: ComponentProps) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { clearFiltersExceptSearch, validPartnerFinderFilters } =
    usePartnerContext();
  const { clearOffFiltersExceptSearch, validMarketplaceFinderFilters } =
    useOfferingContext();
  const { clearKnightFiltersExceptSearch, validKnightsFinderFilters } =
    useKnightsContext();
  const { clearEventsFiltersExceptSearch, validEventsFinderFilters } =
    useEventsContext();
  const { clearUseCasesFilters, validUseCasesFinderFilters } =
    useUseCasesContext();
  const { clearCSPFiltersExceptSearch, validCSPFinderFilters } =
    useCSPContext();

  const contentRef: any = useRef(null);
  const filterBtnRef: any = useRef(null);

  //handle outside click of filters popup, search input and filter button
  useOutsideClickMultiple(
    [contentRef, inputRefTooltip, filterBtnRef, searchBtnRef],
    () => setPopupOpen(false)
  );

  const isSearchFilterApplied =
    tab === 'marketplace'
      ? filters.hasOwnProperty('complexSearchOffering')
      : tab === 'events'
      ? filters.hasOwnProperty('complexSearchEvent')
      : tab === 'knights'
      ? filters.hasOwnProperty('complexSearchKnight')
      : tab === 'useCases'
      ? filters.hasOwnProperty('complexSearchUseCase')
      : tab === 'cloud_svcs'
      ? filters.hasOwnProperty('complexSearchCSP')
      : filters.hasOwnProperty('complexSearch');

  const currentSearchFilterApplied = currentFilters.hasOwnProperty(
    tab === 'marketplace'
      ? 'complexSearchOffering'
      : tab === 'knights'
      ? 'complexSearchKnight'
      : tab === 'useCases'
      ? 'complexSearchUseCase'
      : tab === 'events'
      ? 'complexSearchEvent'
      : tab === 'cloud_svcs'
      ? 'complexSearchCSP'
      : 'complexSearch'
  );

  const handleClearAll = async () => {
    // Clear all filters
    if (tab === 'events') await clearEventsFiltersExceptSearch();
    else if (tab === 'marketplace') await clearOffFiltersExceptSearch();
    else if (tab === 'cloud_svcs') await clearCSPFiltersExceptSearch();
    else if (tab === 'knights') await clearKnightFiltersExceptSearch();
    else if (tab === 'useCases') await clearUseCasesFilters();
    else await clearFiltersExceptSearch();

    clearFilters();
    setDrawerOpen(false);
  };

  const validFilters =
    tab === 'marketplace'
      ? validMarketplaceFinderFilters
      : tab === 'knights'
      ? validKnightsFinderFilters
      : tab === 'events'
      ? validEventsFinderFilters
      : tab === 'useCases'
      ? validUseCasesFinderFilters.filter((e) => e !== 'sort')
      : tab === 'cloud_svcs'
      ? validCSPFinderFilters
      : validPartnerFinderFilters;

  let currentTotalFilters = Object.entries(currentFilters)
    ?.filter((el) => validFilters.includes(el[0]))
    ?.reduce((total, filter) => {
      return total + (Array.isArray(filter[1]) ? filter[1].length : 1);
    }, 0);

  if (
    tab === 'events' &&
    Object.keys(currentFilters)?.includes('end_date_filter')
  ) {
    currentTotalFilters -= 1;
  }

  const contentProps = {
    setPopupOpen,
    handleClearAll,
    tab,
    hasChanges,
    filtersResources,
    setDrawerOpen,
    currentFilters,
    updateFilters,
    applyFilters,
    currentTotalFilters: currentSearchFilterApplied
      ? currentTotalFilters - 1
      : currentTotalFilters
  };

  const triggerProps = {
    totalFilters: visibleTotalFilters,
    isSearchFilterApplied: isSearchFilterApplied
  };

  return (
    <>
      <div className={styles.filters_on_desktop}>
        <Popup
          isOpen={isPopupOpen}
          trigger={
            <PopupTrigger
              onClick={() => {
                setPopupOpen(!isPopupOpen);
                handleFirstVisit();
              }}
              isOpen={isPopupOpen}
              {...triggerProps}
              customRef={filterBtnRef}
            />
          }
        >
          <PopupContent
            {...contentProps}
            contentRef={contentRef}
            id="landing_page_filters_popup"
          />
        </Popup>
      </div>
      <div className={styles.filters_on_mobile}>
        <PopupTrigger
          onClick={() => {
            setDrawerOpen(!drawerOpen);
            handleFirstVisit();
          }}
          isOpen={drawerOpen}
          {...triggerProps}
          mobile={true}
        />
        <Drawer isOpen={drawerOpen}>
          <PopupContent {...contentProps} id="landing_page_filters_popup" />
        </Drawer>
      </div>
    </>
  );
}

const PopupTrigger = ({
  onClick = () => {},
  isOpen,
  customRef = null,
  totalFilters,
  isSearchFilterApplied,
  mobile = false
}) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      className={`${styles.button_filters} ${isOpen ? styles.open : ''}`}
      ref={customRef}
    >
      <div className={styles.label}>
        <FilterIcon />
        {!mobile && t('filters')}
      </div>
      {!(totalFilters === 1 && isSearchFilterApplied) && totalFilters > 0 && (
        <div className={styles.count}>
          {isSearchFilterApplied ? totalFilters - 1 : totalFilters}
        </div>
      )}
    </div>
  );
};
