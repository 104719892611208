'use client';
import React from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { Modal, ModalContent } from 'components/ui/modal';
import Image from 'next/image';
import {
  convertTimezoneBasedOnDate,
  getDayOfTheWeekFromDate,
  getHoursAndMinutesFromISODate,
  getNewDate,
  getTimezonesData
} from 'shared/utils';
import EventsContext from 'context/EventsListingsProvider';
import { useContext } from 'react';
import Link from 'next/link';
import { Button } from 'components/ui/button';
import PartnersContext from 'context/PartnerListingsProvider';

const DEFAULT_IMAGE = '/images/event-broadcom-large.png';
const DEFAULT_PARTNER_IMAGE = '/images/event_banner_def.svg';
const DEFAULT_LOGO = '/images/broadcom-icon.png';
const DEFAULT_WEBCAST = '/images/event-webcast-large.png';
const DEFAULT_CONFERENCE = '/images/event-conference-large.png';
const DEFAULT_USER_GROUP = '/images/event-user-group-large.png';
const DEFAULT_TRAINING = '/images/event-training-large.png';
const DEFAULT_CSP = '/images/csp-event-large.png';

export default function PublicEventModal({
  event,
  defaultOpen = false,
  open = false,
  onOpenChange = () => {},
  onPartnerPage = false
}: {
  event: any;
  defaultOpen?: boolean;
  open?: boolean;
  onOpenChange: (a: boolean) => void;
  onPartnerPage?: boolean;
}) {
  const { t } = useTranslation();
  
  const { allTimezones, usersTimezoneLocation } = useContext(EventsContext);

  const { setLoadingInfo } = useContext(PartnersContext);

  const eventBanner = event?.image
    ? event?.image
    : event?.locatorCompanyId
    ? DEFAULT_CSP
    : event?.partnerId
    ? DEFAULT_PARTNER_IMAGE
    : event?.EventTypes.name === 'WEBCAST_ONLINE_PRESENTATION'
    ? DEFAULT_WEBCAST
    : event?.EventTypes.name.includes('CONFERENCE')
    ? DEFAULT_CONFERENCE
    : event?.EventTypes.name === 'USER_GROUP'
    ? DEFAULT_USER_GROUP
    : event?.EventTypes.name === 'PRODUCT_TRAINING'
    ? DEFAULT_TRAINING
    : DEFAULT_IMAGE;

  const timezonesData = getTimezonesData(allTimezones, usersTimezoneLocation);

  const areDatesEqual = (date1, date2) => {
    return date1.split('T')[0] !== date2.split('T')[0];
  };

  const getDateToDisplay = (date) => {
    return `${getDayOfTheWeekFromDate(
      getNewDate({
        date: date,
        returnDateObject: true
      }),
      t
    )}. ${getNewDate({
      date: date,
      returnDateObject: true,
      formatUSDate: true
    })}`;
  };

  const displayDate = () => {
    return event.allDayEvent ? (
      <span>
        {getDateToDisplay(event.startDate)}{' '}
        {areDatesEqual(event.startDate, event.endDate) &&
          `to ${getDateToDisplay(event.endDate)}`}{' '}
        - All Day
      </span>
    ) : (
      <div className={styles.dates}>
        <div className={styles.date_start}>
          <span>
            {getDateToDisplay(event.startDate)} -{' '}
            {getHoursAndMinutesFromISODate(event.startDate)}
          </span>
        </div>
        <div className={styles.center_line} />
        <div className={styles.date_end}>
          <span>
            {getDateToDisplay(event.endDate)} -{' '}
            {getHoursAndMinutesFromISODate(event.endDate)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Modal defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
      <ModalContent className="box-border md:p-6">
        <h1 className="text-[#2360A0] font-[500] text-[22px] leading-[30px] p-0 w-11/12">
          {event.title}
        </h1>
        <div className="relative w-full h-auto mt-[-30px]">
          <Image
            src={eventBanner}
            alt="event-banner"
            width={0}
            height={0}
            sizes="100vw"
            quality={100}
            className="w-full h-auto rounded-sm object-contain"
          />
          <Image
            className="absolute left-5 bottom-5 rounded-sm bg-white object-contain"
            width={54}
            height={44}
            quality={100}
            src={event?.Partner?.logo || event?.LocatorCompany?.logo || DEFAULT_LOGO}
            alt="event-logo"
          />
        </div>
        <div className={styles.event_info}>
          <span>{event.tagline}</span>
          <div className={styles.line} />
          <p className="text-[#1F384C] text-[16px] leading-[27.77px] font-[500]">
            {convertTimezoneBasedOnDate(
              event.timezone,
              event.startDate,
              timezonesData,
              false
            )}
          </p>
          <div className={styles.line} />
          <span>{event.EventTypes?.label}</span>
          <div className={styles.line} />
          <span className={styles.desc}>{event.description}</span>
          <div className={styles.line} />
          {displayDate()}
          <div className={styles.line} />
          <span>{event.region?.name}</span>
          <div className={styles.line} />
          {event.location && (
            <>
              <span>{event.location}</span>
              <div className={styles.line} />
            </>
          )}
          {event.link && (
            <>
              <a
                href={
                  /^http/i.test(event.link)
                    ? event.link
                    : `https://${event.link}`
                }
                rel="noreferrer"
                target="_blank"
              >
                {t('link')}
              </a>
              <div className={styles.line} />
            </>
          )}
          {event.products?.length > 0 && (
            <span className={styles.products}>{t('products')}:</span>
          )}{' '}
          {event.products?.map((prod, index) => {
            return index === event.products.length - 1 ? (
              <span key={index}>{prod.level3}</span>
            ) : (
              <span key={index}>{prod.level3}, </span>
            );
          })}
        </div>
        {onPartnerPage || event.Partner === null ? null : (
          <Button
            onClick={(e) => {
              e.preventDefault();
              onOpenChange(false);
              setLoadingInfo(true);
            }}
            variant={'default'}
            className="w-full md:w-[45%] text-[18px]"
          >
            {event.Partner !== null && !event.query?.slug && (
              <Link
                href={`${`/partner/${event.Partner?.slug}/events`}`}
                className="text-white decoration-white no-underline mr-4"
              >
                {t('view_all_partner_events')}
              </Link>
            )}

          </Button>
        )}
      </ModalContent>
    </Modal>
  );
}
