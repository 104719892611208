import Carousel from './Carousel';
import CSPFinderResume from '../../Resumes/CSPFinderResume';
import BannerRecentNews from './BannerRecentNews';

export default function CSPFinderPresentation() {
  return (
    <div className="flex flex-col">
      <Carousel />
      <BannerRecentNews />
      <div className="flex flex-col gap-24 w-full">
        <CSPFinderResume />
      </div>
    </div>
  );
}
