import { cn } from 'lib/utils';
import { getNewDate } from 'shared/utils';
import { useEventsContext } from 'hooks/useContexts';
import InputDateRange from 'components/molecules/InputDateRange';

export default function FilterEventsDateRange({
  title,
  currentFilters,
  updateFilters,
  siteAdmin = false
}: {
  siteAdmin?: boolean;
  title: string;
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  function isValidDate(d) {
    return !isNaN(d) && d instanceof Date;
  }
  const { setCurrentPageFiltering, clickOnFilter, usersTimezoneLocation } =
    useEventsContext();

  function formatDate(d) {
    return d
      ? getNewDate({
          date: d,
          getISODateFromUsersTimezone: true,
          usersTimezoneLocation,
          removeTimezone: true
        })
      : undefined;
  }

  const startKey = 'start_date_filter';
  const endKey = 'end_date_filter';

  const startDateFilter = siteAdmin
    ? isValidDate(currentFilters[startKey])
      ? currentFilters[startKey]
      : undefined
    : currentFilters[startKey];

  const endDateFilter = siteAdmin
    ? isValidDate(currentFilters[endKey])
      ? currentFilters[endKey]
      : undefined
    : currentFilters[endKey];

  return (
    <fieldset
      className={cn(
        'mt-4 p-0 border-0 text-[#2360a0] w-full flex flex-col gap-2',
        siteAdmin ? '' : 'mb-6'
      )}
    >
      <InputDateRange
        id="eventFilterDate"
        value={{
          from: formatDate(startDateFilter),
          to: formatDate(endDateFilter)
        }}
        labelClassname={cn(
          'm-0 p-0 text-base font-extralight',
          `${siteAdmin ? 'text-[#005c8a] uppercase font-normal text-sm' : ''}`
        )}
        size="input"
        label={title}
        onChange={(e) => {
          // for this special case we send the key "events_date_range_filter" to be treated on the updateFilters function of eventsContext
          //the value that we send is on the format (ex.:{ from: Date, to: Date}) and will be treated accordingly
          setCurrentPageFiltering(0);
          updateFilters('events_date_range_filter', e);
          clickOnFilter('date_event_filter');
        }}
        allowClear
      />
    </fieldset>
  );
}
