import { useContext } from 'react';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/ui/checkbox';

export default function PublicSector({
  currentFilters,
  updateFilters
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { t } = useTranslation();

  const { clickOnFilter } = useContext(PartnersContext);
  return (
    <fieldset className="flex flex-col gap-2 mb-4">
      <div className="m-0 p-0 font-extralight text-base text-[#2360a0]">
        {t('pub_sector')}
      </div>
      <div className="flex flex-col gap-1">
        <Checkbox
          id="sector"
          label={t('show_pub_part')}
          alignItems="start"
          onCheckedChange={(e) => {
            if (currentFilters['isClearedResources']?.length > 0) {
              delete currentFilters['isClearedResources'];
            }
            const checked = e ? 'on' : undefined;
            updateFilters('isPublic', checked);
            clickOnFilter('Public Sector');
          }}
          checked={
            currentFilters?.isPublic === 'on' ||
            currentFilters?.isPublic?.includes('on') ||
            false
          }
        />
        {currentFilters?.isPublic === 'on' && (
          <div className="ml-10">
            <Checkbox
              label={t('show_pub_res')}
              id="resources"
              alignItems="start"
              onCheckedChange={(e) => {
                const checked = e ? 'on' : undefined;
                updateFilters('isClearedResources', checked);
                clickOnFilter('Cleared Resources');
              }}
              checked={currentFilters?.isClearedResources === 'on' || false}
            />
          </div>
        )}
      </div>
    </fieldset>
  );
}
