import styles from './style.module.scss';
import KnightCard from '../../molecules/Cards/KnightCard';
import { Skeleton } from 'components/ui/skeleton';

export default function KnightsPublicGrid({
  knights,
  isLoading = false,
  loadingCount = 6
}) {
  return (
    <ul className={styles.knights_list}>
      {isLoading
        ? Array.apply(null, Array(loadingCount)).map((e, i) => (
            <Skeleton
              key={`loading_knight_card${i}`}
              className="h-[400px] w-full rounded-md"
            />
          ))
        : knights?.map((knight) => (
            <KnightCard key={`knight_card_${knight.id}`} knight={knight} />
          ))}
    </ul>
  );
}
