import { InputHTMLAttributes, forwardRef } from 'react';
import { cn } from 'lib/utils';
import { Search, X } from 'lucide-react';
import { Label } from './label';
import Spinner from './spinner';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// - - -THIS COMPONENT IS ONLY HERE TO BE USED AS THE SEARCH BAR IN THE FINDER TOOL - - - - -
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  isSearch?: boolean;
  label?: string | null;
  description?: string | null;
  onClear?: () => void;
  error?: any;
  removeStyles?: boolean;
  loading?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      isSearch = false,
      id = '',
      className,
      label,
      description,
      type = 'text',
      placeholder = isSearch ? 'Pesquisar...' : '',
      disabled = false,
      value,
      onClear,
      error,
      removeStyles = false,
      loading = false,
      ...props
    },
    ref
  ) => {
    const showClear = !!onClear && value && !loading;

    const unstyled =
      'rounded-none outline-none shadow-none border-none hover:outline-none hover:shadow-none hover:border-none focus:outline-none focus:shadow-none focus:border-none focus-visible:outline-none focus-visible:shadow-none focus-visible:border-none active:outline-none active:shadow-none active:border-none';

    return (
      <div className="w-full h-full">
        {label && <Label htmlFor={id}>{label}</Label>}
        <div className="relative w-full h-full">
          <input
            id={id}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            className={cn(
              'flex h-full w-full px-3 py-2',
              unstyled,
              'pl-12 md:pl-16',
              'pr-[5.5rem] md:pr-[22rem]',
              className
            )}
            ref={ref}
            value={value}
            {...props}
          />
          <div className="flex absolute inset-y-0 left-0 items-center pl-4 md:pl-8 pointer-events-none">
            <Search className="w-5 h-5 text-[#2360A0]" strokeWidth={2} />
          </div>
          {showClear && (
            <div className="flex absolute inset-y-0 right-0 items-center pr-[3.5rem] md:pr-[19.5rem]">
              <X
                className="text-[#2360A0] w-5 h-5 cursor-pointer"
                onClick={() => onClear()}
                strokeWidth={2.5}
              />
            </div>
          )}
          {loading && (
            <div className="flex absolute inset-y-0 right-0 items-center pr-[3.5rem] md:pr-[19.5rem]">
              <Spinner className="w-6 h-6 text-[#2360A0]" strokeWidth={2} />
            </div>
          )}
        </div>
        {description && (
          <p className="text-sm text-muted-foreground">{description}</p>
        )}
        {error && (
          <div className="text-sm font-medium text-destructive">{error}</div>
        )}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
