import { useTranslation } from 'react-i18next';
import ImageKnightsArchitectural from '/public/images/image_knights_architectural.svg';
import ImageKnightsImplementation from '/public/images/image_knights_implementation.svg';
import ImageKnightsLeadership from '/public/images/image_knights_leadership.svg';

export default function KnightsCarousel() {
  const { t } = useTranslation();

  const styles = {
    wrapper: `flex flex-col gap-5 text-center items-center`,
    icon: 'w-[48px] h-[48px]',
    title: 'text-[#1F384C] text-xl font-normal',
    text: 'text-[#53565A] font-light text-base break-keep px-12 leading-7'
  };

  return (
    <div className="flex flex-col gap-10 mb-9">
      <div className="flex flex-col gap-10 text-center">
        <div className="flex flex-col gap-4 items-center">
          <div className="text-[#1F384C] text-[1.75rem] font-normal">
            {t('insights_knights_info_title')}
          </div>
          <div className="text-[#53565A] text-base font-light">
            {t('insights_knights_info_desc')}
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
          <div className={styles.wrapper}>
            <div className={styles.icon}>
              <ImageKnightsArchitectural />
            </div>
            <span className={styles.title}>{t('architectural_title')}</span>
            <span className={styles.text}>{t('architectural_desc')}</span>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.icon}>
              <ImageKnightsImplementation />
            </div>
            <span className={styles.title}>{t('implementation_title')}</span>
            <span className={styles.text}>{t('implementation_desc')}</span>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.icon}>
              <ImageKnightsLeadership />
            </div>
            <span className={styles.title}>{t('leadership_title')}</span>
            <span className={styles.text}>{t('leadership_desc')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
