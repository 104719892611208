import { Button } from 'components/ui/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import CloudServicesModal from 'components/molecules/Modal/CloudServicesModal';

export function ExpertiseCard({ title, about, type }) {
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const titleModal =
    type === 'hosted'
      ? t('hosted_cloud_services_title')
      : type === 'managed'
      ? t('managed_cloud_services_title')
      : type === 'sovereign'
      ? t('sovereign_cloud_services_title')
      : t('public_cloud_services_title');

  const titleDesc =
    type === 'hosted'
      ? t('hosted_cloud_services_desc')
      : type === 'managed'
      ? t('managed_cloud_services_desc')
      : type === 'sovereign'
      ? t('sovereign_cloud_services_desc')
      : t('public_cloud_services_desc');

  const text =
    type === 'hosted'
      ? t('hosted_cloud_services_text')
      : type === 'managed'
      ? t('managed_cloud_services_text')
      : type === 'sovereign'
      ? t('sovereign_cloud_services_text')
      : t('public_cloud_services_text');

  return (
    <div
      className="relative box-border flex flex-col gap-7 items-start w-full h-full p-6 rounded-[10px] bg-white"
      style={{ boxShadow: '0px 3.474px 13.895px 0px rgba(51, 51, 51, 0.08)' }}
    >
      <div className="w-full flex flex-col items-start gap-4 text-start">
        <div className="w-full">
          <Image
            src={
              type === 'hosted'
                ? '/images/hosted-private-cloud.svg'
                : type === 'managed'
                ? '/images/managed-private-cloud.svg'
                : type === 'sovereign'
                ? '/images/sovereign-cloud.svg'
                : '/images/public-cloud.svg'
            }
            layout="responsive"
            width={100}
            height={100}
            quality={100}
            alt="Hosted Private Cloud"
          />
        </div>
        <h4 className="m-0 p-0 text-[#171725] font-normal text-lg break-[break-word] overflow-x-hidden max-w-full">
          {title}
        </h4>
        <span className="text-[#53565A] text-sm font-light leading-6 line-clamp-3 max-w-full">
          {about || ''}
        </span>
      </div>
      <div className="w-full flex flex-row justify-between">
        <Button
          className="sm:w-[50%] lg:w-[35%] py-3 h-10 px-6 text-sm font-normal rounded-md self-center"
          onClick={() => setIsOpenModal(true)}
        >
          {t('read_more')}
        </Button>
        <Image
          src={
            type === 'hosted'
              ? '/images/hosted-private-icon.svg'
              : type === 'managed'
              ? '/images/managed-private-icon.svg'
              : type === 'sovereign'
              ? '/images/sovereign-cloud-icon.svg'
              : '/images/public-cloud-icon.svg'
          }
          width={type === 'sovereign' ? 60 : 83}
          height={type === 'sovereign' ? 83 : 83}
          quality={100}
          alt="cloud icon"
        />
      </div>
      <CloudServicesModal
        title={titleModal}
        titleDesc={titleDesc}
        text={text}
        type={type}
        isOpen={isOpenModal}
        setOpen={setIsOpenModal}
      />
    </div>
  );
}
