import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from 'services/axios';
import KnightsFeatured from './KnightsFeatured';
import KnightsBlogPosts from './KnightsBlogPosts';

export default function FeaturedSection() {
  const apiClient = getAPIClient();
  const { t } = useTranslation();

  const { data: featuredKnightData, isLoading: isLoadingFeatured } = useQuery({
    queryKey: ['mainPageKnights', 'featuredKnight'],
    queryFn: async () => apiClient.get(`/knights/featured`),
    refetchOnWindowFocus: false
  });

  const { data: blogPostsData, isLoading: isLoadingPosts } = useQuery({
    queryKey: ['mainPageKnights', 'blogPosts'],
    queryFn: async () => apiClient.get(`/blogPosts`),
    refetchOnWindowFocus: false
  });
  const featuredKnight = featuredKnightData?.data || null;
  const blogPosts = blogPostsData?.data || [];

  return featuredKnight || blogPosts.length > 0 ? (
    <div className="flex flex-col mx-0">
      <h3 className="p-0 m-0">{t('featured_this_month')}</h3>
      <div className="grid grid-cols-1 md:grid-cols-[1fr_auto_1fr] gap-8">
        <div className="w-full">
          <KnightsFeatured
            knightFeatured={featuredKnight}
            isLoading={isLoadingFeatured}
          />
        </div>
        <div className="hidden md:block bg-[#b5bfc9] w-[1px] h-auto mt-[100px] mx-[1.5%] rounded-sm" />
        <div className="w-full">
          <KnightsBlogPosts blogPosts={blogPosts} isLoading={isLoadingPosts} />
        </div>
      </div>
    </div>
  ) : null;
}
