import PartnersList from 'components/organisms/PartnersList';
import { useTranslation } from 'react-i18next';
import Pagination from '../Pagination';
import PublicOfferingsGrid from 'componentsV2/organisms/PublicOfferingsGrid';
import { usePartnerContext } from 'hooks/useContexts';
import { PartnerFinderControllerResult } from 'controllers/finderTools/PartnerFinder';
import FinderNoResult from './FinderNoResults';
import { Skeleton } from 'components/ui/skeleton';
import Loader from 'components/atoms/Loader';

type ComponentProps = {
  standalone: boolean;
  partnerFinder?: PartnerFinderControllerResult;
};

const defaultPartnerFinder = {
  currentPartners: [],
  totalPartners: 0,
  PARTNERS_PER_PAGE: 0,
  totalRelatedOfferings: 0,
  relatedOfferings: [],
  RELATED_OFFERINGS_PER_PAGE: 0,
  isLoading: false,
  isLoadingRelated: false
};

export default function PartnerFinderResults({
  partnerFinder = defaultPartnerFinder,
  standalone
}: ComponentProps) {
  const { t } = useTranslation();

  const {
    currentPartners = [],
    totalPartners = 0,
    PARTNERS_PER_PAGE = 12,
    totalRelatedOfferings = 0,
    relatedOfferings = [],
    RELATED_OFFERINGS_PER_PAGE = 0,
    isLoading = false,
    isLoadingRelated = false
  } = partnerFinder;

  const {
    currentPageFiltering,
    setCurrentPageFiltering,
    relatedPageFiltering,
    setRelatedPageFiltering
  } = usePartnerContext();

  return (
    <>
      {isLoading ||
      isLoadingRelated ||
      (!isLoading && !isLoadingRelated && currentPartners?.length > 0) ? (
        <div className="flex flex-col gap-32 mb-20">
          <section className="flex flex-col gap-10">
            <h3
              className="text-[#171725] p-0 m-0 font-normal inline-flex items-center gap-2"
              id="partnerFinder-partnerResults"
            >
              {t('results')}{' '}
              {isLoading ? (
                <Skeleton className="w-36 h-8" />
              ) : (
                <span className="font-extralight text-lg">
                  ({t('showing')} {totalPartners})
                </span>
              )}
            </h3>
            <PartnersList
              partners={currentPartners}
              isLoading={isLoading}
              loadingCount={12}
            />
            {isLoading ? null : (
              <div className="flex justify-between text-[#b5b7c0]">
                <Pagination
                  perPage={PARTNERS_PER_PAGE}
                  total={totalPartners}
                  currentPage={currentPageFiltering}
                  onChange={(currentPageFiltering) => {
                    setCurrentPageFiltering(currentPageFiltering);
                  }}
                  showDataInfo
                  removePadding
                  scrollId="partnerFinder-partnerResults"
                />
              </div>
            )}
          </section>
          {(!standalone && !isLoadingRelated && relatedOfferings.length > 0) ||
          isLoadingRelated ? (
            <section className="flex flex-col gap-10">
              <h3
                className="text-[#171725] p-0 m-0 font-normal inline-flex gap-2 items-center"
                id="partnerFinder-relatedOfferings"
              >
                {t('rel_offering')}{' '}
                {isLoadingRelated ? (
                  <Skeleton className="w-36 h-8" />
                ) : (
                  <span className="font-extralight text-lg">
                    ({t('showing')} {totalRelatedOfferings})
                  </span>
                )}
              </h3>
              <PublicOfferingsGrid
                offerings={relatedOfferings}
                isLoading={isLoadingRelated}
                loadingCount={3}
              />
              {isLoadingRelated ? null : (
                <div className="flex justify-between text-[#b5b7c0]">
                  <Pagination
                    perPage={RELATED_OFFERINGS_PER_PAGE}
                    total={totalRelatedOfferings}
                    currentPage={relatedPageFiltering}
                    onChange={(relatedPageFiltering) => {
                      setRelatedPageFiltering(relatedPageFiltering);
                    }}
                    showDataInfo
                    removePadding
                    scrollId="partnerFinder-relatedOfferings"
                  />
                </div>
              )}
            </section>
          ) : null}
        </div>
      ) : null}
      {!isLoading && !isLoadingRelated && currentPartners.length === 0 ? (
        <FinderNoResult />
      ) : null}
    </>
  );
}
