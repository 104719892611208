import ImproviseIcon from 'public/images/banner_iao_icon.svg';
import IntegrationsIcon from 'public/images/banner_integrations_icon.svg';
import FocusIcon from 'public/images/banner_focus_icon.svg';
import DontGoAloneIcon from 'public/images/icon-park-outline_circular-connection.svg';
import { useTranslation } from 'react-i18next';
import { Slider } from 'components/molecules/Slider';
import { useEffect, useState } from 'react';
import DotNotSelected from 'public/images/dot_slider_not_selected.svg';
import DotSelected from 'public/images/dot_slider_selected.svg';
import useEmblaCarousel from 'embla-carousel-react';

export default function Carousel() {
  const { t } = useTranslation();

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth || 0,
    height: window.innerHeight || 0
  });

  const isMobile = screenSize.width <= 768;

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    slidesToScroll: 'auto',
    containScroll: 'keepSnaps'
  });

  const [dotsToShow, setDotsToShow] = useState<any>([]);

  useEffect(() => {
    setDotsToShow(
      Array.from({
        length:
          screenSize.width >= 650 && screenSize.width <= 1024
            ? 3
            : isMobile
            ? 4
            : 2
      })?.map((e, i) =>
        i === 0 ? (
          <DotSelected key={`dot_selected_${i}`} />
        ) : (
          <div
            className="cursor-pointer"
            onClick={() => {
              emblaApi?.scrollTo(i);
            }}
            key={`dot_not_selected_${i}`}
          >
            <DotNotSelected />
          </div>
        )
      )
    );
  }, [emblaApi]);

  useEffect(() => {
    const updateDimension = () => {
      const localScreenSize = {
        width: screenSize.width ? screenSize.width : window.innerWidth,
        height: screenSize.height ? screenSize.height : window.innerHeight
      };
      const isMobile = localScreenSize.width <= 768;
      setScreenSize(localScreenSize);
      setDotsToShow(
        Array.from({
          length:
            localScreenSize.width >= 650 && localScreenSize.width <= 1024
              ? 3
              : isMobile
              ? 4
              : 2
        })?.map((e, i) => <DotNotSelected key={`not_selected_${i}`} />)
      );
    };
    updateDimension();
    window.addEventListener('resize', updateDimension);
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);

  const flexCard =
    screenSize.width >= 650 && screenSize.width <= 1024
      ? `flex-[0_0_50%]`
      : isMobile
      ? `flex-[0_0_100%]`
      : `flex-[0_0_33.33%]`;

  const centerDots =
    screenSize.width >= 650 && screenSize.width <= 1024
      ? `pl-[45%]`
      : isMobile
      ? `pl-[38%]`
      : `pl-[50%]`;

  const styles = {
    wrapper: `flex flex-col gap-5 text-center items-center ${flexCard} ${
      isMobile ? 'mt-20' : 'mt-5'
    } mb-10 w-10`,
    title: 'text-[#1F384C] text-xl font-normal',
    icon: 'w-[48px] h-[48px]',
    text: 'text-[#53565A] font-light text-base break-keep leading-7 px-12'
  };

  return (
    <div className="w-full flex flex-col gap-12">
      <div className="flex flex-col gap-4 items-center text-center">
        <span className="text-[#1F384C] font-normal text-2xl md:text-[1.75rem]">
          {t('main_page_top_banner_title')}
        </span>
        <span className="text-[#53565A] font-light text-base md:text-base break-keep leading-7">
          {t('main_page_top_banner_desc')}
        </span>
      </div>
      <div>
        <div>
          <Slider
            numOfElements={4}
            title={''}
            key={''}
            slideShow
            dotsToShow={dotsToShow}
            setDotsToShow={setDotsToShow}
            emblaRef={emblaRef}
            emblaApi={emblaApi}
            slidesToShow={1}
            customStylingTitle={{ padding: 0, margin: 0 }}
          >
            <div className={styles.wrapper}>
              <div className={styles.icon}>
                <ImproviseIcon />
              </div>
              <span className={styles.title}>{t('improvise_title')}</span>
              <span className={styles.text}>{t('improvise_desc')}</span>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.icon}>
                <IntegrationsIcon />
              </div>
              <span className={styles.title}>
                {t('insights_integrations_title')}
              </span>
              <span className={styles.text}>
                {t('insights_integrations_desc')}
              </span>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.icon}>
                <FocusIcon />
              </div>
              <span className={styles.title}>{t('focus_title')}</span>
              <span className={styles.text}>{t('focus_desc')}</span>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.icon}>
                <DontGoAloneIcon />
              </div>
              <span className={styles.title}>{t('dont_go_alone_title')}</span>
              <span className={styles.text}>{t('dont_go_alone_desc')}</span>
            </div>
          </Slider>
        </div>
        <div className={`flex flex-row items-center gap-4 ${centerDots}`}>
          {dotsToShow}
        </div>
      </div>
    </div>
  );
}
