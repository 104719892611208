export const shuffle = (array) => {
  array.sort(() => Math.random() - 0.5);
};

export const paginateResults = (arr, pageFiltering, numberPerPage) => {
  return arr.filter(
    (_, idx) =>
      idx >= parseInt(pageFiltering) * parseInt(numberPerPage) &&
      idx < (parseInt(pageFiltering) + 1) * parseInt(numberPerPage)
  );
};

export const defaultQueryData = { data: [], totalCount: 0 };

export type TQueryData = {
  data: any[];
  totalCount: number;
};
