/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as ga from 'lib/ga';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import SendEmailForm from 'componentsV2/molecules/Modals/SendEmailForm';
import { Button } from 'components/ui/button';
import Link from 'next/link';
import { Linkedin, Mail } from 'lucide-react';
import CiberSecurityIcon from 'public/images/shield_icon.svg';
import EnterpriseIcon from 'public/images/enterprice_icon.svg';

const bioLimit = 200;

export default function KnightCard({ knight }) {
  const { t } = useTranslation();
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  useEffect(() => {
    ga.event({
      action: 'search',
      params: {
        search_term: `Knight tile presented (${knight.name})`
      }
    });
  }, []);

  const locs = knight?.locations?.map((item) => item['name'])?.join(', ');

  const prds = knight?.products?.map((item) => item['level3'])?.join(', ');

  const otherProds = knight?.otherProducts
    ?.map((item) => item['level3'])
    ?.join(', ');

  return (
    <>
      <li
        className="relative box-border w-full h-full flex flex-col justify-between items-start gap-5 rounded-[10px] p-6"
        style={{ boxShadow: '0px 3.474px 13.895px 0px rgba(51, 51, 51, 0.08)' }}
      >
        {!knight.awarded && (
          <div className="absolute top-0 right-6 bg-[#2360A0] text-xs font-light text-center text-white p-2 py-1 rounded-b-sm">
            {t('knight_award')} {knight.year}
          </div>
        )}

        <div className="flex flex-col items-start gap-5 pt-2">
          <div className="w-full">
            <div className="max-w-[40%] max-h-[120px] float-left mr-5">
              <img
                className="max-w-full max-h-[120px] overflow-hidden rounded-lg object-contain"
                src={knight.image}
              />
            </div>
            <div className="flex flex-col gap-4">
              <span className="text-[#171725] text-lg font-normal max-w-full">
                {knight.name}
              </span>
              {knight.bio.length > bioLimit - 1 ? (
                <HybridTooltip
                  asChild
                  text={knight.bio}
                  contentClassName="font-light"
                >
                  <div className="text-sm font-light text-[#53565A] leading-6 max-w-full">
                    {knight.bio.substring(0, bioLimit).length === bioLimit
                      ? knight.bio.substring(0, bioLimit).concat('...')
                      : knight.bio}
                  </div>
                </HybridTooltip>
              ) : (
                <div className="text-sm font-light text-[#53565A] leading-6 cursor-[unset] max-w-full">
                  {knight.bio}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 items-start max-w-full">
            {(knight?.Partner || knight?.customPartnerName) && (
              <div className="text-sm text-black max-w-full">
                <span className="font-normal">{t('knights_of')}: </span>
                <span className="font-light">
                  {knight?.Partner?.name || knight?.customPartnerName || ''}
                </span>
              </div>
            )}
            {knight.year && (
              <div className="text-sm text-black max-w-full">
                <span className="font-normal">{t('year_knighted_in')}: </span>
                <span className="font-light">{knight.year}</span>
              </div>
            )}
            {prds && (
              <div className="text-sm text-black max-w-full">
                <span className="font-normal">
                  {t('products_knighted_in')}:{' '}
                </span>
                <span className="font-light">{prds}</span>
              </div>
            )}
            {otherProds && (
              <div className="text-sm text-black max-w-full">
                <span className="font-normal">{t('other_products')}: </span>
                <span className="font-light">{otherProds}</span>
              </div>
            )}
            {locs && (
              <div className="text-sm text-black max-w-full">
                <span className="font-normal">{t('geo_graph_loc')}: </span>
                <span className="font-light">{locs}</span>
              </div>
            )}
          </div>
        </div>

        <div className="w-full grid grid-cols-[1fr_auto] gap-4">
          <div className="flex flex-col items-start gap-2">
            {knight.email && (
              <div className="w-full grid grid-cols-[24px_auto] gap-3 items-center place-content-start">
                <Mail className="w-6 h-6" />
                <span
                  className="cursor-pointer text-left p-0 h-auto text-sm font-light text-[#2360A0] underline underline-offset-4 decoration-[#2360A0] line-clamp-1 text-ellipsis"
                  onClick={() => setEmailModalOpen(true)}
                >
                  {knight.email}
                </span>
              </div>
            )}
            {knight.consent && knight.linkedIn && (
              <div className="w-full grid grid-cols-[24px_auto] gap-3 items-center place-content-start">
                <Linkedin className="w-4 h-4 fill-white stroke-white stroke-1 bg-[#2360A0] p-1 rounded-sm" />
                <Link
                  href={
                    /^http/i.test(knight.linkedIn)
                      ? knight.linkedIn
                      : `https://${knight.linkedIn}`
                  }
                  rel="noreferrer"
                  target="_blank"
                  className="text-sm text-[#2360A0] underline underline-offset-4 decoration-[#2360A0] line-clamp-1 text-ellipsis"
                >
                  {knight.linkedIn}
                </Link>
              </div>
            )}
          </div>
          <div className="flex items-center">
            {knight.types?.length > 0 && (
              <div className="flex items-center gap-2">
                {knight.types?.some((t) => t.value === 'ENTERPRISE') ? (
                  <HybridTooltip text={t('enterprise_tooltip')} asChild>
                    <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#F1BA1B]">
                      <EnterpriseIcon />
                    </div>
                  </HybridTooltip>
                ) : null}
                {knight.types?.some((t) => t.value === 'CIBERSECURITY') ? (
                  <HybridTooltip text={t('cyber_security_tooltip')} asChild>
                    <div className="w-10 h-10 rounded-full flex items-center justify-center bg-[#0098C7]">
                      <CiberSecurityIcon />
                    </div>
                  </HybridTooltip>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </li>
      {emailModalOpen && (
        <SendEmailForm
          open={emailModalOpen}
          setOpen={setEmailModalOpen}
          partnerEmail={knight.email}
          partnerName={knight.name}
        />
      )}
    </>
  );
}
