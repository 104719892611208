import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './style.module.scss';
import UseCasesContext from 'context/UseCasesListingsProvider';
import SelectPV from 'components/atoms/SelectPV';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '10px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});
const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});
const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function UseCaseReaderType({
  currentFilters,
  updateFilters,
  readerTypeVals
}) {
  const { t } = useTranslation();

  const readerType_data = readerTypeVals
    ?.map((l) => {
      return { ...l, label: l?.name, value: l?.id };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const { clickOnFilter } = useContext(UseCasesContext);
  const [readerType, setReaderType] = useState<any[]>([]);

  useEffect(() => {
    if (currentFilters?.readerType?.length !== 0) {
      setReaderType(
        readerType_data?.find(
          (e) => e?.value === parseInt(currentFilters?.readerType)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (
      currentFilters?.readerType?.length === 0 ||
      currentFilters?.readerType === undefined
    )
      setReaderType([]);
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="readerType" className="text-base font-light">
        {t('my_role')}
      </label>
      <div className={styles.select}>
        <SelectPV
          id="readerType"
          placeholder={t('my_role')}
          data={readerType_data}
          value={readerType}
          onChange={(s) => {
            setReaderType(s);
            updateFilters('readerType', s?.id);
            clickOnFilter('readerType');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
          isMultiple={false}
        />
      </div>
    </div>
  );
}
