'use client';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import { cn } from 'lib/utils';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

interface TriggerTypes
  extends ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
  disableTitleActiveStyling?: boolean;
  disableBottomBorderOnOpen?: boolean;
}

interface ItemTypes
  extends ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> {
  disableBottomBorderOnOpen?: boolean;
}

const Accordion = AccordionPrimitive.Root;

const AccordionItem = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ItemTypes
>(({ className, disableBottomBorderOnOpen = false, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn(
      disableBottomBorderOnOpen
        ? 'border-0 border-b border-b-[#eeeeee] border-solid m-0 p-0 [&[data-state=open]]:border-0'
        : 'border-0 border-b border-b-[#eeeeee] border-solid m-0 p-0',
      className
    )}
    {...props}
  />
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  TriggerTypes
>(
  (
    { className, children, disableTitleActiveStyling = false, ...props },
    ref
  ) => (
    <AccordionPrimitive.Header className="flex m-0 p-0">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'outline-none border-none bg-transparent shadow-none rounded-none m-0 p-2 pl-0 flex w-full items-center gap-0 justify-between',
          'cursor-pointer font-light text-[#53565A] text-sm text-left transition-all [&>svg]:stroke-[#2360A0] [&[data-state=open]>svg]:rotate-180',
          disableTitleActiveStyling
            ? ''
            : '[&[data-state=open]]:font-normal [&[data-state=open]]:text-[#2360A0]',
          className
        )}
        {...props}
      >
        {children}
        <ChevronDown className="h-5 w-5 shrink-0 transition-transform duration-200" />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & {
    disableAnimations?: boolean;
  }
>(({ className, children, disableAnimations = false, ...props }, ref) => {
  return (
    <AccordionPrimitive.Content
      ref={ref}
      className={cn(
        disableAnimations
          ? 'overflow-visible'
          : 'overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
        className
      )}
      {...props}
    >
      {children}
    </AccordionPrimitive.Content>
  );
});
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
