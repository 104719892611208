import { useContext, useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});

const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});

const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function TypeOfUseCases({
  currentFilters,
  updateFilters,
  useCaseType = []
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
  useCaseType: any[];
}) {
  const { clickOnFilter } = useContext(PartnersContext);
  const [selectedTypes, setSelectedTypes] = useState<any[]>([]);
  const { t } = useTranslation();

  const type_data = useCaseType
    .map((l) => {
      return { id: l.id, label: l?.name, value: l?.name };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!!currentFilters?.useCaseType?.length) {
      const selectedTypes = currentFilters.useCaseType.map((l) =>
        type_data?.find((e) => e.value === l)
      );
      setSelectedTypes([...selectedTypes]);
    }
  }, []);

  useEffect(() => {
    if (!currentFilters?.useCaseType?.length) {
      setSelectedTypes([]);
    }
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="type" className="text-base font-light">
        {t('broadcom_solution_set')}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="type"
          placeholder={t('broadcom_solution_set')}
          data={type_data}
          value={selectedTypes}
          onChange={(s) => {
            setSelectedTypes([...s]);
            updateFilters(
              'useCaseType',
              s?.map((e) => e?.value)
            );
            clickOnFilter('useCaseType');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
