import { useContext, useEffect, useState } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';
import PartnersContext from 'context/PartnerListingsProvider';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});

const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});

const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function ServicesProvided({
  currentFilters,
  updateFilters,
  servicesProvided = []
}: {
  servicesProvided: any[];
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { clickOnFilter } = useContext(PartnersContext);
  const [selectedServicesProvided, setSelectedServicesProvided] = useState<
    any[]
  >([]);
  const { t } = useTranslation();

  const servicesProvided_data = servicesProvided
    .map((l) => {
      return { ...l, label: l?.name, value: l?.id };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!!currentFilters?.servicesProvided?.length) {
      const selectedServicesProvided =
        currentFilters?.servicesProvided?.map((l) =>
          servicesProvided_data?.find((e) => Number(e.id) === Number(l))
        ) || [];
      setSelectedServicesProvided([...selectedServicesProvided]);
    }
  }, []);

  useEffect(() => {
    if (!currentFilters?.servicesProvided?.length) {
      setSelectedServicesProvided([]);
    }
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="servicesProvided" className="text-base font-light">
        {t('cloud_model')}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="servicesProvided"
          placeholder={t('cloud_model')}
          data={servicesProvided_data}
          value={selectedServicesProvided}
          onChange={(s) => {
            setSelectedServicesProvided([...s]);
            updateFilters(
              'servicesProvided',
              s?.map((e: any) => e?.id)
            );
            clickOnFilter('servicesProvided');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
