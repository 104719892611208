import ModerationButtons from '../ModerationButtons';
import SelectLimitedMultiLog from '../SelectLimitedMultiLog';
import styles from './style.module.scss';

export default function SelectLimitedPV({
  id,
  value,
  label = '',
  instanceId,
  prevValue,
  data,
  onChange = (e) => {},
  onChangeApprove = (a, b) => {},
  wasChanged = false,
  placeholder,
  required = false,
  hasNote = false,
  disabled = false,
  approve = false,
  approved = false,
  changed = false,
  dismiss = false,
  fullWidth = false,
  fullHeight = false,
  maxTags,
  customStyles: customSelectStyles = {}
}: {
  id: string;
  value: any;
  label?: string;
  instanceId?: any;
  prevValue?: any;
  data?: any[];
  onChange: (e) => void;
  onChangeApprove?: (id, type) => void;
  wasChanged?: boolean;
  placeholder?: string;
  required?: boolean;
  hasNote?: any;
  disabled?: boolean;
  approve?: boolean;
  approved?: boolean;
  changed?: boolean;
  dismiss?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxTags?: any;
  customStyles?: any;
}) {
  const indicatorsContainer = (provided) => ({
    ...provided,
    alignItems: 'flex-start'
  });
  const indicatorSeparator = (provided) => ({
    display: 'none'
  });
  const dropdownIndicator = (provided) => ({
    ...provided,
    svg: { fill: '#53565A' },
    paddingTop: '16px'
  });
  const clearIndicator = (provided) => ({
    ...provided,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  });
  const container = (provided) => {
    let container = {
      ...provided,
      minHeight: '54px'
    };
    if (fullHeight) container = { ...container, height: '100%' };
    return container;
  };

  const customStyles = {
    container: container,
    indicatorsContainer: indicatorsContainer,
    indicatorSeparator: indicatorSeparator,
    dropdownIndicator: dropdownIndicator,
    clearIndicator: clearIndicator,
    multiValueRemove: clearIndicator,
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e5ecf4',
      color: '#1f384c',
      padding: '4px',
      fontSize: '18px',
      fontWeight: '300'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#1f384c',
      fontWeight: 300,
      fontSize: '16px'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontStyle: 'normal',
      fontWeight: '200',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#A7A8AA'
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      padding: 5,
      fontSize: '5px !important'
    }),
    ...customSelectStyles
  };
  const customStylesReqReview = {
    container: container,
    indicatorsContainer: indicatorsContainer,
    indicatorSeparator: indicatorSeparator,
    dropdownIndicator: dropdownIndicator,
    clearIndicator: clearIndicator,
    multiValueRemove: clearIndicator,
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#f2c94c',
      color: 'white',
      padding: '4px',
      fontSize: '18px',
      fontWeight: '300'
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '1f384c'
    }),
    ...customSelectStyles
  };

  return (
    <div
      className={`custom_select_input ${styles.container} ${
        fullWidth ? styles.full_width : ''
      } ${fullHeight ? styles.full_height : ''}`}
    >
      <div className={styles.labels_and_moderation}>
        <label htmlFor={id} className={styles.label}>
          {`${label}`}
          {required && (
            <span
              className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}
            >
              *
            </span>
          )}
          {hasNote && <span className={styles.hasNote}>{hasNote}</span>}
        </label>
        {approve && wasChanged && (
          <ModerationButtons
            id={id}
            onChangeApprove={onChangeApprove}
            dismissed={dismiss}
            approved={approved}
          />
        )}
      </div>
      <SelectLimitedMultiLog
        id={id}
        disabled={disabled}
        instanceId={instanceId}
        customStyles={wasChanged ? customStylesReqReview : customStyles}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        prevValue={prevValue}
        data={data}
        onChange={onChange}
        value={value}
        wasChanged={wasChanged}
        approved={approved}
        changed={changed}
        dismiss={dismiss}
        fullHeight={fullHeight}
        maxTags={maxTags}
      />
    </div>
  );
}
