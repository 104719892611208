import Loader from 'components/atoms/Loader';
import Carousel from './Carousel';
import UseCaseCard from 'components/molecules/Cards/UseCaseCard';
import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from 'services/axios';
import { usePartnerContext } from 'hooks/useContexts';
import { useState } from 'react';
import { PartnerUseCaseType } from 'shared/partnerUseCases';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'components/ui/skeleton';

interface UseCase {
  id: number;
  title: string;
  banner: string;
  score: number;
  tagline: string;
  vertical: {
    name: string;
  };
  services: {
    id: number;
    name: string;
  };
}

export default function UseCasesFinderPresentation() {
  const { t } = useTranslation();
  const [useCases, setUseCases] = useState<PartnerUseCaseType[]>([]);

  const { loadingInfo } = usePartnerContext();
  const { isLoading } = useQuery<UseCase[]>({
    queryKey: ['useCases'],
    queryFn: async () => {
      const res = await getAPIClient().get(`/partnerUseCases/latestUseCases`);
      setUseCases(res.data);
      return res.data;
    },
    refetchOnWindowFocus: false
  });

  return loadingInfo ? (
    <Loader />
  ) : (
    <div className="flex flex-col gap-6">
      <Carousel />
      {isLoading && (
        <>
          <h1 className="self-start text-[#1F384C] m-0">
            {t('latest_use_cases')}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <Skeleton className="w-full h-[350px]" />
            <Skeleton className="w-full h-[350px]" />
            <Skeleton className="w-full h-[350px]" />
          </div>
        </>
      )}
      {useCases.length > 0 && (
        <>
          <h1 className="self-start text-[#1F384C] m-0">
            {t('latest_use_cases')}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {useCases?.map((useCase) => (
              <UseCaseCard
                useCase={useCase}
                setSelectedUseCase={undefined}
                setUseCases={setUseCases}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
