import IconArrowRight from '../../../public/images/icon-arrow-right.svg';
import CheckIcon from '../../../public/images/delivered-check.svg';
import SaveIcon from '../../../public/images/icon-save-white.svg';
import IconTrash from '../../../public/images/icon-trash.svg';
import AddIcon from '../../../public/images/add-icon-cross.svg';
import CloseIcon from '../../../public/images/close-icon.svg';
import SubscribeIcon from '../../../public/images/subscribe-icon.svg';
import DownloadIcon from '../../../public/images/download-icon.svg';
import SaveSpecialIcon from '../../../public/images/save-special-icon.svg';
import Import from '../../../public/images/import.svg';
import styles from './style.module.scss';
import IconFilters from '../../../public/images/filters.svg';
import IconFiltersSelected from '../../../public/images/filters-selected.svg';
import IconChevronLeftBlue from '../../../public/images/icon-chevron-left-blue.svg';
import HistoryIcon from '../../../public/images/history-icon.svg';
import SentIcon from '../../../public/images/send-email.svg';
import ShareIcon from '../../../public/images/share-icon.svg';
import DownloadIconDisabled from '../../../public/images/download-icon-disable.svg';
import SubscribeIconDisabled from '../../../public/images/subscribe-icon-disable.svg';
import IconGridSelected from '../../../public/images/icon-grid-selected.svg';
import IconGridIdle from '../../../public/images/icon-grid-idle.svg';
import IconListSelected from '../../../public/images/icon-list-selected.svg';
import IconListIdle from '../../../public/images/icon-list-idle.svg';
import IconCalendarActive from '../../../public/images/calendar_list_active.svg';
import IconCalendarInactive from '../../../public/images/calendar_list_inactive.svg';
import SurveyIcon from '../../../public/images/survey.svg';

export default function BaseButton(props) {
  const {
    color = 'primary',
    withIcon,
    iconType,
    iconPos = 'right',
    disabled,
    action,
    title,
    toUppercase = false,
    style = {},
    selected,
    required,
    noLabel = false,
    className: customClassName = ''
  } = props;

  let icon = null;
  const iconColor = `${!disabled ? styles[`icon_${color}`] : ''}`;
  const iconPosition = `${
    iconPos === 'right' ? styles.icon_right_position : styles.icon_left_position
  } ${noLabel ? styles.no_title : ''}`;
  const iconClasses = `${styles.icon} ${iconColor} ${iconPosition}`;

  if (withIcon) {
    icon = (
      <span className={`${styles.default_icon} ${iconColor}`}>
        <IconArrowRight />
      </span>
    );
  }

  if (iconType === 'add') {
    icon = (
      <span className={iconClasses}>
        <AddIcon />
      </span>
    );
  } else if (iconType === 'subscribe') {
    icon = (
      <span className={iconClasses}>
        {disabled ? <SubscribeIconDisabled /> : <SubscribeIcon />}
      </span>
    );
  } else if (iconType === 'close') {
    icon = (
      <span className={iconClasses}>
        <CloseIcon />
      </span>
    );
  } else if (iconType === 'download') {
    icon = (
      <span className={iconClasses}>
        {disabled ? <DownloadIconDisabled /> : <DownloadIcon />}
      </span>
    );
  } else if (iconType === 'special_save') {
    icon = (
      <span className={iconClasses}>
        <SaveSpecialIcon />
      </span>
    );
  } else if (iconType === 'save') {
    icon = (
      <span className={iconClasses}>
        <SaveIcon />
      </span>
    );
  } else if (iconType === 'delete') {
    icon = (
      <span className={iconClasses}>
        <IconTrash />
      </span>
    );
  } else if (iconType === 'filter') {
    icon = (
      <span className={styles.icon_filters}>
        {selected ? <IconFiltersSelected /> : <IconFilters />}
      </span>
    );
  } else if (iconType === 'delivered') {
    icon = (
      <span className={iconClasses}>
        <CheckIcon />
      </span>
    );
  } else if (iconType === 'chevron-left-blue') {
    icon = (
      <span className={styles.icon_filters_chevron_left}>
        <IconChevronLeftBlue />
      </span>
    );
  } else if (iconType === 'share') {
    icon = (
      <span className={iconClasses}>
        <ShareIcon />
      </span>
    );
  } else if (iconType === 'calendar') {
    icon = (
      <span className={styles.icon_calendar}>
        {selected ? <IconCalendarActive /> : <IconCalendarInactive />}
      </span>
    );
  } else if (iconType === 'survey') {
    icon = (
      <span className={iconClasses}>
        <SurveyIcon />
      </span>
    );
  }

  if (iconType === 'history') {
    icon = (
      <span className={iconClasses}>
        <HistoryIcon />
      </span>
    );
  } else if (iconType === 'sent') {
    icon = (
      <span className={iconClasses}>
        <SentIcon />
      </span>
    );
  } else if (iconType === 'import') {
    icon = (
      <span className={iconClasses}>
        <Import />
      </span>
    );
  } else if (iconType === 'grid') {
    icon = (
      <span className={iconClasses}>
        {selected ? <IconGridSelected /> : <IconGridIdle />}
      </span>
    );
  } else if (iconType === 'list') {
    icon = (
      <span className={iconClasses}>
        {selected ? <IconListSelected /> : <IconListIdle />}
      </span>
    );
  }

  if (iconType === 'import') {
    icon = (
      <span className={iconClasses}>
        <Import />
      </span>
    );
  }

  return (
    <button
      className={`${styles.button} ${!disabled ? styles[color] : ''} ${
        withIcon ? styles.with_icon : ''
      } ${toUppercase ? styles.to_uppercase : ''} ${customClassName}`}
      onClick={action}
      disabled={disabled}
      style={style}
    >
      {iconPos !== 'right' && icon}
      {!noLabel && (
        <span>
          {title}
          {required && (
            <span
              className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}
            >
              *
            </span>
          )}
        </span>
      )}
      {iconPos === 'right' && icon}
    </button>
  );
}
