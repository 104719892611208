import styles from './style.module.scss';
import EventCard from '../../molecules/Cards/EventCard';
import { Skeleton } from 'components/ui/skeleton';
import { Dispatch } from 'react';

export default function FinderEventsList({
  events,
  setSelectedEvent = () => {},
  isLoading = false,
  loadingCount = 12
}: {
  events: any[];
  setSelectedEvent: any;
  isLoading?: boolean;
  loadingCount?: number;
}) {
  return (
    <ul className={styles.events_list}>
      {isLoading
        ? Array.apply(null, Array(loadingCount)).map((e, i) => (
            <Skeleton
              key={`loading_partner_card${i}`}
              className="h-[400px] rounded-md"
            />
          ))
        : events?.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              setSelectedEvent={setSelectedEvent}
            />
          ))}
    </ul>
  );
}
