import { useEffect, useState } from 'react';

function useNavigator() {
  const [defaultNavigator, setDefaultNavigator] = useState<Navigator | null>(
    null
  );

  useEffect(() => setDefaultNavigator(navigator), []);

  return defaultNavigator;
}

export default useNavigator;
