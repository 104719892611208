import { useContext } from 'react';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import { useTranslation } from 'react-i18next';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import { Checkbox } from 'components/ui/checkbox';

export default function PartnerTypes({
  currentFilters,
  updateFilters
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { t } = useTranslation();
  const { clickOnFilter } = useContext(PartnersContext);
  const selectedPartnerTypes =
    currentFilters?.partnerTypeConsultingServices || [];
  const selectedEducationDelivery =
    currentFilters?.partnerTypeAuthorizedEducationDelivery || [];
  const selectedTechnicalSupport =
    currentFilters?.partnerTypeAuthorizedTechnicalSupport || [];

  return (
    <fieldset className="flex flex-col gap-2 mb-4">
      <div className="m-0 p-0 font-extralight text-base text-[#2360a0]">
        {t('partner_type')}
      </div>
      <div className="flex flex-col gap-1">
        <Checkbox
          id="typeConsultingServices"
          name="partner-type"
          alignItems="start"
          label={<>{t('consulting_services')}</>}
          onCheckedChange={() => {
            updateFilters(
              'partnerTypeConsultingServices',
              'consultingServices'
            );
            clickOnFilter('typeConsultingServices');
          }}
          checked={selectedPartnerTypes?.includes('consultingServices')}
        />
        <Checkbox
          id="authorized-education-delivery"
          name="partner-type"
          alignItems="start"
          label={
            <div className="w-max flex items-start">
              {t('authorized_education_delivery')}
              <HybridTooltip
                text={t('ed_delivery_tooltip')}
                alignAsText
                portalContainerId={'landing_page_filters_popup'}
              />
            </div>
          }
          onCheckedChange={() => {
            updateFilters(
              'partnerTypeAuthorizedEducationDelivery',
              'authorizedEducationDelivery'
            );
            clickOnFilter('Authorized Education Delivery');
          }}
          checked={selectedEducationDelivery?.includes(
            'authorizedEducationDelivery'
          )}
        />
        <Checkbox
          id="authorized-technical-support"
          name="partner-type"
          alignItems="start"
          label={t('authorized_technical_support')}
          onCheckedChange={() => {
            updateFilters(
              'partnerTypeAuthorizedTechnicalSupport',
              'authorizedTechnicalSupport'
            );
            clickOnFilter('Authorized Technical Support');
          }}
          checked={selectedTechnicalSupport?.includes(
            'authorizedTechnicalSupport'
          )}
        />
      </div>
    </fieldset>
  );
}
