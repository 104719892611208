import { useContext, useEffect, useState } from 'react';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});
const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});
const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function KnightsType({
  currentFilters,
  updateFilters,
  types
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
  types: any[];
}) {
  const { t } = useTranslation();

  const { clickOnFilter } = useContext(PartnersContext);
  const [tps, setTypes] = useState<any[]>([]);

  useEffect(() => {
    if (currentFilters?.types?.length > 0) {
      const knightTypes = currentFilters.types.map((l) =>
      types?.find((e) => e.label === l)
      );
      setTypes([...knightTypes]);
    }
  }, []);
  useEffect(() => {
    if (!currentFilters?.types) setTypes([]);
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="knight_types" className="text-base font-light">
        {t('type')}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="types"
          placeholder={t('type')}
          data={types}
          value={tps}
          onChange={(t) => {
            setTypes([...t]);
            updateFilters(
              'types',
              t?.map((e) => e?.label)
            );
            clickOnFilter('Type (Knights)');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
