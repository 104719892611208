import Carousel from './Carousel';
import FindYourExpertResume from '../../Resumes/FindYourExpertResume';

export default function FindYourExpertPresentation() {
  return (
    <div className="flex flex-col mt-4 mb-12 gap-24">
      <div className="flex flex-col gap-24 w-full">
        <FindYourExpertResume />
      </div>
    </div>
  );
}
