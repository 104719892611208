import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styles from './style.module.scss';
import { getAPIClient } from 'services/axios';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { checkMinSearchChars } from 'shared/utils';
import { MIN_CHARS_SEARCH } from 'shared/constants';
import {
  compareFilters,
  formatComplexSearch,
  parseFilters,
  updateMarketplaceFilters,
  updateKnightsFilters,
  updatePartnerFilters,
  updateEventsFilters,
  updateUseCasesFilters,
  updateCSPFilters
} from './auxFunctions';
import useOutsideClickMultiple from 'hooks/useOutsideClickMultiple';
import { Input } from 'components/ui/finder-tool-search-bar';
import { Button } from 'components/ui/button';
import { setLocalStorageItem } from 'lib/localStorage';
import useDocument from 'hooks/useDocument';
import FiltersPopup from 'componentsV2/molecules/LandingPageContent/Filters';
import Page from 'components/ui/page';
import { LandingPageResources } from 'componentsV2/pages/LandingPage';
import {
  useUseCasesContext,
  useCSPContext,
  useEventsContext,
  useKnightsContext,
  useOfferingContext,
  usePartnerContext
} from 'hooks/useContexts';

type ComponentProps = {
  tab: string;
  filtersResources: LandingPageResources;
  isFirstWebsiteVisit: boolean;
  setFirstWebsiteVisit: Dispatch<SetStateAction<boolean>>;
  standalone?: boolean;
};

export default function FinderToolsToolbar({
  tab = 'partner',
  filtersResources,
  isFirstWebsiteVisit = false,
  setFirstWebsiteVisit = (boolean) => {},
  standalone = false
}: ComponentProps) {
  const { t } = useTranslation();

  const suggestionsRef: any = useRef();
  const inputRefTooltip: any = useRef();
  const searchBtnRef: any = useRef();
  const inputRef = useRef(null);

  const {
    filters,
    setFilters,
    totalFilters,
    visibleTotalFilters,
    updateFilters: instantFiltersUpdate,
    clearFinderToolRandomization,
    clearFinderToolPagination
  } = tab === 'marketplace'
    ? useOfferingContext()
    : tab === 'knights'
    ? useKnightsContext()
    : tab === 'cloud_svcs'
    ? useCSPContext()
    : tab === 'events'
    ? useEventsContext()
    : tab === 'useCases'
    ? useUseCasesContext()
    : usePartnerContext();

  const searchKey =
    tab === 'marketplace'
      ? 'complexSearchOffering'
      : tab === 'knights'
      ? 'complexSearchKnight'
      : tab === 'events'
      ? 'complexSearchEvent'
      : tab === 'useCases'
      ? 'complexSearchUseCase'
      : tab === 'cloud_svcs'
      ? 'complexSearchCSP'
      : 'complexSearch';

  const [isMounted, setIsMounted] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [focusLastCharacter, setFocusLastCharacter] = useState(true);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(
    parseFilters({ ...filters })
  );
  const [filtersChanges, setFiltersChanges] = useState(false);
  const [search, setSearch] = useState(filters[searchKey] || '');

  const searchId = 'complex_search_input_identifier';
  const document = useDocument();
  const suggestionsKey =
    tab === 'marketplace'
      ? 'marketplaceSuggestions'
      : tab === 'knights'
      ? 'knightsSuggestions'
      : tab === 'events'
      ? 'eventsSuggestions'
      : tab === 'useCases'
      ? 'partnerUseCasesSuggestions'
      : tab === 'cloud_svcs'
      ? 'provider/suggestions'
      : 'suggestions';

  const searchIsFocused = document?.activeElement?.id === searchId;
  const disableSearchBtn = !filtersChanges || !checkMinSearchChars(search);

  //disable suggestions when clicking outside of input and suggestions container
  useOutsideClickMultiple([suggestionsRef, inputRefTooltip], () => {
    setShowSuggestions(false);
  });

  const updateFilters = (prop, value) => {
    const update =
      tab === 'marketplace'
        ? updateMarketplaceFilters
        : tab === 'knights'
        ? updateKnightsFilters
        : tab === 'cloud_svcs'
        ? updateCSPFilters
        : tab === 'events'
        ? updateEventsFilters
        : tab === 'useCases'
        ? updateUseCasesFilters
        : updatePartnerFilters;
    update(setCurrentFilters, prop, value);
  };

  const handleClear = () => {
    setPopupOpen(false);
    setShowTooltip(true);
    setSearch('');
    instantFiltersUpdate(searchKey, '');
  };

  const clearFilters = () => {
    if (currentFilters[searchKey])
      setCurrentFilters({ [searchKey]: currentFilters[searchKey] });
    else setCurrentFilters({});
  };

  //suggestions
  const filtersToUrl = `${new URLSearchParams(filters).toString()}`;

  const { data: suggestions, isLoading } = useQuery({
    queryKey: [suggestionsKey, search, filtersToUrl],
    queryFn: async () => {
      const { data }: any = await getAPIClient().get(
        `${suggestionsKey}?searchQuery=${search.replaceAll(
          '&',
          '/_/:/_/'
        )}&${filtersToUrl}`
      );
      if (data?.values?.length > 0) setShowSuggestions(true);
      else setShowSuggestions(false);
      return data;
    }
  });

  const moveCursorToEnd = () => {
    if (inputRef.current) {
      const inputElement: any = inputRef.current;
      inputElement?.focus();

      if (inputElement.value) {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
        inputElement.scrollLeft = inputElement.scrollWidth;
      }
    }
  };

  //choose suggestion
  const chooseOption = (option) => {
    let keys = search.split(';').map((e) => e.trim());
    keys[search.split(';').length - 1] = `${option.trim()}; `;
    setSearch(keys.join('; '));
    moveCursorToEnd();
    setPopupOpen(true);
  };

  // Dismiss black background after clicking on the search bar
  const handleSearchInputClick = () => {
    if (isFirstWebsiteVisit) {
      setLocalStorageItem('isFirstWebsiteVisit', false);
      setFirstWebsiteVisit(false);
    }

    if (suggestions?.values?.length > 0) setShowSuggestions(true);

    if (focusLastCharacter) {
      moveCursorToEnd();
      setFocusLastCharacter(false);
    }

    setShowTooltip(false);
    setPopupOpen(true);
  };

  // Dismiss black background after clicking on the search bar
  const handleClickFirstVisit = () => {
    if (isFirstWebsiteVisit) {
      setLocalStorageItem('isFirstWebsiteVisit', false);
      setFirstWebsiteVisit(false);
    }
  };

  const applyFilters = () => {
    //parseFilters - remove memoization from values insite filters
    setFilters(parseFilters(currentFilters));
  };

  //handle click on search button or enter key on search bar
  const handleSearch = () => {
    if (!checkMinSearchChars(search)) {
      setShowError(true);
      return;
    }
    if (!filtersChanges) return;
    setShowError(false);
    applyFilters();
    setShowSuggestions(false);
    setPopupOpen(false);
    if (document?.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  function resetCurrentFiltersBasedOnSearchedFilters() {
    setCurrentFilters((prevFilters) => {
      const parsedFilters = parseFilters({ ...filters });

      const updatedFilters = Object.keys(parsedFilters).reduce((acc, key) => {
        if (!key.includes('complexSearch')) {
          acc[key] = parsedFilters[key];
        }
        return acc;
      }, {});

      if (parsedFilters[searchKey]) {
        setSearch(parsedFilters[searchKey]);
      }

      return {
        ...prevFilters,
        ...updatedFilters
      };
    });
  }

  //update current filters state
  useEffect(() => {
    updateFilters(searchKey, formatComplexSearch(search));
  }, [search]);

  //compare changes between filters applied and the current selected filters
  useEffect(() => {
    if (compareFilters(currentFilters, filters)) {
      setFiltersChanges(true);
    } else {
      setFiltersChanges(false);
    }
    clearFinderToolRandomization();
    clearFinderToolPagination();
  }, [currentFilters, filters]);

  useEffect(() => {
    resetCurrentFiltersBasedOnSearchedFilters();
  }, [isPopupOpen]);

  useEffect(() => {
    //this needs to run once in the beginning to populate the current filters
    if (isMounted) return;
    if (Object.keys(filters).length > 0) setIsMounted(true);
    resetCurrentFiltersBasedOnSearchedFilters();
  }, [filters]);

  return (
    <Page
      className={`${styles.above_header} ${standalone ? styles.center : ''}`}
    >
      <fieldset className={styles.filters_container}>
        <div className={styles.menu}>
          <div className={styles.overlay}>
            <div
              className={`${styles.search_wrapper} ${
                standalone ? styles.standalone : ''
              } ${!showTooltip ? styles.hide_tooltip : ''}`}
              data-tip={
                tab === 'marketplace'
                  ? t('searchbar_market')
                  : tab === 'knights'
                  ? t('searchbar_knights')
                  : tab === 'events'
                  ? t('searchbar_event')
                  : tab === 'useCases'
                  ? t('searchbar_useCases')
                  : tab === 'cloud_svcs'
                  ? t('searchbar_csp')
                  : t('searchbar_partner')
              }
              ref={inputRefTooltip}
            >
              <Input
                id={searchId}
                className="bg-transparent"
                onChange={({ target: { value } }) => {
                  setSearch(value);
                  setShowError(false);
                }}
                onClear={handleClear}
                ref={inputRef}
                placeholder={
                  tab === 'marketplace'
                    ? t('searchbar_marketplace_finder')
                    : tab === 'knights'
                    ? t('searchbar_knights_finder')
                    : tab === 'events'
                    ? t('searchbar_event_finder')
                    : tab === 'useCases'
                    ? t('searchbar_usecase_finder')
                    : tab === 'cloud_svcs'
                    ? t('searchbar_csp_finder')
                    : t('searchbar_partner_finder')
                }
                value={search}
                onClick={handleSearchInputClick}
                loading={isLoading && search?.length > 2}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.keyCode === 13) {
                    handleSearch();
                  }
                }}
                autoComplete="off"
              />
              {showError && (
                <div className={styles.error}>
                  {t('min_chars_search', { number: MIN_CHARS_SEARCH })}
                </div>
              )}
              {showSuggestions && searchIsFocused && (
                <div
                  ref={suggestionsRef}
                  className={styles.suggestions_wrapper}
                >
                  {suggestions?.values?.map((e, index) => (
                    <div
                      key={`suggestion-${e.name}-${index}`}
                      className={styles.item}
                      onClick={() => {
                        chooseOption(e.name);
                      }}
                    >
                      {e.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={`${styles.button_wrapper} ${styles.mobile}`}>
              <FiltersPopup
                filtersResources={filtersResources}
                filters={filters}
                totalFilters={totalFilters}
                visibleTotalFilters={visibleTotalFilters}
                tab={tab}
                hasChanges={filtersChanges}
                isPopupOpen={isPopupOpen}
                setPopupOpen={setPopupOpen}
                inputRefTooltip={inputRefTooltip}
                searchBtnRef={searchBtnRef}
                handleFirstVisit={handleClickFirstVisit}
                currentFilters={currentFilters}
                clearFilters={clearFilters}
                updateFilters={updateFilters}
                applyFilters={applyFilters}
              />
              <Button
                ref={searchBtnRef}
                className="hidden md:block w-full h-auto ml-3"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSearch();
                }}
                disabled={disableSearchBtn}
              >
                {t('search')}
              </Button>
            </div>
          </div>
        </div>
      </fieldset>
    </Page>
  );
}
