import { useEffect, useState } from 'react';

export default function useOnScreen(ref, deps) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (!ref?.current) return;
    observer?.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [deps]);

  return isIntersecting;
}
