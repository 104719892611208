import MarketplaceFinderResume from '../../Resumes/MarketplaceFinderResume';
import MarketplaceCarousel from './Carousel';
import HighlightedIntegrations from './HighlightedIntegrations';

export default function MarketplaceFinderPresentation() {
  return (
    <div className="flex flex-col gap-12">
      <MarketplaceCarousel />
      <HighlightedIntegrations />
      <div className="flex flex-col gap-24 w-full">
        <MarketplaceFinderResume reverse />
      </div>
    </div>
  );
}
