import { useTranslation } from 'react-i18next';
import Resume from './Resume';

export default function MarketplaceFinderResume({
  reverse = false
}: {
  reverse?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Resume
      key={'marketplace_resume'}
      title={t('search_download')}
      description={t('marketplace_landing')}
      image={{
        src: '/images/marketplace-finder-landing.png',
        alt: 'Marketplace Finder Image'
      }}
      reverse={reverse}
    />
  );
}
