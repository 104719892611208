import dynamic from 'next/dynamic';
import UpcomingEvents from '../../UpcomingEvents';
import { Skeleton } from 'components/ui/skeleton';
import { useTranslation } from 'react-i18next';

const Calendar = dynamic(() => import('../../EventsLandingPageCalendar'), {
  ssr: false,
  loading: () => (
    <div className="flex flex-col gap-4">
      <Skeleton className="w-[40rem] h-8" />
      <div className="flex items-center gap-8 h-[400px]">
        <Skeleton className="h-full w-[20%]" />
        <Skeleton className="h-full flex-1" />
      </div>
    </div>
  )
});

export default function EventsFinderPresentation() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4 items-center">
        <div className="text-[#1F384C] text-[1.75rem] font-normal">
          {t('main_page_top_events')}
        </div>
        <div className="text-[#53565A] text-base font-light">
          {t('main_page_top_events_desc')}
        </div>
      </div>
      <UpcomingEvents eventType="all" />
      <Calendar />
    </div>
  );
}
