import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger
} from 'components/ui/tooltip';
import { ReactNode } from 'react';

export default function BadgeTooltip({
  children,
  text
}: {
  children: ReactNode;
  text: string;
}) {
  return (
    <TooltipProvider delayDuration={0} skipDelayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            style={{
              background: '#1F384C'
            }}
            className="text-white font-light p-4"
          >
            {text}
            <TooltipArrow style={{ fill: '#1F384C' }} />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
}
