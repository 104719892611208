import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from 'components/ui/dialog';
import KnightsDetail from 'components/molecules/KnightsDetail';
import { Dispatch, SetStateAction, useState } from 'react';
import SendEmailForm from 'componentsV2/molecules/Modals/SendEmailForm';

interface ModalProps {
  isOpen?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  knight: any;
  isPreview?: boolean;
}

export default function KnightProfileModal({
  isOpen,
  setOpen,
  knight = {},
  isPreview = false
}: ModalProps) {
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          {knight?.awarded && (
            <div className="absolute px-4 py-1 top-[-10px] right-[70px] bg-[#2360a0] text-white font-light text-sm rounded-sm text-center">
              {t('knight_award')} {`${knight?.year.toString().slice(2, 4)}'`}
            </div>
          )}
          <DialogTitle>
            {t('knights')} - {knight?.name}
          </DialogTitle>
        </DialogHeader>
        <KnightsDetail
          knight={knight}
          setEmailModalOpen={setIsOpenModal}
          hideName
          isPreview={isPreview}
        />
        {isOpenModal && (
          <SendEmailForm
            open={isOpenModal}
            setOpen={setIsOpenModal}
            partnerEmail={knight.email}
            partnerName={knight.name}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}
