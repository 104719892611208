import styles from './style.module.scss';

export const Popup = ({
  isOpen = false,
  popupStyle = {},
  trigger,
  children
}) => {
  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.trigger}`}>{trigger}</div>
      {isOpen && (
        <div className={`${styles.popup} `} style={popupStyle}>
          {children}
        </div>
      )}
    </div>
  );
};
