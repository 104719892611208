import { useEffect } from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import * as ga from 'lib/ga';
import MarketPlaceTileLogo from 'public/images/marketplace-tile-logo.svg';
import { capitalizeAllWords } from 'shared/utils';
import { api } from 'services/api';
import HybridTooltip from 'components/ui/hybrid-tooltip';
import Image from 'next/image';
import { MousePointer } from 'lucide-react';
import { Button } from 'components/ui/button';
import { cn } from 'lib/utils';
import { usePartnerContext } from 'hooks/useContexts';

export default function PublicOfferingCard({
  offering,
  bgContrast = false
}: {
  offering: any;
  bgContrast?: boolean;
}) {
  const { t } = useTranslation();
  const { setLoadingInfo } = usePartnerContext();

  const hasMultiplePrices = offering?.price?.length > 1;

  const offeringFirstPrice = capitalizeAllWords(
    offering?.price[0]?.label.replaceAll('_', ' ')
  );

  // Data to show on the prices tooltip if the offering has more than one
  const pricesTooltipData = offering?.price.map((e, index) => {
    if (index === 0) return;
    return (
      <div key={`offering-${offering?.id}-price-${index}`}>
        {capitalizeAllWords(e.label.replaceAll('_', ' '))}
      </div>
    );
  });

  useEffect(() => {
    ga.event({
      action: 'search',
      params: {
        search_term: `Tile presented (${offering.name})`
      }
    });
  }, []);

  const incrementClicks = async () => {
    try {
      await api.put(`/offering/${offering.id}/incrementClicks`);
    } catch (error) {}
  };

  return (
    <li
      className={cn(
        'relative box-border w-full h-full flex flex-col justify-between items-start gap-7 bg-white rounded-[10px] p-5',
        bgContrast ? 'bg-[#fafafb]' : ''
      )}
      style={{
        boxShadow: bgContrast
          ? 'none'
          : '0px 3.474px 13.895px 0px rgba(51, 51, 51, 0.08)'
      }}
    >
      <div className="w-full flex flex-col items-start gap-6">
        <div className="absolute top-0 right-8">
          <div
            className="h-5 w-full p-1 flex justify-center items-center bg-[#f1f1f5]"
            style={{
              filter:
                'drop-shadow(0px 4.72727px 4.72727px rgba(0, 92, 138, 0.06))'
            }}
          >
            <span className="text-black font-normal text-xs">
              {offeringFirstPrice}
            </span>
            {hasMultiplePrices && (
              <HybridTooltip
                text={pricesTooltipData}
                asChild
                side="bottom"
                contentClassName="p-2"
              >
                <div className="font-normal ml-1">...</div>
              </HybridTooltip>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="w-full flex items-center justify-start gap-5">
            <MarketPlaceTileLogo />
            <Image
              src={offering?.logo}
              alt={`${offering.name} logo image`}
              sizes="100px"
              className="w-auto max-w-[100px] h-auto max-h-[50px] object-contain"
              width={100}
              height={50}
            />
          </div>
          <h4 className="p-0 m-0 text-[#171725] text-lg font-normal break-[break-word]">
            {offering.name}
          </h4>
          <div className="text-[#53565a] text-sm font-light leading-6">
            {t('offering_created_by')}{' '}
            <span className="text-[#2360A0]">
              {offering.Partner ? <Link target="_blank" className='text-sm' href={`${`/partner/${offering.Partner.slug}`
          }`}>{offering.Partner?.name}</Link> : 'Broadcom'}
            </span>{' '}
            <br />
            <br />
            <span className="line-clamp-3">{offering.about}</span>
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-2 items-center gap-5">
        <Link
          href={`${
            offering.Partner
              ? `/partner/${offering.Partner.slug}/marketplace/${offering.id}`
              : `/marketplace/${offering.id}`
          }`}
          onClick={async () => {
            ga.event({
              action: 'search',
              params: {
                search_term: `Tile clicked (${offering.name})`
              }
            });
            incrementClicks();
            setLoadingInfo(true);
          }}
          scroll={false}
        >
          <Button className="py-3 w-full h-auto font-normal text-sm">
            {t('learn_more')}
          </Button>
        </Link>
        <div className="w-full flex items-center justify-start gap-1">
          <div className="w-6 h-6 rounded-full flex items-center justify-center">
            <MousePointer color='#CC0830' className="w-4 h-4" />
          </div>
          <span className="font-light text-[#53565A] text-xs">
            {offering.clicks || 0} {t('click_throughs')}
          </span>
        </div>
      </div>
      {/* <div
        className={`${styles.main_wrapper} ${
          bgContrast ? styles.backgroundContrast : null
        }`}
      ></div> */}
    </li>
  );
}
