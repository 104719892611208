import PartnersList from 'components/organisms/PartnersList';
import { useKnightsContext } from 'hooks/useContexts';
import { useTranslation } from 'react-i18next';
import Pagination from '../Pagination';
import KnightsPublicGrid from 'components/organisms/KnightsPublicGrid';
import { KnightsFinderControllerResult } from 'controllers/finderTools/KnightsFinder';
import FinderNoResult from './FinderNoResults';
import { Skeleton } from 'components/ui/skeleton';

const defaultKnightsFinder = {
  totalKnights: 0,
  totalRelatedPartners: 0,
  currentKnights: [],
  KNIGHTS_PER_PAGE: 0,
  RELATED_PARTNERS_PER_PAGE: 0,
  relatedPartnersFromPage: [],
  isLoading: false,
  isLoadingRelated: false
};

export default function KnightsFinderResults({
  standalone = false,
  knightsFinder = defaultKnightsFinder
}: {
  standalone?: boolean;
  knightsFinder?: KnightsFinderControllerResult;
}) {
  const { t } = useTranslation();

  const {
    totalKnights = 0,
    totalRelatedPartners = 0,
    currentKnights = [],
    KNIGHTS_PER_PAGE = 12,
    RELATED_PARTNERS_PER_PAGE = 4,
    relatedPartnersFromPage = [],
    isLoading = false,
    isLoadingRelated = false
  } = knightsFinder;

  const {
    currentPageFiltering,
    setCurrentPageFiltering,
    relatedPageFiltering,
    setRelatedPageFiltering
  } = useKnightsContext();

  return (
    <>
      {isLoading ||
      isLoadingRelated ||
      (!isLoading && !isLoadingRelated && currentKnights?.length > 0) ? (
        <div className="flex flex-col gap-32 mb-20">
          <section className="flex flex-col gap-10">
            <h3
              className="text-[#171725] p-0 m-0 font-normal inline-flex items-center gap-2"
              id="knightsFinder-mainResults"
            >
              {t('results')}{' '}
              {isLoading ? (
                <Skeleton className="w-36 h-8" />
              ) : (
                <span className="font-extralight text-lg">
                  ({t('showing')} {totalKnights})
                </span>
              )}
            </h3>
            <KnightsPublicGrid
              knights={currentKnights}
              isLoading={isLoading}
              loadingCount={12}
            />
            {isLoading ? null : (
              <div className="flex justify-between text-[#b5b7c0]">
                <Pagination
                  perPage={KNIGHTS_PER_PAGE}
                  total={totalKnights}
                  currentPage={currentPageFiltering}
                  onChange={(currentPageFiltering) => {
                    setCurrentPageFiltering(currentPageFiltering);
                  }}
                  showDataInfo
                  removePadding
                  scrollId="knightsFinder-mainResults"
                />
              </div>
            )}
          </section>
          {/* Related Partners */}
          {(!standalone &&
            !isLoadingRelated &&
            relatedPartnersFromPage.length > 0) ||
          isLoadingRelated ? (
            <section className="flex flex-col gap-10">
              <h3
                className="text-[#171725] p-0 m-0 font-normal inline-flex items-center gap-2"
                id="knightsFinder-relatedResults"
              >
                {t('rel_partners')}{' '}
                {isLoadingRelated ? (
                  <Skeleton className="w-36 h-8" />
                ) : (
                  <span className="font-extralight text-lg">
                    ({t('showing')} {totalRelatedPartners})
                  </span>
                )}
              </h3>
              <PartnersList
                partners={relatedPartnersFromPage}
                isLoading={isLoadingRelated}
                loadingCount={3}
                related="knights"
              />
              {isLoadingRelated ? null : (
                <div className="flex justify-between text-[#b5b7c0]">
                  <Pagination
                    perPage={RELATED_PARTNERS_PER_PAGE}
                    total={totalRelatedPartners}
                    currentPage={relatedPageFiltering}
                    onChange={(relatedPageFiltering) => {
                      setRelatedPageFiltering(relatedPageFiltering);
                    }}
                    showDataInfo
                    removePadding
                    scrollId="knightsFinder-relatedResults"
                  />
                </div>
              )}
            </section>
          ) : null}
        </div>
      ) : null}
      {!isLoading && !isLoadingRelated && currentKnights.length === 0 ? (
        <FinderNoResult />
      ) : null}
    </>
  );
}
