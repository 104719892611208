'use client';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui/button';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { getAPIClient } from 'services/axios';
import { cn } from 'lib/utils';
import { getLocalStorageItem, setLocalStorageItem } from 'lib/localStorage';
import Link from 'next/link';
import { usePartnerContext } from 'hooks/useContexts';

const DEFAULT_PARTNER_IMAGE = '/images/event-conference-large.png';
const DEFAULT_LOGO = '/images/broadcom-icon.png';

export default function UseCaseCard({
  useCase,
  slug = '',
  setSelectedUseCase,
  setUseCases
}) {
  const { t } = useTranslation();
  const [liked, setLiked] = useState(false);
  const [score, setScore] = useState(useCase?.score || 0);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { setLoadingInfo } = usePartnerContext();

  const incrementClicks = async () => {
    try {
      await getAPIClient().put(
        `/partnerUseCases/clickThroughs/${useCase.id}?offerButtonClickThroughs=true`
      );
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    const storedLike = getLocalStorageItem(`useCase_like_${useCase?.id}`);
    if (storedLike) {
      setLiked(storedLike === 'true');
    }
    setScore(useCase?.score);
  }, []);

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: async () => {
      await getAPIClient().put(
        `/partnerUseCases/updateScore/${useCase?.id}?increment=${
          liked ? -1 : 1
        }`
      );
    },
    onSuccess: () => {
      setUseCases((prev) => {
        return prev.map((useCasePrev) => {
          if (useCase.id === useCasePrev?.id) {
            return {
              ...useCasePrev,
              score: useCasePrev.score + (liked ? -1 : 1)
            };
          }
          return useCasePrev;
        });
      });
      setScore((prev) => (liked ? prev - 1 : prev + 1));
      setLocalStorageItem(`useCase_like_${useCase?.id}`, !liked);
    }
  });

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    setScore(useCase?.score);
    setLiked((prev) => !prev);
  }, [useCase?.score]);

  return (
    <div
      className="relative box-border w-full h-full flex flex-col items-start justify-between gap-4 rounded-[10px] bg-white"
      style={{
        boxShadow: '0px 3.474px 13.895px 0px rgba(51, 51, 51, 0.08)'
      }}
    >
      <div className="w-full flex flex-col items-start gap-4">
        <div className="relative w-full h-auto">
          <div className="relative w-full h-[180px]">
            <Image
              src={useCase?.banner || DEFAULT_PARTNER_IMAGE}
              alt="background-img"
              fill
              quality={100}
              sizes="50vw"
              className="overflow-hidden rounded-t-sm object-cover"
            />
          </div>
          <Image
            className="absolute left-2 bottom-2 rounded-sm bg-white object-contain"
            width={80}
            height={80}
            quality={100}
            src={useCase?.Partner?.logo || DEFAULT_LOGO}
            alt="partner-logo"
          />
          <div className="absolute right-4 top-3 flex gap-2 items-center justify-center h-[34px] w-[58px] rounded-[3px] cursor-pointer">
            <Button
              className={cn(
                'h-auto py-2 px-3 font-[100] text-sm rounded-sm',
                liked
                  ? 'bg-[#6FCF97] hover:bg-[#6FCF97]'
                  : 'bg-[#2360A0] hover:bg-[#2360A0]'
              )}
              icon={liked ? 'like-filled' : 'like-empty'}
              iconPosition="right"
              onClick={() => {
                mutate();
              }}
              loading={isLoading}
            >
              {score}
            </Button>
          </div>
        </div>
        <div className="px-5 h-full flex flex-col items-start gap-4">
          <h4 className="m-0 p-0 text-[#1F384C] font-normal text-lg break-[break-word] overflow-x-hidden max-w-full">
            {useCase?.title || ''}
          </h4>
          <span className="text-[#52565A] teaxt-sm font-light leading-6 line-clamp-3 max-w-full">
            {useCase?.tagline?.length > 30
              ? `${useCase?.tagline?.slice(0, 30)}...` || ''
              : useCase?.tagline || ''}
          </span>
          <div>
            <span className="text-[#65696D] text-sm font-light leading-6 line-clamp-3 max-w-full">
              <span className="font-[400] text-[#1F384C]">
                {t('verticals')}:
              </span>{' '}
              {useCase?.vertical?.name || ''}
            </span>
            <span className="text-[#65696D] text-sm font-light leading-6 line-clamp-3 max-w-full">
              <span className="font-[400] text-[#1F384C]">
                {t('services')}:
              </span>{' '}
              {useCase?.services?.map((item) => item?.name).join('; ') || ''}
            </span>
          </div>
        </div>
      </div>
      <Link
        href={`${`/partner/${
          slug?.length > 0 ? slug : useCase?.Partner?.slug
        }/useCases?id=${useCase?.id}`}`}
        scroll={false}
        className="p-3 pl-5"
      >
        <Button
          className="h-auto font-[350] text-sm rounded-sm"
          onClick={() => {
            incrementClicks();
            setSelectedUseCase && setSelectedUseCase(useCase);
            setLoadingInfo && setLoadingInfo(true);
          }}
        >
          {t('view_use_case')}
        </Button>
      </Link>
    </div>
  );
}
