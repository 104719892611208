import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import useDevice from '../../../../../hooks/useDevice';
import styles from './style.module.scss';
import SelectLimitedPV from 'components/atoms/SelectLimitedPV/SelectLimitedPV';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' },
  paddingTop: '16px'
});

const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});
const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});
const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function UseCasesType({
  currentFilters,
  updateFilters,
  useCasesType
}) {
  const { t } = useTranslation();

  const useCasesType_data = useCasesType
    ?.map((l) => {
      return { ...l, label: l?.name, value: l?.name };
    })
    ?.sort((a, b) => a.label.localeCompare(b.label));

  const { clickOnFilter } = useContext(PartnersContext);
  const [cases, setCases] = useState<any[]>([]);

  useEffect(() => {
    if (currentFilters?.useCasesType?.length > 0) {
      const uses = currentFilters.useCasesType.map((l) =>
        useCasesType_data?.find((e) => e.value === l)
      );
      setCases([...uses]);
    }
  }, []);

  useEffect(() => {
    if (!currentFilters?.useCasesType) setCases([]);
  }, [currentFilters]);

  return (
    <div className={styles.fieldset}>
      <label htmlFor="useCasesType" className="text-base font-light">
        {t('usecases')}
      </label>
      <div className={styles.select}>
        <SelectLimitedPV
          id="useCases"
          placeholder={t('usecases')}
          data={useCasesType_data}
          value={cases}
          onChange={(s) => {
            setCases([...s]);
            updateFilters(
              'useCasesType',
              s?.map((e) => e?.value)
            );
            clickOnFilter('useCasesType');
          }}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          instanceId={undefined}
          prevValue={undefined}
          onChangeApprove={undefined}
          wasChanged={undefined}
          required={undefined}
          disabled={undefined}
          approve={undefined}
          approved={undefined}
          changed={undefined}
          dismiss={undefined}
          fullWidth={undefined}
          fullHeight={undefined}
          maxTags={undefined}
        />
      </div>
    </div>
  );
}
