import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from './axios';
import { getSolutionTypesFromProducts } from './dataQueries';

export function getLandingPageResources() {
  const { data, isLoading } = useQuery({
    queryKey: [
      'landingPageResources',
      'locations',
      'products',
      'services',
      'languages',
      'countries',
      'accreditations',
      'verticals',
      'formFactors',
      'operatingSystems',
      'prices',
      'eventTypes',
      'knightTypes',
      'divisions',
      'providerServices',
      'verticalMarket',
      'regions',
      'servicesProvided',
      'useCaseType',
      'readerType'
    ],
    queryFn: async () => {
      const apiClient = getAPIClient();

      const [
        locations = [],
        products = [],
        services = [],
        languages = [],
        countries = [],
        accreditations = [],
        verticals = [],
        formFactors = [],
        operatingSystems = [],
        prices = [],
        eventTypes = [],
        knightTypes = [],
        divisions = [],
        providerServices = [],
        verticalMarket = [],
        servicesProvided = [],
        regions = [],
        useCaseType = [],
        readerType = []
      ] = await Promise.all([
        apiClient.get(`/locations`),
        apiClient.get(`/products/public`),
        apiClient.get(`/services`),
        apiClient.get(`/language`),
        apiClient.get(`/country`),
        apiClient.get(`/accreditations`),
        apiClient.get(`/verticals`),
        apiClient.get(`/offeringFormFactor`),
        apiClient.get(`/operatingSystems`),
        apiClient.get(`/offeringPrices`),
        apiClient.get(`/eventTypes`),
        apiClient.get(`/types`),
        apiClient.get(`/useCasesDivisions`),
        apiClient.get(`/provider/cloudServices`),
        apiClient.get(`/provider/verticalMarket`),
        apiClient.get(`/provider/servicesProvided`),
        apiClient.get(`/provider/regions`),
        apiClient.get(`/products/level2`),
        apiClient.get(`/readerTypes`)
      ]).then((res: any) => {
        return res.map((res) => res.data);
      });

      return {
        locations: locations || [],
        products: products || [],
        services: services || [],
        languages: languages || [],
        accreditations: accreditations || [],
        countries: countries || [],
        verticals: verticals || [],
        formFactors: formFactors || [],
        operatingSystems: operatingSystems || [],
        prices: prices || [],
        eventTypes: eventTypes || [],
        knightTypes: knightTypes || [],
        divisions: divisions || [],
        providerServices: providerServices || [],
        verticalMarket: verticalMarket || [],
        servicesProvided: servicesProvided || [],
        regions: regions || [],
        useCaseType: getSolutionTypesFromProducts(useCaseType, 'name') || [],
        readerType: readerType || []
      };
    }
  });

  if (data) return { ...data, isLoading: isLoading };

  return {
    isLoading: isLoading,
    locations: [],
    products: [],
    services: [],
    languages: [],
    accreditations: [],
    countries: [],
    verticals: [],
    formFactors: [],
    operatingSystems: [],
    prices: [],
    eventTypes: [],
    knightTypes: [],
    divisions: [],
    providerServices: [],
    verticalMarkets: [],
    servicesProvided: [],
    regions: [],
    useCaseType: [],
    readerType: []
  };
}
