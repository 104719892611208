import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/ui/checkbox';
import EventsContext from 'context/EventsListingsProvider';

export default function BroadcomEvents({
  currentFilters,
  updateFilters
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { t } = useTranslation();
  const { clickOnFilter } = useContext(EventsContext);

  return (
    <fieldset className="flex flex-col gap-2">
      <div className="p-0 m-0 mt-4 text-base font-extralight text-[#2360a0]">
        {t('broadcom_events')}
      </div>
      <Checkbox
        label={t('only_broadcom_events')}
        id="broadcomEvents"
        onCheckedChange={(e) => {
          const changed = e ? 'on' : undefined;
          updateFilters('isBroadcomEvents', changed);
          clickOnFilter('Broadcom Events');
        }}
        checked={
          currentFilters?.isBroadcomEvents === 'on' ||
          currentFilters?.isBroadcomEvents?.includes('on') ||
          false
        }
      />
    </fieldset>
  );
}
