import ExpertAdvantageProgram from 'public/images/expert-advantage-program.svg';
import ExpertAdvantagePartnerCons from 'public/images/expert-advantage-partner-cons.svg';
import PartnerConsultantEng from 'public/images/partner-consultant-eng.svg';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export default function Carousel() {
  const { t } = useTranslation();

  const carouselCards = [
    {
      icon: 'expert-advantage-program',
      title: t('slider_find_your_expert_1_title'),
      description: t('slider_find_your_expert_1_descr')
    },
    {
      icon: 'expert-advantage-partner-cons',
      title: t('slider_find_your_expert_2_title'),
      description: t('slider_find_your_expert_2_descr')
    },
    {
      icon: 'partner-consultant-eng',
      title: t('slider_find_your_expert_3_title'),
      description: t('slider_find_your_expert_3_descr')
    }
  ];

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth || 0,
    height: window.innerHeight || 0
  });

  const isMobile = screenSize.width <= 768;

  useEffect(() => {
    const updateDimension = () => {
      const localScreenSize = {
        width: screenSize.width ? screenSize.width : window.innerWidth,
        height: screenSize.height ? screenSize.height : window.innerHeight
      };
      setScreenSize(localScreenSize);
    };
    updateDimension();
    window.addEventListener('resize', updateDimension);
    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, []);

  const styles = {
    wrapper: `flex flex-col gap-5 text-center items-center ${
      isMobile ? 'mt-20' : 'mt-5'
    } mx-16 lg:mx-12`,
    title: 'text-[#1F384C] text-xl font-normal',
    icon: 'w-[48px] h-[48px]',
    text: 'text-[#53565A] font-[100] text-base break-word leading-7'
  };

  const Card = ({ icon, title, text }) => (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        {icon === 'expert-advantage-program' && <ExpertAdvantageProgram />}
        {icon === 'expert-advantage-partner-cons' && (
          <ExpertAdvantagePartnerCons />
        )}
        {icon === 'partner-consultant-eng' && <PartnerConsultantEng />}
      </div>
      <span className={styles.title}>{title}</span>
      <span className={styles.text}>{text}</span>
    </div>
  );

  return (
    <div className="w-full flex flex-col gap-10 mt-8">
      <div className="flex flex-col gap-4 items-center text-center px-2">
        <span className="text-[#1F384C] font-[350] text-2xl md:text-[1.75rem]">
          {t('title_find_your_expert')}
        </span>
        <span className="text-[#53565A] font-[100] break-word leading-9">
          {t('desc_find_your_expert')}
        </span>
      </div>
      <div>
        <div className="flex flex-col lg:flex-row">
          {carouselCards.map((item, index) => (
            <Card
              key={index}
              icon={item.icon}
              title={item.title}
              text={item.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
