import UpcomingEvents from '../../UpcomingEvents';
import Carousel from './Carousel';
import PartnerFinderResume from '../../Resumes/PartnerFinderResume';
import MarketplaceFinderResume from '../../Resumes/MarketplaceFinderResume';
import KnightsFinderResume from '../../Resumes/KnightsFinderResume';

export default function PartnerFinderPresentation() {
  return (
    <div className="flex flex-col gap-4">
      <Carousel />
      <UpcomingEvents />
      <div className="flex flex-col gap-12 w-full pt-10">
        <PartnerFinderResume />
        <MarketplaceFinderResume reverse />
        <KnightsFinderResume />
      </div>
    </div>
  );
}
