import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/ui/checkbox';

export default function FreeTrial({
  currentFilters,
  updateFilters
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { t } = useTranslation();

  return (
    <fieldset className="flex flex-col gap-2 mb-4">
      <div className="m-0 p-0 font-extralight text-base text-[#2360a0]">
        {t('free_trial')}
      </div>
      <div className="flex flex-col gap-1 w-full mt-2">
        <Checkbox
          id="free_trial"
          name="free_trial"
          label={t('free_trial')}
          onCheckedChange={(checked) => {
            updateFilters('isFreeTrial', checked);
          }}
          checked={currentFilters['isFreeTrial'] ? true : false}
        />
      </div>
    </fieldset>
  );
}
