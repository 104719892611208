import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from 'components/ui/dialog';
import styles from './style.module.scss';
import { Dispatch, SetStateAction } from 'react';
import Image from 'next/image';

interface ModalProps {
  isOpen?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  type?: 'hosted' | 'managed' | 'public' | 'sovereign';
  title: string;
  titleDesc: string;
  text: string;
}

export default function CloudServicesModal({
  isOpen,
  setOpen,
  type,
  title,
  titleDesc,
  text
}: ModalProps) {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogContent className="min-w-[55%]">
        <DialogHeader>
          <div className="flex gap-5">
            <Image
              src={'/images/icon-modal-expertise.svg'}
              width={50}
              height={50}
              quality={100}
              alt="modal icon"
            />
            <DialogTitle>{title}</DialogTitle>
          </div>
        </DialogHeader>
        <div className={`overflow-y-auto ${styles.body}`}>
          <div className="w-full relative">
            <Image
              src={
                type === 'hosted'
                  ? '/images/hosted-private-cloud-modal.svg'
                  : type === 'sovereign'
                  ? '/images/sovereign-cloud.svg'
                  : type === 'managed'
                  ? '/images/managed-private-cloud-modal.svg'
                  : '/images/public-cloud-modal.svg'
              }
              layout="responsive"
              width={250}
              height={50}
              quality={100}
              alt="cloud icon"
            />
            <div className="absolute top-2 right-2">
              <Image
                src={
                  type === 'hosted'
                    ? '/images/hosted-private-icon.svg'
                    : type === 'managed'
                    ? '/images/managed-private-icon.svg'
                    : type === 'sovereign'
                    ? '/images/sovereign-cloud-icon.svg'
                    : '/images/public-cloud-icon.svg'
                }
                width={50}
                height={50}
                quality={100}
                alt="cloud icon"
              />
            </div>
          </div>
          <h1
            className="text-[#171725] text-[22px] font-[325] leading-8 text-start"
            dangerouslySetInnerHTML={{ __html: titleDesc }}
          ></h1>
          <span
            dangerouslySetInnerHTML={{ __html: text }}
            className="text-[#53565A] text-[18px] font-[100] leading-8 text-start whitespace-pre-line"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}
