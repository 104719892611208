import PartnersList from 'components/organisms/PartnersList';
import { useEventsContext } from 'hooks/useContexts';
import { useTranslation } from 'react-i18next';
import Pagination from '../Pagination';
import { useState } from 'react';
import PublicEventModal from '../Modals/PublicEventModal';
import { EventsFinderControllerResult } from 'controllers/finderTools/EventsFinder';
import { Skeleton } from 'components/ui/skeleton';
import FinderEventsList from 'components/organisms/FinderEventsList';
import FinderNoResult from './FinderNoResults';

const defaultEventsFinder = {
  currentEvents: [],
  EVENTS_PER_PAGE: 0,
  totalEvents: 0,
  totalRelatedPartners: 0,
  relatedPartnersFromPage: [],
  RELATED_PARTNERS_PER_PAGE: 0,
  isLoading: false,
  isLoadingRelated: false
};
export default function EventsFinderResults({
  standalone,
  eventsFinder = defaultEventsFinder
}: {
  standalone: boolean;
  eventsFinder?: EventsFinderControllerResult;
}) {
  const {
    currentEvents,
    EVENTS_PER_PAGE,
    totalEvents,
    totalRelatedPartners,
    relatedPartnersFromPage,
    RELATED_PARTNERS_PER_PAGE,
    isLoading = false,
    isLoadingRelated = false
  } = eventsFinder;

  const [selectedEvent, setSelectedEvent] = useState<any>(null);

  const { t } = useTranslation();

  const {
    currentPageFiltering,
    setCurrentPageFiltering,
    relatedPageFiltering,
    setRelatedPageFiltering
  } = useEventsContext();

  return (
    <>
      {isLoading ||
      isLoadingRelated ||
      (!isLoading && !isLoadingRelated && currentEvents?.length > 0) ? (
        <>
          <div className="flex flex-col gap-32 mb-20">
            <section className="flex flex-col gap-10">
              <h3
                className="text-[#171725] p-0 m-0 font-normal inline-flex gap-2 items-center"
                id="eventsFinder-mainResults"
              >
                {t('results')}{' '}
                {isLoading ? (
                  <Skeleton className="w-36 h-8" />
                ) : (
                  <span className="font-extralight text-lg">
                    ({t('showing')} {totalEvents})
                  </span>
                )}
              </h3>
              <FinderEventsList
                setSelectedEvent={setSelectedEvent}
                events={currentEvents}
                isLoading={isLoading}
                loadingCount={12}
              />
              {isLoading ? null : (
                <div className="flex justify-between text-[#b5b7c0]">
                  <Pagination
                    perPage={EVENTS_PER_PAGE}
                    total={totalEvents}
                    currentPage={currentPageFiltering}
                    onChange={(p) => setCurrentPageFiltering(p)}
                    showDataInfo
                    removePadding
                    scrollId="eventsFinder-mainResults"
                  />
                </div>
              )}
            </section>
            {((!standalone &&
              !isLoadingRelated &&
              relatedPartnersFromPage.length > 0) ||
              isLoadingRelated) && (
              <section className="flex flex-col gap-10">
                <h3
                  className="text-[#171725] p-0 m-0 font-normal inline-flex gap-2 items-center"
                  id="eventsFinder-relatedResults"
                >
                  {t('rel_partners')}{' '}
                  {isLoadingRelated ? (
                    <Skeleton className="w-36 h-8" />
                  ) : (
                    <span className="font-extralight text-lg">
                      ({t('showing')} {totalRelatedPartners})
                    </span>
                  )}
                </h3>
                <PartnersList
                  partners={relatedPartnersFromPage}
                  isLoading={isLoadingRelated}
                  loadingCount={3}
                  related="events"
                />
                {isLoadingRelated ? null : (
                  <div className="flex justify-between text-[#b5b7c0]">
                    <Pagination
                      perPage={RELATED_PARTNERS_PER_PAGE}
                      total={totalRelatedPartners}
                      currentPage={relatedPageFiltering}
                      onChange={(relatedPageFiltering) => {
                        setRelatedPageFiltering(relatedPageFiltering);
                      }}
                      showDataInfo
                      removePadding
                      scrollId="eventsFinder-relatedResults"
                    />
                  </div>
                )}
              </section>
            )}
          </div>
          {selectedEvent && (
            <PublicEventModal
              open={!!selectedEvent}
              onOpenChange={() => setSelectedEvent(null)}
              event={selectedEvent}
            />
          )}
        </>
      ) : null}
      {!isLoading && !isLoadingRelated && currentEvents.length === 0 ? (
        <FinderNoResult />
      ) : null}
    </>
  );
}
