import styles from './style.module.scss';
import PartnerCard from 'components/molecules/Cards/PartnerCard';
import { Skeleton } from 'components/ui/skeleton';

type ComponentProps = {
  partners: any[];
  setLoading?: any;
  isLoading?: boolean;
  loadingCount?: number;
  related?: string;
};

export default function PartnersList({
  partners = [],
  isLoading = false,
  loadingCount = 12,
  related = ''
}: ComponentProps) {
  const allPartners = partners;

  return (
    <ul className={styles.card_grid}>
      {isLoading
        ? Array.apply(null, Array(loadingCount)).map((e, i) => (
            <Skeleton
              key={`loading_partner_card${i}`}
              className="h-[400px] rounded-md"
            />
          ))
        : allPartners?.map(
            (partner: any) =>
              partner?.visibility === 'VISIBLE' && (
                <PartnerCard key={partner.id} partner={partner} related={related} />
              )
          )}
    </ul>
  );
}
