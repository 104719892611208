import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PartnersContext from '../../../../../context/PartnerListingsProvider';
import styles from './style.module.scss';
import SelectPV from 'components/atoms/SelectPV/SelectPV';

const dropdownIndicator = (p) => ({
  ...p,
  color: '#005c8a',
  svg: { fill: '#53565A' }
});
const placeholder = (p) => ({
  ...p,
  fontStyle: 'normal',
  fontWeight: '200',
  fontSize: '14px',
  lineHeight: '0px',
  color: '#A7A8AA'
});
const noOptionsMessage = (p) => ({
  ...p,
  fontSize: '16px'
});
const input = (p) => ({
  ...p,
  fontSize: '16px'
});

export default function Certifications({
  currentFilters,
  updateFilters
}: {
  currentFilters: any;
  updateFilters: (a: any, b: any) => void;
}) {
  const { clickOnFilter } = useContext(PartnersContext);
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const certificationsMap = {
    'ten-plus': '>=10',
    'ten-minus': '<10'
  };

  let options = [
    {
      key: 1,
      label: '<10',
      value: '<10',
      onChange: ({ label }) => {
        updateFilters('certifications', 'ten-minus');
        clickOnFilter('Certifications 10-');
        setValue(label);
      }
    },
    {
      key: 2,
      label: '>=10',
      value: '>=10',
      onChange: ({ label }) => {
        updateFilters('certifications', 'ten-plus');
        clickOnFilter('Certifications 10+');
        setValue(label);
      }
    }
  ];

  const onClear = () => {
    updateFilters('certifications', '');
    setValue('');
  };

  // Clear locations when clicking on the 'Clear All' filters button
  useEffect(() => {
    if (!currentFilters?.certifications?.length) {
      setValue('');
    } else {
      setValue(certificationsMap[currentFilters?.certifications].toString());
    }
  }, [currentFilters?.certifications]);

  return (
    <fieldset className={`${styles.fieldset}`}>
      <label htmlFor="number_certifications" className="text-base font-light">
        {t('number_certifications')}
      </label>
      <div className={styles.select}>
        <SelectPV
          id="number_certifications"
          placeholder={t('number_certifications')}
          data={options}
          value={options.find((e) => value === e.value) || ''}
          onChange={(e) => e.onChange(e)}
          onClear={onClear}
          clearable
          isMultiple={false}
          customStyles={{
            dropdownIndicator: dropdownIndicator,
            placeholder: placeholder,
            clearIndicator: dropdownIndicator,
            noOptionsMessage: noOptionsMessage,
            input: input
          }}
          menuPortalTarget={undefined}
        />
      </div>
    </fieldset>
  );
}
